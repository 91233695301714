import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function RelPedVendaRelPolo(data, relPedVenda, filtro) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const formattedTime = currentDate.toLocaleTimeString();

  const telefone = relPedVenda[0].telefone;
  const formattedTelefone = `${telefone.substring(0, 2)} ${telefone.substring(
    2,
    7
  )}-${telefone.substring(7)}`;

  const maxLineLength = 150;
  let currentLine = '';
  const reportContent = [];

  const filtroDetails = filtro.map((detalhe) => {
    const { campo, conteudo } = detalhe;
    if (campo.includes('Dt Mov')) {
      const [dataIni, dataEnd] = conteudo;
      return `${campo} = ${dataIni.trim()} a ${dataEnd.trim()}`;
    } else {
      return `${campo} = ${conteudo}`;
    }
  });

  filtroDetails.forEach((filtroItem, index) => {
    const filterPrefix = index === 0 ? 'Filtrado por: ' : '';
    const newLine = `${filterPrefix}${currentLine}${filtroItem}; `;
    if (newLine.length <= maxLineLength) {
      currentLine = newLine;
    } else {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
      currentLine = `${filtroItem}; `;
    }
  });

  if (currentLine !== '') {
    if (currentLine.includes('Filtrado por: ')) {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
    } else {
      reportContent.push({
        text: `.                     ${currentLine}`,
        fontSize: 8
      });
    }
  }

  const reportTitle = [
    {
      fontSize: 11,
      bold: true,
      margin: [15, 15, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Empresa: ' + relPedVenda[0].empresa, alignment: 'left' },
        { text: 'Data: ' + formattedDate, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Loja: ' + relPedVenda[0].loja, alignment: 'left' },
        { text: `Hora:      ` + formattedTime, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      text: [
        'CNPJ: ' + relPedVenda[0].cnpj,
        'Endereço: ' + relPedVenda[0].endereco,
        'Telefone: ' + formattedTelefone
      ].join('\n'),
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0]
    },
    {
      text: ['Relação de Itens por Fabricante'].join('\n'),
      fontSize: 13,
      bold: false,
      margin: [15, 15, 15, 0]
    },
    {
      stack: reportContent,
      fontSize: 8,
      bold: false,
      margin: [15, 3, 15, 0]
    }
  ];

  const polosRepetidos = {};

  const dados = data.map((opt, index) => {
    const previousOpt = data[index - 1];

    let pes_nome;

    if (opt.pes_nome === null) {
      pes_nome = 'SEM FORNECEDOR';
    } else {
      pes_nome = opt.pes_nome === previousOpt?.pes_nome ? '' : opt.pes_nome;
    }

    const itm_codfab =
      opt.itm_codfab === previousOpt?.itm_codfab ? '' : opt.itm_codfab;

    // const pes_nome = opt.pes_nome === previousOpt?.pes_nome ? '' : opt.pes_nome;

    if (!polosRepetidos[opt.pes_nome]) {
      polosRepetidos[opt.pes_nome] = 1;
    } else {
      polosRepetidos[opt.pes_nome]++;
    }

    return [
      { text: itm_codfab, fontSize: 8, margin: [0, 0, 0, 0] },
      { text: pes_nome, fontSize: 8, margin: [0, 0, 0, 0] },
      { text: opt.ped_numped, fontSize: 8, margin: [0, 0, 0, 0] },
      { text: opt.ipd_item, fontSize: 8, margin: [0, 0, 0, 0] },
      { text: opt.itm_modelo, fontSize: 8, margin: [0, 0, 0, 0] },
      { text: opt.ipd_desitm, fontSize: 8, margin: [0, 0, 0, 0] },
      { text: opt.ipd_unidade, fontSize: 8, margin: [0, 0, 0, 0] },
      {
        text: opt.ipd_quantped,
        fontSize: 8,
        margin: [0, 0, 0, 0],
        alignment: 'right'
      }
    ];
  });

  const polosRepetidosArray = Object.entries(polosRepetidos);

  const styles = {
    tableHeader: {
      bold: true
    }
  };

  const qtdRegistros = dados.length;

  const details = [
    {
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto'],
        body: [
          [
            { text: 'Cod. Fab', style: 'tableHeader', fontSize: 9 },
            { text: 'Nome', style: 'tableHeader', fontSize: 9 },
            { text: 'Num Ped', style: 'tableHeader', fontSize: 9 },
            { text: 'Cd. Item', style: 'tableHeader', fontSize: 9 },
            { text: 'Cd.Itm Cli', style: 'tableHeader', fontSize: 9 },
            { text: 'Desc. Item', style: 'tableHeader', fontSize: 9 },
            { text: 'Unidade', style: 'tableHeader', fontSize: 9 },
            { text: 'Qtd. Ped', style: 'tableHeader', fontSize: 9 }
          ],
          ...dados
        ]
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
        }
      }
    },
    {
      table: {
        headerRows: 0,
        widths: ['*', 'auto'],
        body: [
          [
            {
              text: 'Total de Itens:',
              alignment: 'right',
              bold: true,
              fontSize: 8
            },
            {
              text: qtdRegistros.toLocaleString('pt-BR'),
              alignment: 'right',
              fontSize: 8
            }
          ],
          ...polosRepetidosArray.map(([nomePolo, quantidade]) => [
            {
              text: nomePolo === 'null' ? 'SEM FORNECEDOR' : nomePolo,
              alignment: 'right',
              bold: true,
              fontSize: 8
            },
            {
              text: quantidade.toLocaleString('pt-BR'),
              alignment: 'right',
              fontSize: 8
            }
          ])
        ]
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
        paddingTop: function () {
          return 3;
        },
        paddingBottom: function () {
          return 3;
        }
      }
    }
  ];

  function Rodape(currentPage, pageCount) {
    return [
      {
        text: currentPage + ' / ' + pageCount,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0]
      }
    ];
  }

  const docDefinitios = {
    pageSize: 'A4',
    // pageMargins: [15, 135, 15, 40],
    pageMargins: [10, 135, 10, 30],

    header: [reportTitle],
    content: [details],
    footer: Rodape,
    styles: styles
  };

  pdfMake.createPdf(docDefinitios).download();
}

export default RelPedVendaRelPolo;
