import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Config from '../modal/Config';
import i18n from '../services/i18n/i18n';

function Header(props) {
  const { t } = useTranslation();

  const [bgNavbar, setBgNavbar] = useState('#cacaca');
  const [fontNavbar, setFontNavbar] = useState('#232323');

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const [usuario] = useState(
    localStorage.getItem('sessionToken')
      ? localStorage.getItem('sessionNome')
      : ''
  );

  const handleLogout = () => {
    if (localStorage.getItem('sessionToken')) {
      localStorage.removeItem('sessionToken');
      window.location.reload();
    } else {
      window.location.reload();
    }
  };

  const srcImg = () => {
    switch (selectedLanguage) {
      case 'ptBR':
        return 'imagens/brasil.png';
      case 'en':
        return 'imagens/usa.jpg';
      default:
        return 'imagens/espanha.png';
    }
  };

  useEffect(() => {
    const savedConfigString = localStorage.getItem('config');
    if (savedConfigString) {
      const savedConfig = JSON.parse(savedConfigString);
      setBgNavbar(savedConfig.bgNavbar);
      setFontNavbar(savedConfig.fontNavbar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Ativa os tooltips após a renderização do componente
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <nav
      className="navbar navbar-expand-lg"
      style={{ backgroundColor: bgNavbar }}
    >
      <div className="container-fluid" style={{ width: '95vw' }}>
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
          <img src="imagens/logo.png" alt="logo SALT" height="40" />
          <p
            className="navbar-brand ms-3 navbar-center fw-bold"
            style={{ color: fontNavbar, fontSize: props.tamTitulo }}
          >
            SIE {props.area}
          </p>

          {props.login && (
            <ul className="navbar-nav">
              <li
                className="nav-item ms-3 mt-1 navbar-center fw-semibold"
                style={{ color: fontNavbar, fontSize: props.tamTexto }}
              >
                Empresa: {props.dataEmpresa.nomorg} {t('Usuario')}: {usuario}
              </li>
            </ul>
          )}
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
          aria-controls="navbarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarMenu">
          <ul className="navbar-nav ms-auto">
            {props.dataEmpresa.nivel.includes('GESTAO') && (
              <>
                <li className="nav-item">
                  <div className="dropdown navbar-center">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {t('Periodos')}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => props.handlePeriodClick('esta-semana')}
                        >
                          {t('EssaSemana')}
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() =>
                            props.handlePeriodClick('semana-passada')
                          }
                        >
                          {t('SemanaPassada')}
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() =>
                            props.handlePeriodClick('proxima-semana')
                          }
                        >
                          {t('ProximaSemana')}
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => props.handlePeriodClick('este-mes')}
                        >
                          {t('EsseMes')}
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => props.handlePeriodClick('mes-passado')}
                        >
                          {t('MesPassado')}
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => props.handlePeriodClick('proximo-mes')}
                        >
                          {t('ProximoMes')}
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => props.handlePeriodClick('este-ano')}
                        >
                          {t('EsseAno')}
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => props.handlePeriodClick('ano-passado')}
                        >
                          {t('AnoPassado')}
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => props.handlePeriodClick('proximo-ano')}
                        >
                          {t('ProximoAno')}
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="nav-item">
                  <p
                    className="nav-link navbar-center"
                    style={{ color: fontNavbar, fontSize: props.tamTexto }}
                  >
                    {t(props.selectedPeriod)}
                  </p>
                </li>
              </>
            )}
            <li className="nav-item">
              <button
                type="button"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                data-bs-custom-class="custom-tooltip"
                data-bs-title="Configurações"
                style={{ border: 'none', backgroundColor: 'transparent' }}
              >
                <i
                  className="fa fa-solid fa-bars nav-link navbar-center"
                  data-bs-toggle="modal"
                  data-bs-target="#Config"
                  style={{ color: '#6c757d' }}
                ></i>
              </button>
              <Config
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                bgNavbar={bgNavbar}
                setBgNavbar={setBgNavbar}
                fontNavbar={fontNavbar}
                setFontNavbar={setFontNavbar}
                bgDashboard1={props.bgDashboard1}
                setBgDashboard1={props.setBgDashboard1}
                fontDashboard1={props.fontDashboard1}
                setFontDashboard1={props.setFontDashboard1}
                bgDashboard2={props.bgDashboard2}
                setBgDashboard2={props.setBgDashboard2}
                fontDashboard2={props.fontDashboard2}
                setFontDashboard2={props.setFontDashboard2}
                bgDashboard3={props.bgDashboard3}
                setBgDashboard3={props.setBgDashboard3}
                fontDashboard3={props.fontDashboard3}
                setFontDashboard3={props.setFontDashboard3}
                bgMenu={props.bgMenu}
                setBgMenu={props.setBgMenu}
                fontMenu={props.fontMenu}
                setFontMenu={props.setFontMenu}
                login={props.login}
                tamInput={props.tamInput}
                setTamInput={props.setTamInput}
                tamTitulo={props.tamTitulo}
                setTamTitulo={props.setTamTitulo}
                tamSubTitulo={props.tamSubTitulo}
                setTamSubTitulo={props.setTamSubTitulo}
                tamTexto={props.tamTexto}
                setTamTexto={props.setTamTexto}
                tamSubTexto={props.tamSubTexto}
                setTamSubTexto={props.setTamSubTexto}
                changeState={props.changeState}
                setChangeState={props.setChangeState}
                area={props.area}
                setArea={props.setArea}
                campoArea={props.campoArea}
                setCampoArea={props.setCampoArea}
                area_verificar={props.area_verificar}
                abr_area={props.abr_area}
                dataBanco={props.dataBanco}
                setDataBanco={props.setDataBanco}
                Templates={props.Templates}
                dataEmpresa={props.dataEmpresa}
              />
            </li>
            <li className="nav-item d-flex align-items-center">
              <img src={srcImg()} className="img-nav" alt="Logo Brasil" />
            </li>
            <li className="nav-item d-flex align-items-center ms-1">
              <button
                className="btn btn-sm btn-outline-danger"
                style={{
                  fontSize: props.tamTexto
                }}
                onClick={handleLogout}
              >
                SAIR
              </button>
            </li>
            <li className="nav-item">
              <p
                className="nav-link navbar-center"
                style={{ color: fontNavbar, fontSize: '0.8em' }}
              >
                V 1.03.45
              </p>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
