import { useState } from 'react';

import { Compra } from '../modal/compra/Compra';
import { Consumo } from '../modal/consumo/Consumo';
import { Conta } from '../modal/conta/Conta';
import { Contabil } from '../modal/contabil/Contabil';
import { ContaPag } from '../modal/contaPag/ContaPag';
import { Estoque } from '../modal/estoque/Estoque';
import { Financeiro } from '../modal/financeiro/Financeiro';
import { Manutencao } from '../modal/manutencao/Manutencao';
import { PedidoCompra } from '../modal/pedidoCompra/PedidoCompra';
import { PedidoVenda } from '../modal/pedidoVenda/PedidoVenda';
import { Pessoa } from '../modal/pessoa/Pessoa';
import { Transferencia } from '../modal/transferencia/Transferencia';
import { CheckinRecep } from '../modalRecep/checkin/Checkin';
import { EntradaRecep } from '../modalRecep/entrada/Entrada';
import { EventosRecep } from '../modalRecep/eventos/Eventos';
import { Hospegadem } from '../modalRecep/hospedagem/Hospedagem';
import { Portaria } from '../modalRecep/portaria/Portaria';
import { ReservaRecep } from '../modalRecep/reserva/Reserva';
import { SaidaRecep } from '../modalRecep/saida/Saida';
import { handleCard } from './handleCard';

export const Card = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const cardStyle = {
    backgroundColor: isHovered ? '#cfe5ed' : props.bgMenu,
    color: props.fontMenu
  };

  return (
    <div
      className="card cursor cardHover w-100 mt-1 mb-1"
      tabIndex="-1"
      data-bs-toggle="modal"
      data-bs-target={props.modal}
      style={cardStyle}
      onClick={() => props.onClick()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="card-body d-flex flex-column justify-content-center align-items-center">
        <h4 className="card-title">
          <i
            className={props.icon}
            style={{ fontSize: props.tamSubTitulo }}
          ></i>
        </h4>
        <h5
          className="card-text text-center fw-semibold"
          style={{ fontSize: props.tamSubTitulo }}
        >
          {props.texto}
        </h5>
      </div>
    </div>
  );
};

function Menu(props) {
  const [isOpen, setIsOpen] = useState('');

  const cards = handleCard(props.dataEmpresa.area);

  return (
    <div className="mt-1" style={{ width: '95vw', marginLeft: '2.5vw' }}>
      <div className="row">
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-2 d-flex flex-column align-items-center">
          {cards
            .filter((item) => {
              const values = Object.values(item)[0];
              return values.situacao === 'ATIVO' && values.coluna === '1';
            })
            .map((opt) => {
              if (opt) {
                const values = Object.values(opt)[0];

                return (
                  <Card
                    key={values.chave}
                    texto={values.nome}
                    modal={props.login ? `#${values.chave}` : '#msgError'}
                    icon="fa-solid fa-magnifying-glass"
                    bgMenu={props.bgMenu}
                    fontMenu={props.fontMenu}
                    tamSubTitulo={props.tamSubTitulo}
                    onClick={() => setIsOpen(values.chave)}
                  />
                );
              }
            })}
        </div>
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-2 d-flex flex-column align-items-center">
          {cards
            .filter((item) => {
              const values = Object.values(item)[0];
              return values.situacao === 'ATIVO' && values.coluna === '2';
            })
            .map((opt) => {
              if (opt) {
                const values = Object.values(opt)[0];

                return (
                  <Card
                    key={values.chave}
                    texto={values.nome}
                    modal={props.login ? `#${values.chave}` : '#msgError'}
                    icon="fa-solid fa-magnifying-glass"
                    bgMenu={props.bgMenu}
                    fontMenu={props.fontMenu}
                    tamSubTitulo={props.tamSubTitulo}
                    onClick={() => setIsOpen(values.chave)}
                  />
                );
              }
            })}
        </div>
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-2 d-flex flex-column align-items-center">
          {cards
            .filter((item) => {
              const values = Object.values(item)[0];
              return values.situacao === 'ATIVO' && values.coluna === '3';
            })
            .map((opt) => {
              if (opt) {
                const values = Object.values(opt)[0];

                return (
                  <Card
                    key={values.chave}
                    texto={values.nome}
                    modal={props.login ? `#${values.chave}` : '#msgError'}
                    icon="fa-solid fa-magnifying-glass"
                    bgMenu={props.bgMenu}
                    fontMenu={props.fontMenu}
                    tamSubTitulo={props.tamSubTitulo}
                    onClick={() => setIsOpen(values.chave)}
                  />
                );
              }
            })}
        </div>
      </div>

      {props.login ? (
        <>
          <PedidoVenda
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
          <PedidoCompra
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
          />
          <Conta
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
          />
          <ContaPag
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
          />
          <Financeiro
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
          />
          <Compra
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
          />
          <Transferencia
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
          />
          <Estoque
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
          />
          <Pessoa
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
          />
          <Contabil
            isOpen={isOpen}
            tamTitulo={props.tamTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
          />
          <Manutencao
            isOpen={isOpen}
            tamTexto={props.tamTexto}
            tamTitulo={props.tamTitulo}
            tamSubTexto={props.tamSubTexto}
          />

          <ReservaRecep
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
          <EntradaRecep
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
          <SaidaRecep
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
          <EventosRecep
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
          <CheckinRecep
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
          <Portaria
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
          <Hospegadem
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
          <Consumo
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
        </>
      ) : (
        <div
          className="modal fade"
          id="msgError"
          tabIndex="-1"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div
                  className="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  <div>Não Logado</div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Menu;
