import { useState } from 'react';
import InputMask from 'react-input-mask';

import { format, addDays } from 'date-fns';

export function Search(props) {
  const [anoDatent, setAnoDatent] = useState('');

  const months = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' }
  ];

  const handlePeriodClick = (period) => {
    const today = new Date();
    let start, end;
    switch (period) {
      case 'esta-semana':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, -1);
        }
        end = addDays(start, 6);
        break;
      case 'semana-passada':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, -1);
        }
        start = addDays(start, -7);
        end = addDays(start, 6);
        break;
      case 'proxima-semana':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, 1);
        }
        end = addDays(start, 6);
        break;
      case 'este-mes':
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case 'mes-passado':
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case 'proximo-mes':
        start = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        end = new Date(today.getFullYear(), today.getMonth() + 2, 0);
        break;
      case 'este-ano':
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date(today.getFullYear(), 11, 31);
        break;
      case 'ano-passado':
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        break;
      case 'proximo-ano':
        start = new Date(today.getFullYear() + 1, 0, 1);
        end = new Date(today.getFullYear() + 1, 11, 31);
        break;
      default:
        start = null;
        end = null;
    }

    props.setDataFiltro({
      ...props.dataFiltro,
      datsaiInicial: start ? format(start, 'dd/MM/yyyy') : '',
      datsaiFinal: end ? format(end, 'dd/MM/yyyy') : ''
    });
  };

  const calculateDates = (monthValue, yearValue) => {
    const month = parseInt(monthValue, 10);
    const year = parseInt(yearValue, 10);

    let lastDayOfMonth;
    if (month === 2) {
      lastDayOfMonth = new Date(year, 2, 0).getDate();
    } else {
      const monthsWith30Days = [4, 6, 9, 11];
      lastDayOfMonth = monthsWith30Days.includes(month) ? 30 : 31;
    }

    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month - 1, lastDayOfMonth);

    props.setDataFiltro({
      ...props.dataFiltro,
      datsaiInicial: format(startDate, 'dd/MM/yyyy'),
      datsaiFinal: format(endDate, 'dd/MM/yyyy')
    });
  };

  return (
    <>
      {/* emp */}
      <div>
        <span className="col-form-label">Empresa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.empresa || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                empresa: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[0]?.map((option, index) => (
              <option key={index} value={option.emp_codemp}>
                {option.emp_codemp} - {option.emp_fantasia}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* loja */}
      <div>
        <span className="col-form-label">Loja</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.loja || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                loja: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[1]?.map((option, index) => (
              <option key={index} value={option.loj_codloj}>
                {option.loj_codloj} - {option.loj_denloj}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* undadm */}
      <div>
        <span className="col-form-label">Unidade Administrativa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.undadm || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                undadm: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[2]
              ?.slice()
              .sort((a, b) =>
                a.nome_unidade_adm.localeCompare(b.nome_unidade_adm)
              )
              .map((option, index) => (
                <option key={index} value={option.codigo_unidade_adm}>
                  {option.codigo_unidade_adm} - {option.nome_unidade_adm}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div>
        <hr />
      </div>

      <div>
        <span className="col-form-label">Tipo Apto</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.tipapt || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                tipapt: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[4]?.map((option, index) => (
              <option key={index} value={option.tap_tipapa}>
                {option.tap_tipapa} - {option.tap_destap}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <span className="col-form-label">Cód. Evento</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.evento || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                evento: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[3]?.map((option, index) => (
              <option key={index} value={option.evn_codevn}>
                {option.evn_codevn} - {option.evn_evento}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <span className="col-form-label">Nome Empresa Agência</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.tippes || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                tippes: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {/* {props.comboboxPessoa.map((option, index) => (
              <option key={index} value={option.pes_codpes}>
                {option.pes_nome}
              </option>
            ))} */}
          </select>
        </div>
      </div>

      <div>
        <span className="col-form-label">Nº da Reserva</span>
        <div>
          <input
            type="number"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.numres || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                numres: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Nº da Entrada</span>
        <div>
          <input
            type="number"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.nument || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                nument: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Nº da Saída</span>
        <div>
          <input
            type="number"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.numsai || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                numsai: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Nº Cód. Reserva de Grupo</span>
        <div>
          <input
            type="number"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.grupo || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                grupo: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Nº do Apto</span>
        <div>
          <input
            type="number"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.numapa || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                numapa: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Nome do Hóspede</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.nomhos || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                nomhos: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Situação da Saída</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.sitsai || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                sitsai: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="F">F - FATURADO</option>
            <option value="C">C - CANCELADA</option>
          </select>
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* datsai */}
      <div className="row d-flex">
        <div className="dropdown col-6">
          <button
            className="btn btn-info dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Períodos
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('esta-semana')}
              >
                Essa semana
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('semana-passada')}
              >
                Semana passada
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proxima-semana')}
              >
                Semana que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-mes')}
              >
                Esse mês
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('mes-passado')}
              >
                Mês passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-mes')}
              >
                Mês que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-ano')}
              >
                Esse ano
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('ano-passado')}
              >
                Ano passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-ano')}
              >
                Ano que vem
              </p>
            </li>
          </ul>
        </div>

        <div className="dropdown col-6">
          <button
            className="btn btn-info dropdown-toggle"
            type="button"
            id="dropdownMesesdatent"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Meses/Ano
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMesesdatent">
            <li className="dropdown-item">
              <InputMask
                mask="9999"
                maskChar=""
                type="text"
                className="form-control form-control-sm border border-dark"
                id="datentIni1"
                placeholder="Ano"
                value={anoDatent}
                onChange={(e) => setAnoDatent(e.target.value)}
              />
            </li>
            {months.map((month) => (
              <li key={month.value}>
                <p
                  className="dropdown-item"
                  onClick={() => calculateDates(month.value, anoDatent)}
                >
                  {month.label}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div>
        <span className="col-form-label">Data de Saída Inicial</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            inputMode="numeric"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datsaiInicial || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datsaiInicial: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Data de Saída Final</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            inputMode="numeric"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datsaiFinal || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datsaiFinal: e.target.value
              })
            }
          />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={props.handleSearch}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
}
