import React from 'react';

export const TabelaQuatroGrupos = ({ data, headers, title }) => {
  return (
    <div style={{ width: '95%', height: '40vh' }}>
      <h4 style={{ backgroundColor: 'grey', color: 'white', padding: '10px' }}>
        {title}
      </h4>
      <div style={{ height: 'calc(100% - 60px)', overflowY: 'auto' }}>
        <table className="table table-striped table-hover">
          <thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'white'
            }}
          >
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.descricao}</td>
                <td>{item.item}</td>
                <td>{item.unidade}</td>
                <td>{item.nomuadm}</td>
                <td>{parseFloat(item.sum).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
