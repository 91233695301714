import { useEffect, useState } from 'react';

import { ApiGet, NewApiGet } from '../../services/apiService';
import { Dashboard } from './dashboard/Dashboard';
import { Grafico } from './grafico/Grafico';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';
import { Relatorio } from './relatorio/Relatorio';

export const Estoque = (props) => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [dataFiltro, setDataFiltro] = useState({});
  const [dataItems, setDataItems] = useState([]);
  const [filtroPesquisa, setFiltroPesquisa] = useState([]);
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');
  const [options, setOptions] = useState([]);

  const handleSearch = async () => {
    const tamItem = dataFiltro.item ? dataFiltro.item.padStart(10, '0') : '';

    const body = {
      codemp: dataFiltro.empresa || '' || '',
      loja: dataFiltro.loja || '',
      undadm: dataFiltro.undadm || '',
      item: tamItem || '',
      qtdest:
        (dataFiltro.qtdest &&
          `AND etq_qtdest ${dataFiltro.operacaoQtdest} ${dataFiltro.qtdest}`) ||
        '',
      minimo:
        (dataFiltro.minimo &&
          `AND etq_minimo ${dataFiltro.operacaoMinimo} ${dataFiltro.minimo}`) ||
        '',
      resup:
        (dataFiltro.resup &&
          `AND (etq_qtdest - etq_minimo) ${dataFiltro.operacaoResup} ${dataFiltro.resup}`) ||
        '',
      tipo: dataFiltro.tipo || '',
      grupo: dataFiltro.grupo || '',
      subgrupo: dataFiltro.subGrupo || ''
    };

    const conditions = [
      {
        condition: dataFiltro.empresa,
        campo: 'Empresa',
        conteudo: dataFiltro.empresa
      },
      { condition: dataFiltro.loja, campo: 'Loja', conteudo: dataFiltro.loja },
      {
        condition: dataFiltro.undadm,
        campo: 'Und Adm',
        conteudo: dataFiltro.undadm
      },
      {
        condition: dataFiltro.tipo,
        campo: 'Tipo',
        conteudo: dataFiltro.tipo
      },
      {
        condition: dataFiltro.grupo,
        campo: 'Grupo',
        conteudo: dataFiltro.grupo
      },
      {
        condition: dataFiltro.subgrupo,
        campo: 'Sub Grupo',
        conteudo: dataFiltro.subgrupo
      },
      {
        condition: tamItem,
        campo: 'Item',
        conteudo: tamItem
      },
      {
        condition: dataFiltro.qtdest,
        campo: 'Qtd Est',
        conteudo: dataFiltro.qtdest
      },
      {
        condition: dataFiltro.minimo,
        campo: 'Minimo',
        conteudo: dataFiltro.minimo
      }
    ];

    let pesquisasDetalhes = [];

    conditions.forEach(({ condition, campo, conteudo }) => {
      if (condition) pesquisasDetalhes.push({ campo, conteudo });
    });

    setFiltroPesquisa(pesquisasDetalhes);

    try {
      const response = await ApiGet(
        '/estoque',
        { contas: body },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      setData(response.data.estoque);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    alert('Em atualização');
  };

  const handleReset = () => {
    setFormData({});
    setActiveTab(1);
  };

  const handleCombobox = async () => {
    try {
      const response1 = await NewApiGet(
        '/tabela/empresa',
        localStorage.getItem('sessionToken')
      );
      const resEmpresa = response1.data.tabela;

      const response2 = await NewApiGet(
        '/tabela/loja',
        localStorage.getItem('sessionToken')
      );
      const resLoja = response2.data.tabela;

      const response3 = await NewApiGet(
        '/tabela/unidadeAdm',
        localStorage.getItem('sessionToken')
      );
      const resUndAdm = response3.data.tabela;

      const response4 = await NewApiGet(
        '/tabela/tipoItem',
        localStorage.getItem('sessionToken')
      );
      const resItem = response4.data.tabela;

      const response5 = await NewApiGet(
        '/tabela/grupo',
        localStorage.getItem('sessionToken')
      );
      const resGrupo = response5.data.tabela;

      const response6 = await NewApiGet(
        '/tabela/subgrupo',
        localStorage.getItem('sessionToken')
      );
      const resSubgrupo = response6.data.tabela;

      setOptions([
        resEmpresa,
        resLoja,
        resUndAdm,
        resItem,
        resGrupo,
        resSubgrupo
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-Estoque') {
      handleSearch();
    }
  }, [activeTab, props.isOpen]);

  useEffect(() => {
    handleCombobox();
  }, []);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Estoque"
      tabIndex="-1"
      aria-labelledby="lblEstoque"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblEstoque"
              style={{ fontSize: props.tamTitulo }}
            >
              Estoque
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-Estoque' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasTransferencia"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    options={options}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p
                  className={`nav-link ${
                    activeTab === 1 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    setActiveTab(1);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  Navegação
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 2 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    setActiveTab(2);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  Relatorio
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 3 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    setActiveTab(3);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  Grafico
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 4 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    setActiveTab(4);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  Dashboard
                </p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    setDataItems={setDataItems}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                  id="tab2"
                >
                  {data.length >= 1 && (
                    <Relatorio data={data} filtroPesquisa={filtroPesquisa} />
                  )}
                </div>
                <div
                  className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
                  id="tab3"
                >
                  <Grafico />
                </div>
                <div
                  className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}
                  id="tab4"
                >
                  <Dashboard tamTexto={props.tamTexto} />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {activeTab === 1 && (
              <button
                className="btn btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasTransferencia"
                aria-controls="offcanvasExample"
                style={{ fontSize: props.tamTexto }}
              >
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
