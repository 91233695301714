import { useEffect, useState } from 'react';

import { ApiGet } from '../../services/apiService';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';

import { addDays, format, subDays } from 'date-fns';

export function Portaria(props) {
  const date = new Date();
  const initialDate = subDays(date, 1);
  const finalDate = addDays(date, 1);

  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [dataFiltro, setDataFiltro] = useState({
    sitres: 'N',
    datentInicial: format(initialDate, 'dd/MM/yyyy'),
    datentFinal: format(finalDate, 'dd/MM/yyyy')
  });

  const handleSearch = async () => {
    const tamNumres = dataFiltro.numres?.padStart(10, '0') || '';
    const tamNumapa = dataFiltro.numapa?.padStart(5, ' ') || '';
    const nome = dataFiltro.nomctt?.toUpperCase() || '';
    const placaFormat = dataFiltro.placa?.toUpperCase() || '';
    let data = [];

    const isDataInicial = dataFiltro.datentInicial !== '';
    const isDataFinal = dataFiltro.datentFinal !== '';

    if (!isDataInicial || !isDataFinal) {
      alert('Preencher as duas datas');
      return;
    } else {
      data = [dataFiltro.datentInicial, dataFiltro.datentFinal];
    }

    const body = {
      res_numres: tamNumres || '',
      enh_nomctt: nome || '',
      res_datent: data || '',
      res_codevn: '',
      enh_checkin: dataFiltro.checkin || '',
      res_sitres: dataFiltro.sitres || '',
      res_numapa: tamNumapa || '',
      entrada: dataFiltro.ocupado || '',
      res_placar: placaFormat || ''
    };

    try {
      const response = await ApiGet(
        '/v1/evento/hospede',
        { evento: body },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      const sortedData = response.data.evento.sort(
        (a, b) => new Date(a.res_datent) - new Date(b.res_datent)
      );

      setData(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-ControlePortaria') {
      handleSearch();
    }
  }, [activeTab, props.isOpen]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-ControlePortaria"
      tabIndex="-1"
      aria-labelledby="lblPortaria"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblPortaria"
              style={{ fontSize: props.tamTitulo }}
            >
              Controle de Veículos/Portaria
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-ControlePortaria' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasPedVenda"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Navegação</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    dataFiltro={dataFiltro}
                    handleSearch={handleSearch}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div className="row mt-1 mb-1">
                <div className="col-12 text-end d-flex justify-content-end">
                  <div className="d-flex">
                    <p
                      className="bg-success-subtle"
                      style={{ width: '1.25rem', borderRadius: '5px' }}
                    ></p>
                    <p style={{ fontSize: '0.75rem' }}>Apto Ocupado</p>
                  </div>

                  <div className="d-flex ms-2">
                    <p
                      className="bg-warning-subtle"
                      style={{ width: '1.25rem', borderRadius: '5px' }}
                    ></p>
                    <p style={{ fontSize: '0.75rem' }}>CkOnline SIM</p>
                  </div>

                  <div className="d-flex ms-2">
                    <p
                      className="bg-danger-subtle"
                      style={{ width: '1.25rem', borderRadius: '5px' }}
                    ></p>
                    <p style={{ fontSize: '0.75rem' }}>CkOnline NÃO</p>
                  </div>
                </div>
              </div>

              {activeTab === 1 && (
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasPedVenda"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
