import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function RelContas(data, relContas, filtro) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const formattedTime = currentDate.toLocaleTimeString();

  const reportTitle = [
    {
      fontSize: 11,
      bold: true,
      margin: [15, 15, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Empresa: ' + relContas[0].empresa, alignment: 'left' },
        { text: 'Data: ' + formattedDate, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Loja: ' + relContas[0].loja, alignment: 'left' },
        { text: `Hora:      ` + formattedTime, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      text: [
        'CNPJ: ' + relContas[0].cnpj,
        'Endereço: ' + relContas[0].endereco,
        'Telefone: ' + relContas[0].telefone
      ].join('\n'),
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0]
    }
  ];

  const maxLineLength = 150;
  let currentLine = '';
  const reportContent = [];

  const filtroDetails = filtro.map((detalhe) => {
    const { campo, conteudo } = detalhe;
    if (campo.includes('Dt Mov')) {
      const [dataIni, dataEnd] = conteudo;
      return `${campo} = ${dataIni.trim()} a ${dataEnd.trim()}`;
    } else if (campo.includes('Dt Ven')) {
      const [dataIni, dataEnd] = conteudo;
      return `${campo} = ${dataIni.trim()} a ${dataEnd.trim()}`;
    } else {
      return `${campo} = ${conteudo}`;
    }
  });

  filtroDetails.forEach((filtroItem, index) => {
    const filterPrefix = index === 0 ? 'Filtrado por: ' : '';
    const newLine = `${filterPrefix}${currentLine}${filtroItem}; `;
    if (newLine.length <= maxLineLength) {
      currentLine = newLine;
    } else {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
      currentLine = `${filtroItem}; `;
    }
  });

  if (currentLine !== '') {
    if (currentLine.includes('Filtrado por: ')) {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
    } else {
      reportContent.push({
        text: `.                     ${currentLine}`,
        fontSize: 8
      });
    }
  }

  let receitaAR = 0;
  let despesaAR = 0;

  const dados = data.slice(0, 20000).map((opt) => {
    const dataFormatadaDamov = new Date(opt.cnt_damov).toLocaleDateString(
      'pt-BR'
    );
    const dataFormatadaDatven = new Date(opt.cnt_datven).toLocaleDateString(
      'pt-BR'
    );

    if (opt.cnt_tipcon === 'R') receitaAR = receitaAR + opt.cnt_valdps;

    if (opt.cnt_tipcon === 'P') despesaAR = despesaAR + opt.cnt_valdps;

    return [
      // { text: opt.cnt_docpag, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.cnt_numcon, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.cnt_tipcon, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.cnt_codpes, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.cnt_nompes, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: dataFormatadaDamov, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: dataFormatadaDatven, fontSize: 8, margin: [0, 2, 0, 2] },
      {
        text: opt.cnt_valdps.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }),
        fontSize: 8,
        margin: [0, 2, 0, 2],
        alignment: 'right'
      }
      // {
      //   text: opt.cnt_valpag
      //     ? opt.cnt_valpag.toLocaleString('pt-BR', {
      //         minimumFractionDigits: 2,
      //         maximumFractionDigits: 2
      //       })
      //     : '',
      //   fontSize: 8,
      //   margin: [0, 2, 0, 2],
      //   alignment: 'right'
      // }
    ];
  });

  let saldoAR = receitaAR - despesaAR;

  const qtdRegistros = dados.length;

  const styles = {
    tableHeader: {
      bold: true
    }
  };

  const details = [
    {
      table: {
        headerRows: 1,
        widths: [
          // 'auto',
          'auto',
          'auto',
          'auto',
          '*',
          'auto',
          'auto',
          'auto'
          // 'auto'
        ],
        body: [
          [
            // { text: 'Id', style: 'tableHeader', fontSize: 9 },
            { text: 'Num Con', style: 'tableHeader', fontSize: 9 },
            { text: 'Tp Con', style: 'tableHeader', fontSize: 9 },
            { text: 'C Cliente', style: 'tableHeader', fontSize: 9 },
            { text: 'Nome Cliente', style: 'tableHeader', fontSize: 9 },
            { text: 'Dt Mov', style: 'tableHeader', fontSize: 9 },
            { text: 'Dt Ven', style: 'tableHeader', fontSize: 9 },
            { text: 'Val Dps', style: 'tableHeader', fontSize: 9 }
            // { text: 'Val Pag', style: 'tableHeader', fontSize: 9 }
          ],
          ...dados
        ]
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
        }
      }
    },
    {
      table: {
        headerRows: 0,
        widths: ['auto', 'auto'],
        body: [
          [
            {
              text: ''
            },
            {
              text: 'A realizar',
              alignment: 'right',
              bold: true,
              fontSize: 8
            }
          ],
          [
            { text: 'Receita', bold: true, fontSize: 8 },
            {
              text: receitaAR.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            }
          ],
          [
            { text: 'Despesa', bold: true, fontSize: 8 },
            {
              text: despesaAR.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            }
          ],
          [
            { text: 'Saldo', bold: true, fontSize: 8 },
            {
              text: saldoAR.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            }
          ],
          [
            { text: 'Quantidade de Registros', bold: true, fontSize: 8 },
            {
              text: qtdRegistros.toLocaleString('pt-BR'),
              alignment: 'right',
              fontSize: 8
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
        paddingTop: function () {
          return 3;
        },
        paddingBottom: function () {
          return 3;
        }
      }
    }
  ];

  function Rodape(currentPage, pageCount) {
    return [
      {
        text: currentPage + ' / ' + pageCount,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0]
      }
    ];
  }

  const docDefinitios = {
    pageSize: 'A4',
    pageMargins: [15, 90, 15, 40],

    header: [reportTitle],
    content: [
      { text: 'Relação de Contas', style: 'header' },
      reportContent,
      details
    ],
    footer: Rodape,
    styles: styles
  };

  pdfMake.createPdf(docDefinitios).download();
}

export default RelContas;
