import { useState, useEffect } from 'react';

import { ApiDelete, ApiGet } from '../../../services/apiService';

import { format } from 'date-fns';

export const GridItems = (props) => {
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
    setSelectedRow(null);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelectedRow(null);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
    setSelectedRow(null);
  };

  const handleEdit = async (itemID) => {
    try {
      const response = await ApiGet(
        '/v1/consumo/item',
        { itemID: itemID },
        localStorage.getItem('sessionToken')
      );
      props.setFormDataItems(response.data.dados[0]);
      props.setActiveTab(4);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await ApiDelete(`/v1/consumo/item/${id}`, {});
      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      alert('Registro deletado com sucesso');

      const response1 = await ApiGet(
        '/v1/consumo/item',
        { numeroConsumo: props.formData.numeroConsumo },
        localStorage.getItem('sessionToken')
      );
      props.setDataItems(response1.data.dados);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFilteredData(props.dataItems.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.dataItems.length / itemsPerPage));
    setCurrentPage(0);
    setSelectedRow(null);
    // eslint-disable-next-line
  }, [props.dataItems]);

  useEffect(() => {
    setFilteredData(props.dataItems.slice(offset, offset + itemsPerPage));
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <div>
      <div>
        <table
          className="table table-striped table-hover table-bordered table-sm align-middle mt-1"
          style={{ height: '100%' }}
        >
          <thead>
            <tr className="table-secondary">
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Item
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Num. Consumo
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Desc. Item
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Valor
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Dt. Inclusão
              </th>
              <th
                scope="col"
                style={{ fontSize: props.tamTexto, width: '80px' }}
                className="grid"
              >
                Visualizar
              </th>
              {localStorage.getItem('sessionNivelDeAcesso') === '16' ||
              localStorage.getItem('sessionNivelDeAcesso') === '22' ||
              localStorage.getItem('sessionNivelDeAcesso') === '50' ||
              localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
                <th
                  scope="col"
                  style={{ fontSize: props.tamTexto, width: '80px' }}
                  className="grid"
                >
                  Editar
                </th>
              ) : null}
              {localStorage.getItem('sessionNivelDeAcesso') === '22' ||
              localStorage.getItem('sessionNivelDeAcesso') === '50' ||
              localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
                <th
                  scope="col"
                  style={{ fontSize: props.tamTexto, width: '80px' }}
                  className="grid"
                >
                  Deletar
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                key={index}
                className={selectedRow === index ? 'bg-info' : ''}
                onClick={() => handleRowClick(index)}
              >
                <td scope="row" style={{ fontSize: props.tamSubTexto }}>
                  {item.item}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.numeroConsumo}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.descricaoItem}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>{item.valor}</td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {format(new Date(item.dataInclusao), 'dd/MM/yyyy')}
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      style={{ fontSize: props.tamSubTexto }}
                      onClick={() => {
                        props.setViewOrUpdateItems('view');
                        handleEdit(item.itemID);
                      }}
                    >
                      <i className="fa-solid fa-eye"></i>
                    </button>
                  </div>
                </td>
                {localStorage.getItem('sessionNivelDeAcesso') === '16' ||
                localStorage.getItem('sessionNivelDeAcesso') === '22' ||
                localStorage.getItem('sessionNivelDeAcesso') === '50' ||
                localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
                  <td>
                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        style={{ fontSize: props.tamSubTexto }}
                        onClick={() => {
                          props.setInsertOrUpdateItems('update');
                          props.setViewOrUpdateItems('update');
                          handleEdit(item.itemID);
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                    </div>
                  </td>
                ) : null}
                {localStorage.getItem('sessionNivelDeAcesso') === '22' ||
                localStorage.getItem('sessionNivelDeAcesso') === '50' ||
                localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
                  <td>
                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm"
                        style={{ fontSize: props.tamSubTexto }}
                        onClick={async () => {
                          const id = item.itemID;
                          await handleDelete(id);
                        }}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="11" className="align-middle">
                <nav className="d-flex align-items-center justify-content-center">
                  <ul className="h-100 pagination pagination-sm">
                    <li className="page-item">
                      <button
                        className="page-link"
                        aria-label="PreviousPage"
                        onClick={() => handleFirstPage()}
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        aria-label="PreviousIten"
                        onClick={() => handlePageClick(currentPage - 1)}
                      >
                        <span aria-hidden="true">&lt;</span>
                      </button>
                    </li>
                    {displayedPageNumbers.map((pageNumber) => (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          pageNumber === currentPage ? 'active' : ''
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageClick(pageNumber)}
                        >
                          {pageNumber + 1}
                        </button>
                      </li>
                    ))}
                    <li className="page-item">
                      <button
                        className="page-link"
                        aria-label="NextIten"
                        onClick={() => handlePageClick(currentPage + 1)}
                      >
                        <span aria-hidden="true">&gt;</span>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        aria-label="NextPage"
                        onClick={() => handleLastPage()}
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </button>
                    </li>
                  </ul>
                  <p className="text-muted ms-2">
                    Número de Registros: {props.dataItems.length}
                  </p>
                </nav>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
