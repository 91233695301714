import { apiSalt } from '../../../services/api';
export const CargaLancamentos = async (props) => {
  const formData = new FormData();
  formData.append('empresa', '01');
  formData.append('loja', '00001');
  formData.append('file', props.file);
  formData.append('usuario', localStorage.getItem('sessionUsuario'));

  const result = await apiSalt
    .post('tabela/contabil/bulk', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('sessionToken')
      }
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });

  return result;
};
