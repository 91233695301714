import { ApiPut, NewApiPost } from '../../../services/apiService';
import { Grid } from './Grid';

import Swal from 'sweetalert2';

export function CtrNavegacao(props) {
  const handleChavent = (numres, seqenc, nompes, status, chave) => {
    if (status === 'AUSENTE' || status === 'CONCLUIDO') {
      Swal.fire({
        title: 'Esse hóspede não está presente',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Fechar',
        allowOutsideClick: false
      });
      return;
    }

    if (chave == 'SIM') {
      Swal.fire({
        title: 'Corrigir',
        html: 'Deseja desfazer a entrega da chave?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await ApiPut(
            '/v1/evento/entregarchave',
            {
              numero_reserva: numres,
              chave: 'nao',
              sequencial: seqenc,
              usuario: localStorage.getItem('sessionUsuario')
            },
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) != '100')
            alert(response.data?.mensagem?.msg);
          if (response.data?.evento === '100') {
            Swal.fire({
              title: 'Chave Recebida',
              icon: 'success',
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            props.props.handleSearch();
          }
        }
      });
    } else {
      Swal.fire({
        title: 'Confirmação',
        html: `Confirmar entrega de chave para:<br>${nompes}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await ApiPut(
            '/v1/evento/entregarchave',
            {
              numero_reserva: numres,
              chave: 'sim',
              sequencial: seqenc,
              usuario: localStorage.getItem('sessionUsuario')
            },
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) != '100')
            alert(response.data?.mensagem?.msg);
          if (response.data?.evento === '100') {
            Swal.fire({
              title: 'Chave Entregue',
              icon: 'success',
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            props.handleSearch();
          }
        }
      });
    }
  };

  const EntradaSim = (numres, seqenc, nome) => {
    Swal.fire({
      title: 'Confirmação',
      html: `Confirmar entrada do hóspede:<br>${nome}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await ApiPut(
          '/v1/evento/status',
          {
            numero_reserva: numres,
            sequencial: seqenc,
            status: 'presente',
            usuario: localStorage.getItem('sessionUsuario')
          },
          localStorage.getItem('sessionToken')
        );
        if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
          Swal.fire({
            title: 'Hóspede Presente',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Fechar',
            allowOutsideClick: false
          });
          props.handleSearch();
        } else {
          alert(response.data.mensagem.msg);
        }
      }
    });
  };

  const EntradaNao = (numres, seqenc) => {
    Swal.fire({
      title: 'Confirmação',
      html: `É para dar entrada em todos os hóspedes da acomodação que já fizeram check-in online`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false
    }).then(async (result) => {
      const response = await NewApiPost(
        '/reserva/v1/entrada',
        {
          numres: numres,
          seqenc: seqenc,
          usuario: localStorage.getItem('sessionUsuario')
        },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
        if (result.isConfirmed) {
          const response = await ApiPut(
            '/v1/evento/status',
            {
              numero_reserva: numres,
              sequencial: 0,
              status: 'presente',
              usuario: localStorage.getItem('sessionUsuario')
            }, // TODO: Todos os hospedes
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
            Swal.fire({
              title: 'Todos os hóspedes presentes',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            props.handleSearch();
          } else {
            alert(response.data.mensagem.msg);
          }
        } else {
          const response = await ApiPut(
            '/v1/evento/status',
            {
              numero_reserva: numres,
              sequencial: seqenc,
              status: 'presente',
              usuario: localStorage.getItem('sessionUsuario')
            }, // TODO: muda apenas um hospedes
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
            Swal.fire({
              title: 'Hóspedes presente',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            props.handleSearch();
          } else {
            alert(response.data.mensagem.msg);
          }
        }
      } else {
        alert(response.data.mensagem.msg);
      }
    });
  };

  const handleEntrada = (entrada, numres, seqenc, nome, status) => {
    if (status === 'PRESENTE') {
      Swal.fire({
        title: 'Esse hóspede já está presente',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Fechar',
        allowOutsideClick: false
      });
      return;
    }

    if (entrada === 'SIM') {
      EntradaSim(numres, seqenc, nome);
    } else {
      EntradaNao(numres, seqenc);
    }
  };

  const handleSaida = (numres, seqenc, chave, status) => {
    if (status === 'CONCLUIDO') {
      Swal.fire({
        title: 'Hóspede já devolveu a chave',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Fechar',
        allowOutsideClick: false
      });
      return;
    }

    if (chave === 'NAO') {
      Swal.fire({
        title: 'Deseja dar baixa nesse hóspede?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await ApiPut(
            '/v1/evento/status',
            {
              numero_reserva: numres,
              sequencial: seqenc,
              status: 'concluido',
              usuario: localStorage.getItem('sessionUsuario')
            }, // TODO: Só um hóspede chegou
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
            Swal.fire({
              title: 'Dado baixa no hóspede',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            props.handleSearch();
          } else {
            alert(response.data.mensagem.msg);
          }
        }
      });
    } else {
      Swal.fire({
        title: 'Devolução da Chave',
        html: `É para dar baixa em todos os hóspedes da acomodação?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await ApiPut(
            '/v1/evento/status',
            {
              numero_reserva: numres,
              sequencial: 0,
              status: 'concluido',
              usuario: localStorage.getItem('sessionUsuario')
            }, // TODO: Todos os hospedes
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
            Swal.fire({
              title: 'Dado baixa em todos os hóspedes',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            props.handleSearch();
          } else {
            alert(response.data.mensagem.msg);
          }
        } else {
          const response = await ApiPut(
            '/v1/evento/status',
            {
              numero_reserva: numres,
              sequencial: seqenc,
              status: 'concluido',
              usuario: localStorage.getItem('sessionUsuario')
            }, // TODO: Só um hóspede chegou
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
            Swal.fire({
              title: 'Dado baixa no hóspede',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            props.handleSearch();
          } else {
            alert(response.data.mensagem.msg);
          }
        }
      });
    }
  };

  return (
    <Grid
      data={props.data}
      setData={props.setData}
      tamTexto={props.tamTexto}
      tamSubTexto={props.tamSubTexto}
      dataFiltro={props.dataFiltro}
      handleSearch={props.handleSearch}
      handleChavent={handleChavent}
      handleEntrada={handleEntrada}
      handleSaida={handleSaida}
      dataEmpresa={props.dataEmpresa}
    />
  );
}
