import { NumericFormat } from 'react-number-format';

export const CadPedidos = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Empresa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_empresa"
              value={props.formData?.mov_empresa || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Loja</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_loja"
              value={props.formData?.mov_loja || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Und. Aministrativa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_undadm"
              value={props.formData?.mov_undadm || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Num Mov</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Tip Mov</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_nummov"
              value={props.formData?.mov_nummov || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_tipmov"
              value={props.formData?.mov_tipmov || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Num Ped</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Tip Ped</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_numped"
              value={props.formData?.mov_numped || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_tipped"
              value={props.formData?.mov_tipped || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">For Pag</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Tip Pag</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_forpag"
              value={props.formData?.mov_forpag || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_tippag"
              value={props.formData?.mov_tippag || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Num Doc</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Tip Doc</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_numdoc"
              value={props.formData?.mov_numdoc || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_tipdoc"
              value={props.formData?.mov_tipdoc || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Dat Mov</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Dat Atz</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="mov_datmov"
              value={props.formData?.mov_datmov?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="mov_datatz"
              value={props.formData?.mov_datatz?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Valor</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Val Tot</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <NumericFormat
              value={props.formData.mov_valor || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  mov_valor: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-4">
            <NumericFormat
              value={props.formData.mov_valtot || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  mov_valtot: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Usuario</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="mov_usuario"
              value={props.formData?.mov_usuario || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
