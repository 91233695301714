export function CadGeral(props) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Código e Cód. Lançamento Sequencial */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Código</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Cód. Lançamento</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Sequencial</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="id"
              value={props.formData?.id || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="codigoLancamento"
              value={props.formData?.codigoLancamento || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="sequencial"
              value={props.formData?.sequencial || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.insertOrUpdate === 'read' && true}
            />
          </div>
        </div>

        {/* Empresa */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Empresa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="empresa"
              value={props.formData?.empresa || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              {props.optionsEmpresa?.map((option, index) => (
                <option key={index} value={option.emp_codemp}>
                  {option.emp_razsoc}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Loja */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Loja</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="loja"
              value={props.formData?.loja || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              {props.optionsLoja?.map((option, index) => (
                <option key={index} value={option.loj_codloj}>
                  {option.loj_denloj}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Data de Inclusão */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Data de Inclusão</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="dataInclusao"
              value={props.formData?.dataInclusao?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            />
          </div>
        </div>

        {/* Data de Atualização e Hora de Atualização */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Data de Atualização</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Hora de Atualização</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="dataAtualizacao"
              value={props.formData?.dataAtualizacao?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="horaAtualizacao"
              value={props.formData?.horaAtualizacao || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            />
          </div>
        </div>

        {/* Usuário */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Usuário</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="usuario"
              value={
                props.formData?.usuario ||
                localStorage.getItem('sessionUsuario')
              }
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Conta Débito e Conta Débito */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Conta Débito</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Conta Crédito</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="contaDebito"
              value={props.formData?.contaDebito || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="contaCredito"
              value={props.formData?.contaCredito || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            />
          </div>
        </div>

        {/* Centro de Custo */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Centro de Custo</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="centroCusto"
              value={props.formData?.centroCusto || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              {props.optionsCencus?.map((option, index) => (
                <option key={index} value={option.tcc_codtcc}>
                  {option.tcc_nomtcc}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Natureza da Despesa */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Natureza da Depesa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="naturezaDespesa"
              value={props.formData?.naturezaDespesa || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              {props.optionsNatdes?.map((option, index) => (
                <option key={index} value={option.tnd_codnat}>
                  {option.tnd_nomnat}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Fonte de Recurso */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Fonte de Recurso</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="fonteRecurso"
              value={props.formData?.fonteRecurso || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              {props.optionsFonrec?.map((option, index) => (
                <option key={index} value={option.tfo_codfon}>
                  {option.tfo_nomfon}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
