import ReactSelect from 'react-select';

export const FiltroDropdown = ({
  listaItems,
  setValue,
  title,
  isDate = false
}) => {
  const handleChange = (event) => {
    setValue(event);
  };

  const options = listaItems?.map((item) => {
    return { value: item.item, label: item.item, codigo: item.codigo };
  });

  return (
    <div
      className="d-flex flex-column w-100 p-1 rounded"
      style={{ backgroundColor: '#fff' }}
    >
      <label className="form-label m-0">{title}</label>
      {/* <select
        className="form-select"
        onChange={handleChange}
        value={value}
        style={{ marginTop: -8 }}
      >
        {title !== 'Tipo Periodo' && <option value="">Todos</option>}
        {listaItems?.map(
          (item, index) =>
            item.item !== null && (
              <option key={index} value={item.item}>
                {item.item}
              </option>
            )
        )}
      </select> */}

      <ReactSelect
        closeMenuOnSelect={false}
        // defaultValue={[colourOptions[0], colourOptions[1]]}
        isMulti={!isDate}
        options={options}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            margin: 0,
            overflowY: 'auto',
            maxHeight: '65px'
          })
        }}
        onChange={handleChange}
      />
    </div>
  );
};
