import { useEffect, useState } from 'react';

import { ApiGet } from '../../../services/apiService';
import { CadConta } from './CadConta';

export const Contas = (props) => {
  const [optionsTipcar, setOptionsTipcar] = useState([]);

  const handleInput = (event) => {
    const { name, value } = event.target;
    props.setDataContas({ ...props.dataContas, [name]: value });
  };

  const handleComboboxTipcar = async () => {
    try {
      const response = await ApiGet(
        '/tabela/cartaoCredito',
        {
          situacaoCartao: 'ativo',
          codigoBanco: props.dataContas?.bandeira || '',
          quantidade: props.dataContas?.parcela || null,
          codigoPessoa: props.dataContas?.creddev || '',
          tipoCartao:
            props.dataContas?.tippag === '00003' ||
            props.dataContas?.tippag === '00027'
              ? 'debito'
              : props.dataContas?.tippag === '00005'
              ? 'credito'
              : ''
        },
        localStorage.getItem('sessionToken')
      );
      setOptionsTipcar(response.data.tabela);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      (props.dataContas?.tippag !== null &&
        props.dataContas?.tippag !== undefined &&
        props.dataContas?.tippag !== '') ||
      (props.dataContas?.creddev !== null &&
        props.dataContas?.creddev !== undefined &&
        props.dataContas?.creddev !== '') ||
      (props.dataContas?.bandeira !== null &&
        props.dataContas?.bandeira !== undefined &&
        props.dataContas?.bandeira !== '') ||
      (props.dataContas?.parcela !== null &&
        props.dataContas?.parcela !== undefined &&
        props.dataContas?.parcela !== '')
    ) {
      handleComboboxTipcar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.dataContas.tippag,
    props.dataContas.creddev,
    props.dataContas.bandeira,
    props.dataContas.parcela
  ]);

  return (
    <CadConta
      handleInput={handleInput}
      dataContas={props.dataContas}
      optionsTipcar={optionsTipcar}
      options={props.options}
      optionsContas={props.optionsContas}
    />
  );
};
