export function CadHospede(props) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Cód. Hóspede e Nome Hóspede */}
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Hóspede</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Hóspede</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_codhos"
              value={props.formData?.res_codhos || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_nomhos"
              value={props.formData?.res_nomhos || ''}
              onChange={props.handleInput}
              disabled
              style={{ textTransform: 'uppercase' }}
            />
          </div>
        </div>

        {/* Email do Hóspede */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Email do Hóspede</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_email"
              value={props.formData.res_email || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'lowercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Email da Reserva */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Email da Reserva</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_email"
              value={props.formData.res_email || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'lowercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Nacionalidade */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Nacionalidade</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_nacion"
              value={props.formData.res_nacion || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* DDD e Telefone Celular */}
        <div className="col-12 row">
          <div className="col-2">
            <span className="col-form-label">DDD</span>
          </div>
          <div className="col-10">
            <span className="col-form-label">Telefone Celular</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_precel"
              value={props.formData?.res_precel || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-10">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_telcel"
              value={props.formData?.res_telcel || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* DDD e Telefone Residencial */}
        <div className="col-12 row">
          <div className="col-2">
            <span className="col-form-label">DDD</span>
          </div>
          <div className="col-10">
            <span className="col-form-label">Telefone Residencial</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_preres"
              value={props.formData?.res_preres || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-10">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_telres"
              value={props.formData?.res_telres || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* DDD e Telefone Comercial */}
        <div className="col-12 row">
          <div className="col-2">
            <span className="col-form-label">DDD</span>
          </div>
          <div className="col-10">
            <span className="col-form-label">Telefone Comercial</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_precom"
              value={props.formData?.res_precom || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-10">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_telcom"
              value={props.formData?.res_telcom || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
