import axios from 'axios';

// HOMEPAGE: https://siegestao.com.br
// HOMEPAGE: https://gestao.salthomol.com.br/

// DEV
// const urlSalt = 'http://168.90.5.99:3003';
// const urlSalt = 'http://192.168.1.6:3003';
// const urlSalt = 'http://192.168.1.50:3005';
// const urlSalt = 'http://192.168.1.151:3005';

// HOMOL
// const urlSalt = 'https://siegestao.com.br:21074'
const urlSalt = 'https://sie.app.br:21074';

const apiSalt = axios.create({
  baseURL: urlSalt
});

apiSalt.interceptors.response.use(
  (response) => {
    if (response?.data?.mensagem?.msg === '405: AUTH INVALIDA;') {
      location.reload();
    }

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('sessionToken');

      if (!document.location.href.includes('/', 1)) {
        document.location = '/';
      }
    }
    return error;
  }
);

// api Rafael
const urlrj = 'https://oficialfood.com.br:21010/';
const apirj = axios.create({
  baseURL: urlrj
});

apirj.interceptors.request.use(
  (req) => {
    if (localStorage.getItem('sessionToken')) {
      req.headers.Authorization = `Bearer ${localStorage.getItem(
        'sessionToken'
      )}`;
    }

    return req;
  },
  (err) => {
    console.log(err);
  }
);

apirj.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('sessionToken');

      if (!document.location.href.includes('/login', 1)) {
        document.location = '/login';
      }
    }
    return error;
  }
);

export { apiSalt, apirj };
