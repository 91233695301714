import { useEffect, useState } from 'react';

export const Grid = (props) => {
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
    setSelectedRow(null);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelectedRow(null);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
    setSelectedRow(null);
  };

  useEffect(() => {
    if (Array.isArray(props.dataGrid)) {
      setFilteredData(props.dataGrid.slice(offset, offset + itemsPerPage));
      setPageCount(Math.ceil(props.dataGrid.length / itemsPerPage));
      setCurrentPage(0);
      setSelectedRow(null);
    }
  }, [props.dataGrid]);

  useEffect(() => {
    if (Array.isArray(props.dataGrid)) {
      setFilteredData(props.dataGrid.slice(offset, offset + itemsPerPage));
    }
  }, [currentPage]);

  return (
    <table
      className="table table-striped table-hover table-bordered table-sm align-middle"
      style={{ height: '100%' }}
    >
      <thead>
        <tr className="table-secondary">
          <th scope="col" style={{ fontSize: props.tamTexto }}>
            Cód.
          </th>
          <th scope="col" style={{ fontSize: props.tamTexto }}>
            Centro de Custo
          </th>
          <th scope="col" style={{ fontSize: props.tamTexto }}>
            Natureza da Despesa
          </th>
          <th scope="col" style={{ fontSize: props.tamTexto, width: '80px' }}>
            Visualizar
          </th>
          {localStorage.getItem('sessionNivelDeAcesso') === '16' ||
          localStorage.getItem('sessionNivelDeAcesso') === '22' ||
          localStorage.getItem('sessionNivelDeAcesso') === '50' ||
          localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
            <th scope="col" style={{ fontSize: props.tamTexto, width: '80px' }}>
              Atualizar
            </th>
          ) : null}
          {localStorage.getItem('sessionNivelDeAcesso') === '22' ||
          localStorage.getItem('sessionNivelDeAcesso') === '50' ||
          localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
            <th scope="col" style={{ fontSize: props.tamTexto, width: '80px' }}>
              Deletar
            </th>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {filteredData?.map((item, index) => (
          <tr
            key={index}
            className={selectedRow === index ? 'bg-info' : ''}
            onClick={() => handleRowClick(index)}
          >
            <th scope="row" style={{ fontSize: props.tamSubTexto }}>
              {item.id}
            </th>
            <td style={{ fontSize: props.tamSubTexto }}>{item.centroCusto}</td>
            <td style={{ fontSize: props.tamSubTexto }}>
              {item.naturezaDespesa}
            </td>
            <td>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => {
                    props.setData({
                      empresa: item.empresa || '',
                      centroCusto: item.centroCusto || '',
                      fonteRecurso: item.fonteRecurso || '',
                      naturezaDespesa: item.naturezaDespesa || '',
                      codigoLancamento: item.codigoLancamento || '',
                      contaDebito: item.contaDebito || '',
                      contaCredito: item.contaCredito || '',
                      usuario: item.usuario || '',
                      dataAtualizacao: item.dataAtualizacao || '',
                      dataInclusao: item.dataInclusao || '',
                      horaAtualizacao: item.horaAtualizacao || '',
                      id: item.id || '',
                      sequencial: item.sequencial || ''
                    });

                    props.setInsertOrUpdate('read');
                    props.setNvAcesso('read');
                    props.setActiveTab(2);
                    props.setTabReturn(1);
                  }}
                  style={{ fontSize: props.tamSubTexto }}
                >
                  <i className="fa-solid fa-eye"></i>
                </button>
              </div>
            </td>
            {localStorage.getItem('sessionNivelDeAcesso') === '16' ||
            localStorage.getItem('sessionNivelDeAcesso') === '22' ||
            localStorage.getItem('sessionNivelDeAcesso') === '50' ||
            localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
              <td>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => {
                      props.setData({
                        empresa: item.empresa || '',
                        centroCusto: item.centroCusto || '',
                        fonteRecurso: item.fonteRecurso || '',
                        naturezaDespesa: item.naturezaDespesa || '',
                        codigoLancamento: item.codigoLancamento || '',
                        contaDebito: item.contaDebito || '',
                        contaCredito: item.contaCredito || '',
                        usuario: item.usuario || '',
                        dataAtualizacao: item.dataAtualizacao || '',
                        dataInclusao: item.dataInclusao || '',
                        horaAtualizacao: item.horaAtualizacao || '',
                        id: item.id || '',
                        sequencial: item.sequencial || ''
                      });

                      props.setInsertOrUpdate('update');
                      props.setNvAcesso('update');
                      props.setActiveTab(2);
                      props.setTabReturn(1);
                    }}
                    style={{ fontSize: props.tamSubTexto }}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                </div>
              </td>
            ) : null}
            {localStorage.getItem('sessionNivelDeAcesso') === '22' ||
            localStorage.getItem('sessionNivelDeAcesso') === '50' ||
            localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
              <td>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={async () => {
                      const id = item.id;
                      await props.handleDelete(id);
                    }}
                    style={{ fontSize: props.tamSubTexto }}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
              </td>
            ) : null}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="6" className="align-middle">
            <nav className="d-flex align-items-center justify-content-center">
              <ul className="h-100 pagination pagination-sm">
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="PreviousPage"
                    onClick={() => handleFirstPage()}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="PreviousIten"
                    onClick={() => handlePageClick(currentPage - 1)}
                  >
                    <span aria-hidden="true">&lt;</span>
                  </button>
                </li>
                {displayedPageNumbers.map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === currentPage ? 'active' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageClick(pageNumber)}
                    >
                      {pageNumber + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="NextIten"
                    onClick={() => handlePageClick(currentPage + 1)}
                  >
                    <span aria-hidden="true">&gt;</span>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="NextPage"
                    onClick={() => handleLastPage()}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </li>
              </ul>
            </nav>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
