import { useState, useEffect } from 'react';

export const GridItems = (props) => {
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
    setSelectedRow(null);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelectedRow(null);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
    setSelectedRow(null);
  };

  useEffect(() => {
    setFilteredData(props.dataItems.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.dataItems.length / itemsPerPage));
    setCurrentPage(0);
    setSelectedRow(null);
    // eslint-disable-next-line
  }, [props.dataItems]);

  useEffect(() => {
    setFilteredData(props.dataItems.slice(offset, offset + itemsPerPage));
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <div>
      <div>
        <table
          className="table table-striped table-hover table-bordered table-sm align-middle"
          style={{ height: '100%' }}
        >
          <thead>
            <tr className="table-secondary">
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Emp
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Loja
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Num Ped
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Seq Item
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Cod Item
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Desc item
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Unid Emb
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Qtd de Emb
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Qtd por Emb
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Val Uni Emb
              </th>
              <th
                scope="col"
                className="grid"
                style={{ fontSize: props.tamTexto }}
              >
                Val Tot
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                key={index}
                className={selectedRow === index ? 'bg-info' : ''}
                onClick={() => handleRowClick(index)}
              >
                <th scope="row" style={{ fontSize: props.tamSubTexto }}>
                  {item.ipd_empresa}
                </th>
                <td style={{ fontSize: props.tamSubTexto }}>{item.ipd_loja}</td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.ipd_numped}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.ipd_seqitm}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>{item.ipd_item}</td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.ipd_desitm}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.ipd_unidemb}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.ipd_qtddeemb}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.ipd_qtdporemb}
                </td>
                <td
                  className="text-end"
                  style={{ fontSize: props.tamSubTexto }}
                >
                  {item.ipd_valuniemb.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2
                  })}
                </td>
                <td
                  className="text-end"
                  style={{ fontSize: props.tamSubTexto }}
                >
                  {item.ipd_valitm.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2
                  })}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="11" className="align-middle">
                <nav className="d-flex align-items-center justify-content-center">
                  <ul className="h-100 pagination pagination-sm">
                    <li className="page-item">
                      <button
                        className="page-link"
                        aria-label="PreviousPage"
                        onClick={() => handleFirstPage()}
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        aria-label="PreviousIten"
                        onClick={() => handlePageClick(currentPage - 1)}
                      >
                        <span aria-hidden="true">&lt;</span>
                      </button>
                    </li>
                    {displayedPageNumbers.map((pageNumber) => (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          pageNumber === currentPage ? 'active' : ''
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageClick(pageNumber)}
                        >
                          {pageNumber + 1}
                        </button>
                      </li>
                    ))}
                    <li className="page-item">
                      <button
                        className="page-link"
                        aria-label="NextIten"
                        onClick={() => handlePageClick(currentPage + 1)}
                      >
                        <span aria-hidden="true">&gt;</span>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        aria-label="NextPage"
                        onClick={() => handleLastPage()}
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </button>
                    </li>
                  </ul>
                  <p className="text-muted ms-2">
                    Número de Registros: {props.dataItems.length}
                  </p>
                </nav>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
