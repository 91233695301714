import { useEffect, useState } from 'react';

import { getStartAndEndDates } from '../../../../functions/getStartAndEndDates';
import { ApiGet } from '../../../../services/apiService';
import { ChartBar, ChartLine, ChartPieQt } from '../../graficos';
import { TabelaDinamica } from '../../graficos/TabelaDinamica';
import { FiltroDropdown } from '../FiltroDropdown';
import { FiltrosDate } from '../FiltrosDate';

export const AnaliseVendaHotel = () => {
  const [dataLine, setDataLine] = useState([]);
  const [dataPieVL, setDataPieVL] = useState([]);
  const [dataPieQT, setDataPieQT] = useState([]);
  const [dataBar, setDataBar] = useState([]);
  const [dataSheet, setDataSheet] = useState([]);
  const [empresa, setEmpresa] = useState([]);
  const [dataDropdownCliente, setDataDropdownCliente] = useState([]);

  const loadDataFilter = getStartAndEndDates(3);

  const [startDate, setStartDate] = useState(loadDataFilter.startDate);
  const [endDate, setEndDate] = useState(loadDataFilter.endDate);

  useEffect(() => {
    let empresas = empresa
      .map((selectedEmpresa) => {
        let matchedItem = dataDropdownCliente.find(
          (item) => item.item === selectedEmpresa.value
        );
        return matchedItem ? matchedItem.value : null;
      })
      .filter((id) => id !== null);

    const requests = [
      ApiGet(
        '/v1/graficos/hotel/venda/valdiaxtiphos',
        { startDate, endDate, empresas },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        '/v1/graficos/hotel/venda/quantxtiphos',
        { startDate, endDate, empresas },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        '/v1/graficos/hotel/venda/vendaxmes',
        { startDate, endDate, empresas },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        '/v1/graficos/hotel/venda/topEmpresa',
        { startDate, endDate },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        '/v1/graficos/hotel/venda/tabelaDetalhe',
        { startDate, endDate, empresas },
        localStorage.getItem('sessionToken')
      )
    ];

    Promise.all(requests)
      .then((responses) => {
        let gDataPieVL = responses[0].data.dados.map((item) => ({
          nome: item.nome,
          sum: item.valor_total
        }));

        let gDataPieQT = responses[1].data.dados.map((item) => ({
          nome: item.nome,
          sum: parseInt(item.quantidade)
        }));

        let dataLine = responses[2].data.dados.map((item) => ({
          periodo: item.data,
          sum: item.total_valdia
        }));
        let dataBar = responses[3].data.dados.map((item) => ({
          cliente: item.nome,
          sumvalitem: item.total_valdia
        }));

        setDataPieVL(gDataPieVL);
        setDataPieQT(gDataPieQT);
        setDataLine(dataLine);
        setDataBar(dataBar);
        setDataSheet(responses[4].data.dados);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [startDate, endDate, empresa, dataDropdownCliente]);

  useEffect(() => {
    const requests = [
      ApiGet(
        '/v1/graficos/hotel/venda/filtro/empresa',
        {},
        localStorage.getItem('sessionToken')
      )
    ];

    Promise.all(requests)
      .then((responses) => {
        let listaEmpresas = responses[0].data.dados.map((item) => {
          return { item: item.nome, value: item.id };
        });
        setDataDropdownCliente(listaEmpresas);
      })
      .catch((error) => {
        console.error('Error fetching dropdown data:', error);
      });
  }, []);

  return (
    <div className="w-100" style={{ backgroundColor: '#eeeeee' }}>
      <h4>Dashboard Análise Vendas</h4>
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 mb-2 p-1">
        <FiltrosDate
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <FiltroDropdown
          title="EMPRESA"
          listaItems={dataDropdownCliente.sort((a, b) =>
            a.item.localeCompare(b.item)
          )}
          value={empresa}
          setValue={setEmpresa}
        />
      </div>
      <div className="w-100 h-100">
        <div className="d-flex flex-column flex-md-row justify-content-center text-center w-100 h-100">
          <ChartLine data={dataLine} />
          <TabelaDinamica
            title="Tabela Vendas Hotel"
            data={dataSheet}
            headers={[
              { label: 'Empresa', value: 'nome' },
              {
                label: 'Receita de Hospedagem',
                value: 'total_valdia',
                align: 'right',
                type: 'monetary'
              },
              {
                label: 'Diária Média',
                value: 'avg_val1dia',
                align: 'right',
                type: 'monetary'
              },
              {
                label: 'Rooming Night',
                value: 'total_days',
                align: 'right',
                type: 'integer'
              }
            ]}
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center text-center w-100 h-100">
          <ChartPieQt
            data={dataPieVL}
            titulo={'Gráfico Valor Total'}
            isMonetary={true}
          />
          <ChartPieQt
            data={dataPieQT}
            titulo={'Gráfico Quantidade'}
            isMonetary={false}
          />
          <ChartBar data={dataBar} />
        </div>
      </div>
    </div>
  );
};
