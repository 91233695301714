export function CadTelefone(props) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Celular */}
        <div className="col-12 row">
          <div className="col-2">
            <span className="col-form-label">DDD</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Celular</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_precel"
              value={props.formData?.pes_precel || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_telcel"
              value={props.formData?.pes_telcel || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Residencial */}
        <div className="col-12 row">
          <div className="col-2">
            <span className="col-form-label">DDD</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Residencial</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_preres"
              value={props.formData?.pes_preres || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_telres"
              value={props.formData?.pes_telres || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Comercial */}
        <div className="col-12 row">
          <div className="col-2">
            <span className="col-form-label">DDD</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Comercial</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_precom"
              value={props.formData?.pes_precom || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_telcom"
              value={props.formData?.pes_telcom || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
