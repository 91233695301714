import { ApiGet, NewApiGet } from '../../../../services/apiService';

export const fetchDataGraficoPizzaEstoque = async (
  startDate = '',
  endDate = '',
  unidadeAdm = '',
  tipoMovimentacao = ''
) => {
  try {
    const response = await ApiGet(
      `/v1/graficos/comprasTrasferencias`,
      {
        dataInicial: startDate,
        dataFinal: endDate,
        unidadeAdm: unidadeAdm,
        tipoMovimentacao: tipoMovimentacao
      },
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataTabelaEstoque = async (
  startDate = '',
  endDate = '',
  unidadeAdm = '',
  tipoMovimentacao = ''
) => {
  try {
    const response = await ApiGet(
      `/v1/graficos/tabelaComprasTransferencias`,
      {
        dataInicial: startDate,
        dataFinal: endDate,
        unidadeAdm: unidadeAdm,
        tipoMovimentacao: tipoMovimentacao
      },
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataDropdown = async () => {
  try {
    const response = await NewApiGet(
      `/v1/graficos/filtro/CentroCustoNaturezaFonteReceita`,
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataDropdownUnidAdm = async () => {
  try {
    const response = await NewApiGet(
      `/v1/graficos/filtro/estoqueAdmLoja`,
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
