import { useEffect, useState } from 'react';

import { format } from 'date-fns';

const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: props.tamTexto }}>{props.title}</span>
        <div>
          <span onClick={() => props.handleSort(props.order, 'asc')}>
            <i className="fa-solid fa-arrow-up ms-2"></i>
          </span>
          <span
            className="ms-1"
            onClick={() => props.handleSort(props.order, 'desc')}
          >
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export function Grid(props) {
  const campos = [
    { title: 'Tipo', order: 'tipo' },
    { title: 'Nº Reserva', order: 'num' },
    { title: 'Situação', order: 'sit' },
    { title: 'Nome Hóspede', order: 'nome' },
    { title: 'Nº Apto', order: 'napa' },
    { title: 'Dt Venda', order: 'dtincl' },
    { title: 'Dt Entrada', order: 'dtent' },
    { title: 'Dt Saída', order: 'dtsai' }
  ];

  const totalVendaForaMes = props.data.filter((item) => {
    const itemDate = Date.parse(item.hos_datent);
    const startDate = Date.parse(
      props.dataFiltro.datinclInicial.split('/').reverse().join('-')
    );
    let endDate = Date.parse(
      props.dataFiltro.datinclFinal.split('/').reverse().join('-')
    );
    endDate += 24 * 60 * 60 * 1000; // adiciona 1 dia em milissegundos
    return itemDate < startDate || itemDate > endDate;
  });

  const totalVendaDentroMes = props.data?.filter((item) => {
    const itemDate = Date.parse(item.hos_datent);
    const startDate = Date.parse(
      props.dataFiltro.datinclInicial.split('/').reverse().join('-')
    );
    let endDate = Date.parse(
      props.dataFiltro.datinclFinal.split('/').reverse().join('-')
    );
    endDate += 24 * 60 * 60 * 1000; // adiciona 1 dia em milissegundos
    return itemDate >= startDate && itemDate <= endDate;
  });

  const totalVendaReserva = props.data?.filter((item) => {
    return item.tabela === 'RES';
  });

  const totalVendaEntrada = props.data?.filter((item) => {
    return item.tabela === 'ENT';
  });

  const totalVendaSaida = props.data?.filter((item) => {
    return item.tabela === 'SAI';
  });

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'tipo':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.tabela?.localeCompare(b.tabela)
          )
        );
        break;
      case 'num':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.hos_numres.localeCompare(b.hos_numres)
          )
        );
        break;
      case 'sit':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.hos_sit?.localeCompare(b.hos_sit)
          )
        );
        break;
      case 'nome':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier *
              a.hos_nomhos?.trim()?.localeCompare(b.hos_nomhos?.trim())
          )
        );
        break;
      case 'napa':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.hos_numapa?.localeCompare(b.hos_numapa)
          )
        );
        break;
      case 'dtincl':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.hos_datincl?.localeCompare(b.hos_datincl)
          )
        );
        break;
      case 'dtent':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.hos_datent?.localeCompare(b.hos_datent)
          )
        );
        break;
      case 'dtsai':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.hos_datsai?.localeCompare(b.hos_datsai)
          )
        );
        break;
      default:
        props.setData([...props.data]);
        break;
    }
  };

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.data.length / itemsPerPage));
    setCurrentPage(0);
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <table
        className="table table-striped table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            {campos.map((campo) => (
              <BlcTitle
                key={campo.order}
                title={campo.title}
                order={campo.order}
                handleSort={handleSort}
                tamTexto={props.tamTexto}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((data, index) => (
            <tr key={index}>
              <td style={{ fontSize: props.tamSubTexto }}>{data.tabela}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.hos_numres}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.hos_sit}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.hos_nomhos}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.hos_numapa}</td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {format(new Date(data.hos_datincl), 'dd/MM/yyyy')}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {format(new Date(data.hos_datent), 'dd/MM/yyyy')}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {format(new Date(data.hos_datsai), 'dd/MM/yyyy')}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="8" className="align-middle">
              <nav className="d-flex align-items-center justify-content-center">
                <ul className="h-100 pagination pagination-sm">
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousPage"
                      onClick={() => handleFirstPage()}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousIten"
                      onClick={() => handlePageClick(currentPage - 1)}
                    >
                      <span aria-hidden="true">&lt;</span>
                    </button>
                  </li>
                  {displayedPageNumbers.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        pageNumber === currentPage ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(pageNumber)}
                      >
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextIten"
                      onClick={() => handlePageClick(currentPage + 1)}
                    >
                      <span aria-hidden="true">&gt;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextPage"
                      onClick={() => handleLastPage()}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
          <tr>
            <td colSpan="8" className="align-middle">
              <div className="d-flex gap-2">
                <div className="d-flex flex-column">
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Total Reservas Vendidas:{' '}
                    {props.data.length.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Total Vendas Fora Mês:{' '}
                    {totalVendaForaMes.length.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Total Vendas Dentro Mês:{' '}
                    {totalVendaDentroMes.length.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </p>
                </div>

                <div className="d-flex flex-column">
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Total Vendas na Reserva:{' '}
                    {totalVendaReserva.length.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Total Vendas na Entrada:{' '}
                    {totalVendaEntrada.length.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Total Vendas na Saída:{' '}
                    {totalVendaSaida.length.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}
