import { useEffect, useState } from 'react';

const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: props.tamTexto }}>{props.title}</span>
        <div>
          <span onClick={() => props.handleSort(props.order, 'asc')}>
            <i className="fa-solid fa-arrow-up ms-2"></i>
          </span>
          <span
            className="ms-1"
            onClick={() => props.handleSort(props.order, 'desc')}
          >
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export function Cadastro(props) {
  const campos = [
    { title: 'Nome Hóspede', order: 'nome' },
    { title: 'Nº Reserva', order: 'numres' },
    { title: 'Nº Apto', order: 'numapa' },
    { title: 'Status', order: 'status' },
    { title: 'CkOnline', order: 'checkin' },
    { title: 'Chave Ent', order: 'chaveent' }
  ];

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  const reservasUnicas = new Set(
    props.formData?.map((item) => item.res_numres)
  );

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'nome':
        props.setFormData(
          [...props.formData].sort(
            (a, b) =>
              multiplier *
              a.enh_nomctt?.trim().localeCompare(b.enh_nomctt?.trim())
          )
        );
        break;
      case 'numres':
        props.setFormData(
          [...props.formData].sort(
            (a, b) => multiplier * a.res_numres?.localeCompare(b.res_numres)
          )
        );
        break;
      case 'numapa':
        props.setFormData(
          [...props.formData].sort(
            (a, b) => multiplier * a.res_numapa?.localeCompare(b.res_numapa)
          )
        );
        break;
      case 'status':
        props.setFormData(
          [...props.formData].sort(
            (a, b) => multiplier * a.enh_status?.localeCompare(b.enh_status)
          )
        );
        break;
      case 'checkin':
        props.setFormData(
          [...props.formData].sort(
            (a, b) => multiplier * a.enh_checkin?.localeCompare(b.enh_checkin)
          )
        );
        break;
      case 'chaveent':
        props.setFormData(
          [...props.formData].sort(
            (a, b) => multiplier * a.enh_chaveent?.localeCompare(b.enh_chaveent)
          )
        );
        break;
      default:
        props.setFormData([...props.formData]);
        break;
    }
  };

  useEffect(() => {
    setFilteredData(props.formData.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.formData.length / itemsPerPage));
    setCurrentPage(0);
  }, [props.formData]);

  useEffect(() => {
    setFilteredData(props.formData.slice(offset, offset + itemsPerPage));
  }, [currentPage]);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <p>{props.title}</p>
      </div>

      <table
        className="table table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            {campos.map((campo) => (
              <BlcTitle
                key={campo.order}
                title={campo.title}
                order={campo.order}
                handleSort={handleSort}
                tamTexto={props.tamTexto}
              />
            ))}
            <th style={{ width: '100px' }} scope="col" className="grid">
              Documentos
            </th>
            <th style={{ width: '100px' }} scope="col" className="grid">
              Processos
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((data, index) => (
            <tr
              key={index}
              className={
                data.enh_status === 'PRESENTE' ||
                data.enh_status === 'CONCLUIDO'
                  ? 'table-success'
                  : data.enh_checkin === 'SIM'
                  ? 'table-warning'
                  : 'table-danger'
              }
            >
              <td scope="row" style={{ fontSize: props.tamSubTexto }}>
                {data.enh_nomctt}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.res_numres}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.res_numapa}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.enh_status}</td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {data.enh_checkin ? data.enh_checkin : 'NAO'}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {data.enh_chaveent ? data.enh_chaveent : 'NAO'}
              </td>
              <td>
                <div style={{ display: 'flex', gap: '5px' }}>
                  {data.enh_checkin === 'SIM' && (
                    <>
                      <button className="btn btn-secondary btn-sm">
                        <a
                          // href={`http://localhost:5173/${props.dataEmpresa.sigorg}/fnrh/${data.res_numres}/${data.enh_codpes}`}
                          // href={`https://checkin.salthomol.com.br/${props.dataEmpresa.sigorg}/fnrh/${data.res_numres}/${data.enh_codpes}`}
                          href={`https://checkin.sie.app.br/${props.dataEmpresa.sigorg}/fnrh/${data.res_numres}/${data.enh_codpes}`}
                          target="_blank"
                          rel="noreferrer"
                          className="link-light link-offset-2 link-underline-opacity-0 fw-normal"
                          style={{
                            fontSize: props.tamSubTexto
                          }}
                        >
                          <i className="fa-solid fa-print"></i>
                          FNRH
                        </a>
                      </button>
                      <button className="btn btn-secondary btn-sm">
                        <a
                          // href={`http://localhost:5173/${props.dataEmpresa.sigorg}/imagem/${data.res_numres}/${data.enh_nomctt}`}
                          // href={`https://checkin.salthomol.com.br/${props.dataEmpresa.sigorg}/imagem/${data.res_numres}/${data.enh_nomctt}`}
                          href={`https://checkin.sie.app.br/${props.dataEmpresa.sigorg}/imagem/${data.res_numres}/${data.enh_nomctt}`}
                          target="_blank"
                          rel="noreferrer"
                          className="link-light link-offset-2 link-underline-opacity-0 fw-normal"
                          style={{
                            fontSize: props.tamSubTexto
                          }}
                        >
                          <i className="fa-solid fa-camera"></i>
                          Fotos
                        </a>
                      </button>
                      <button className="btn btn-secondary btn-sm">
                        <a
                          // href={`http://localhost:5173/${props.dataEmpresa.sigorg}/anexar/${data.res_numres}/${data.enh_nomctt}/${data.enh_codpes}`}
                          // href={`https://checkin.salthomol.com.br/${props.dataEmpresa.sigorg}/anexar/${data.res_numres}/${data.enh_nomctt}/${data.enh_codpes}`}
                          href={`https://checkin.sie.app.br/${props.dataEmpresa.sigorg}/anexar/${data.res_numres}/${data.enh_nomctt}/${data.enh_codpes}`}
                          target="_blank"
                          rel="noreferrer"
                          className="link-light link-offset-2 link-underline-opacity-0 fw-normal"
                          style={{
                            fontSize: props.tamSubTexto
                          }}
                        >
                          <i className="fa-solid fa-paperclip"></i>
                          Anexar
                        </a>
                      </button>
                    </>
                  )}
                </div>
              </td>
              <td>
                <div style={{ display: 'flex', gap: '5px' }}>
                  {data.enh_checkin === 'SIM' ? (
                    <>
                      <button
                        className="btn btn-secondary btn-sm fw-normal"
                        style={{
                          fontSize: props.tamSubTexto
                        }}
                        onClick={() =>
                          props.handleChavent(
                            data.res_numres,
                            data.enh_seqenc,
                            data.enh_nomctt,
                            data.res_codevn,
                            data.enh_status,
                            data.enh_chaveent
                          )
                        }
                      >
                        <i className="fa-solid fa-key"></i>
                        Entregar Chave
                      </button>
                      <button
                        className="btn btn-secondary btn-sm fw-normal"
                        style={{
                          fontSize: props.tamSubTexto
                        }}
                        onClick={() =>
                          props.handleEntrada(
                            data.entrada,
                            data.res_numres,
                            data.enh_seqenc,
                            data.enh_nomctt,
                            data.enh_status
                          )
                        }
                      >
                        <i className="fa-solid fa-check"></i>
                        Check-in
                      </button>
                      <button
                        className="btn btn-secondary btn-sm fw-normal"
                        style={{
                          fontSize: props.tamSubTexto
                        }}
                        onClick={() =>
                          props.handleSaida(
                            data.res_numres,
                            data.enh_seqenc,
                            data.enh_chaveent,
                            data.enh_status
                          )
                        }
                      >
                        <i className="fa-solid fa-key"></i>
                        Receber Chave
                      </button>
                    </>
                  ) : (
                    <button className="btn btn-secondary btn-sm">
                      <a
                        // href={`http://localhost:5173/${props.dataEmpresa.sigorg}/checkin/${data.res_numres}/${data.enh_seqenc}`}
                        // href={`https://checkin.salthomol.com.br/${props.dataEmpresa.sigorg}/checkin/${data.res_numres}/${data.enh_seqenc}`}
                        href={`https://checkin.sie.app.br/${props.dataEmpresa.sigorg}/checkin/${data.res_numres}/${data.enh_seqenc}`}
                        target="_blank"
                        rel="noreferrer"
                        className="link-light link-offset-2 link-underline-opacity-0 fw-normal"
                        style={{
                          fontSize: props.tamSubTexto
                        }}
                      >
                        <i className="fa-solid fa-user-check"></i>
                        CkOnline
                      </a>
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="8" className="align-middle">
              <nav className="d-flex align-items-center justify-content-center">
                <ul className="h-100 pagination pagination-sm">
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousPage"
                      onClick={() => handleFirstPage()}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousIten"
                      onClick={() => handlePageClick(currentPage - 1)}
                    >
                      <span aria-hidden="true">&lt;</span>
                    </button>
                  </li>
                  {displayedPageNumbers.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        pageNumber === currentPage ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(pageNumber)}
                      >
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextIten"
                      onClick={() => handlePageClick(currentPage + 1)}
                    >
                      <span aria-hidden="true">&gt;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextPage"
                      onClick={() => handleLastPage()}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
          <tr>
            <td colSpan="8" className="align-middle">
              <div className="d-flex gap-2">
                <div className="d-flex flex-column">
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Qtd. de Reserva: {reservasUnicas.size}
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Qtd. de Hóspede: {props.formData.length}
                  </p>
                </div>

                <div className="d-flex flex-column">
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Apto Ocupado:{' '}
                    {
                      props.formData.filter(
                        (item) =>
                          item.enh_status === 'PRESENTE' ||
                          item.enh_status === 'CONCLUIDO'
                      ).length
                    }
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    CkOnline SIM:{' '}
                    {
                      props.formData.filter(
                        (item) => item.enh_checkin === 'SIM'
                      ).length
                    }
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    CkOnline NÃO:{' '}
                    {
                      props.formData.filter(
                        (item) => item.enh_checkin !== 'SIM'
                      ).length
                    }
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}
