import { ApiGetConsulta } from '../../../services/apiService';
import { Grid } from './Grid';

export const CtrNavegacao = (props) => {
  const handleEdit = async (numped, emp, loja) => {
    try {
      const response = await ApiGetConsulta(
        `/pedcompra/item/${numped}/${emp}/${loja}`,
        localStorage.getItem('sessionToken')
      );
      props.setActiveTab(3);
      props.setDataItems(response.data.pedidoCompra[0]);
      props.setFormData(response.data.pedidoCompra[1][0][0]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    alert('Em atualização');
  };

  return (
    <Grid
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      data={props.data}
      setData={props.setData}
      tamTexto={props.tamTexto}
      tamSubTexto={props.tamSubTexto}
      setInsertOrUpdate={props.setInsertOrUpdate}
      setViewOrUpdate={props.setViewOrUpdate}
      dataEmpresa={props.dataEmpresa}
    />
  );
};
