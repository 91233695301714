export function SearchCadastro(props) {
  return (
    <>
      <div>
        <span className="col-form-label">Nome Hóspede</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltroCadastro?.nome || ''}
            onChange={(e) =>
              props.setDataFiltroCadastro({
                ...props.dataFiltroCadastro,
                nome: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Nº da Reserva</span>
        <div>
          <input
            type="number"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltroCadastro?.numres || ''}
            onChange={(e) =>
              props.setDataFiltroCadastro({
                ...props.dataFiltroCadastro,
                numres: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Nº Apto</span>
        <div>
          <input
            type="number"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltroCadastro?.numapa || ''}
            onChange={(e) =>
              props.setDataFiltroCadastro({
                ...props.dataFiltroCadastro,
                numapa: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">CkOnline</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltroCadastro?.checkin || ''}
            onChange={(e) =>
              props.setDataFiltroCadastro({
                ...props.dataFiltroCadastro,
                checkin: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="SIM">SIM</option>
            <option value="NAO">NAO</option>
          </select>
        </div>
      </div>

      <div>
        <span className="col-form-label">Chave Ent</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltroCadastro?.chaveent || ''}
            onChange={(e) =>
              props.setDataFiltroCadastro({
                ...props.dataFiltroCadastro,
                chaveent: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="SIM">SIM</option>
            <option value="NAO">NAO</option>
          </select>
        </div>
      </div>

      <div>
        <span className="col-form-label">Sit. Reserva</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltroCadastro?.sitres || ''}
            onChange={(e) =>
              props.setDataFiltroCadastro({
                ...props.dataFiltroCadastro,
                sitres: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="N">N - NORMAL</option>
            <option value="C">C - CANCELADO</option>
          </select>
        </div>
      </div>

      <div className="mb-2">
        <span className="col-form-label">Apto Ocp/Dscp</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltroCadastro?.ocupado || ''}
            onChange={(e) =>
              props.setDataFiltroCadastro({
                ...props.dataFiltroCadastro,
                ocupado: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="SIM">O - Ocupado</option>
            <option value="NAO">D - Desocupado</option>
          </select>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={props.handleSearch}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
}
