import { format } from 'date-fns';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function RelContasPag(data, relContas, filtro) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const formattedTime = currentDate.toLocaleTimeString();

  const reportTitle = [
    {
      fontSize: 11,
      bold: true,
      margin: [15, 15, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Empresa: ' + relContas[0].empresa, alignment: 'left' },
        { text: 'Data: ' + formattedDate, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Loja: ' + relContas[0].loja, alignment: 'left' },
        { text: `Hora:      ` + formattedTime, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      text: [
        'CNPJ: ' + relContas[0].cnpj,
        'Endereço: ' + relContas[0].endereco,
        'Telefone: ' + relContas[0].telefone
      ].join('\n'),
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0]
    }
  ];

  const maxLineLength = 150;
  let currentLine = '';
  const reportContent = [];

  const filtroDetails = filtro.map((detalhe) => {
    const { campo, conteudo } = detalhe;
    if (campo.includes('Dt Mov')) {
      const [dataIni, dataEnd] = conteudo;
      return `${campo} = ${dataIni.trim()} a ${dataEnd.trim()}`;
    } else if (campo.includes('Dt Ven')) {
      const [dataIni, dataEnd] = conteudo;
      return `${campo} = ${dataIni.trim()} a ${dataEnd.trim()}`;
    } else if (campo.includes('Dt Pag')) {
      const [dataIni, dataEnd] = conteudo;
      return `${campo} = ${dataIni.trim()} a ${dataEnd.trim()}`;
    } else {
      return `${campo} = ${conteudo}`;
    }
  });

  filtroDetails.forEach((filtroItem, index) => {
    const filterPrefix = index === 0 ? 'Filtrado por: ' : '';
    const newLine = `${filterPrefix}${currentLine}${filtroItem}; `;
    if (newLine.length <= maxLineLength) {
      currentLine = newLine;
    } else {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
      currentLine = `${filtroItem}; `;
    }
  });

  if (currentLine !== '') {
    if (currentLine.includes('Filtrado por: ')) {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
    } else {
      reportContent.push({
        text: `.                     ${currentLine}`,
        fontSize: 8
      });
    }
  }

  let receitaRdps = 0;
  let despesaRdps = 0;

  let receitaRpag = 0;
  let despesaRpag = 0;

  const dados = data.map((opt) => {
    const dataFormatadaDamov = new Date(opt.cnt_damov).toLocaleDateString(
      'pt-BR'
    );
    const dataFormatadaDatven = new Date(opt.cnt_datven).toLocaleDateString(
      'pt-BR'
    );
    // const dataFormatadaDatpag = new Date(opt.cnt_datpag).toLocaleDateString(
    //   'pt-BR'
    // );

    const dataFormatadaDatpag =
      opt.cnt_datpag.slice(0, 4) === '1899'
        ? ''
        : format(new Date(opt.cnt_datpag), 'dd/MM/yyyy');

    const valpag =
      opt.cnt_datpag.slice(0, 4) === '1899'
        ? ''
        : opt.cnt_valpag
        ? opt.cnt_valpag.toLocaleString('pt-BR', {
            minimumFractionDigits: 2
          })
        : '';

    if (opt.cnt_tipcon === 'R') {
      receitaRdps = receitaRdps + opt.cnt_valdps;
      receitaRpag = receitaRpag + opt.cnt_valpag;
    }

    if (opt.cnt_tipcon === 'P') {
      despesaRdps = despesaRdps + opt.cnt_valdps;
      despesaRpag = despesaRpag + opt.cnt_valpag;
    }

    return [
      {
        text: opt.cnt_docpag,
        fontSize: 8,
        margin: [0, 2, 0, 2],
        noWrap: false
      },
      {
        text: opt.cnt_numcon,
        fontSize: 8,
        margin: [0, 2, 0, 2],
        noWrap: false
      },
      {
        text: opt.cnt_tipcon,
        fontSize: 8,
        margin: [0, 2, 0, 2],
        noWrap: false
      },
      {
        text: opt.cnt_codpes,
        fontSize: 8,
        margin: [0, 2, 0, 2],
        noWrap: false
      },
      {
        text: opt.cnt_nompes,
        fontSize: 8,
        margin: [0, 2, 0, 2],
        noWrap: false
      },
      {
        text: dataFormatadaDamov,
        fontSize: 8,
        margin: [0, 2, 0, 2],
        noWrap: false
      },
      {
        text: dataFormatadaDatven,
        fontSize: 8,
        margin: [0, 2, 0, 2],
        noWrap: false
      },
      {
        text: dataFormatadaDatpag,
        fontSize: 8,
        margin: [0, 2, 0, 2],
        noWrap: false
      },
      {
        text: opt.cnt_valdps.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }),
        fontSize: 8,
        margin: [0, 2, 0, 2],
        alignment: 'right',
        noWrap: false
      },
      {
        // text: opt.cnt_valpag
        //   ? opt.cnt_valpag.toLocaleString('pt-BR', {
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2
        //     })
        //   : '',
        text: valpag,
        fontSize: 8,
        margin: [0, 2, 0, 2],
        alignment: 'right',
        noWrap: false
      }
    ];
  });

  let saldoRdps = receitaRdps - despesaRdps;
  let saldoRpag = receitaRpag - despesaRpag;

  const qtdRegistros = dados.length;

  const styles = {
    tableHeader: {
      bold: true
    }
  };

  const details = [
    {
      table: {
        headerRows: 1,
        widths: [
          'auto',
          'auto',
          'auto',
          'auto',
          'auto', //*
          'auto',
          'auto',
          'auto',
          'auto',
          'auto'
        ],
        body: [
          [
            { text: 'Id', style: 'tableHeader', fontSize: 9 },
            { text: 'Num Con', style: 'tableHeader', fontSize: 9 },
            { text: 'Tp Con', style: 'tableHeader', fontSize: 9 },
            { text: 'C Cliente', style: 'tableHeader', fontSize: 9 },
            { text: 'Nome Cliente', style: 'tableHeader', fontSize: 9 },
            { text: 'Dt Mov', style: 'tableHeader', fontSize: 9 },
            { text: 'Dt Ven', style: 'tableHeader', fontSize: 9 },
            { text: 'Dt Pag', style: 'tableHeader', fontSize: 9 },
            { text: 'Val Dps', style: 'tableHeader', fontSize: 9 },
            { text: 'Val Pag', style: 'tableHeader', fontSize: 9 }
          ],
          ...dados
        ]
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
        }
      }
    },
    {
      table: {
        headerRows: 0,
        // widths: ['auto', 'auto'],
        widths: ['auto', 'auto', '*', 'auto', 'auto'],
        body: [
          [
            {
              text: 'Val Dps',
              alignment: 'center',
              bold: true,
              fontSize: 8,
              colSpan: 2
            },
            '',
            '',
            {
              text: 'Val Pag',
              alignment: 'center',
              bold: true,
              fontSize: 8,
              colSpan: 2
            },
            ''
          ],
          [
            {
              text: ''
            },
            {
              text: 'Realizada',
              alignment: 'right',
              bold: true,
              fontSize: 8
            },
            '', // Célula vazia para separar as duas tabelas
            {
              text: '',
              alignment: 'right',
              bold: true,
              fontSize: 8
            },
            {
              text: 'Realizada',
              alignment: 'right',
              bold: true,
              fontSize: 8
            }
          ],
          [
            { text: 'Receita', bold: true, fontSize: 8 },
            {
              text: receitaRdps.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            },
            '', // Célula vazia para separar as duas tabelas
            { text: 'Receita', bold: true, fontSize: 8 },
            {
              text: receitaRpag.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            }
          ],
          [
            { text: 'Despesa', bold: true, fontSize: 8 },
            {
              text: despesaRdps.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            },
            '', // Célula vazia para separar as duas tabelas
            { text: 'Despesa', bold: true, fontSize: 8 },
            {
              text: despesaRpag.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            }
          ],
          [
            { text: 'Saldo', bold: true, fontSize: 8 },
            {
              text: saldoRdps.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            },
            '', // Célula vazia para separar as duas tabelas
            { text: 'Saldo', bold: true, fontSize: 8 },
            {
              text: saldoRpag.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            }
          ]
          // [
          //   {
          //     text: 'Quantidade de Registros',
          //     bold: true,
          //     fontSize: 8
          //   },
          //   {
          //     text: qtdRegistros.toLocaleString('pt-BR'),
          //     alignment: 'right',
          //     fontSize: 8
          //   },
          //   '',
          //   '',
          //   ''
          // ]
        ]
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
        paddingTop: function () {
          return 3;
        },
        paddingBottom: function () {
          return 3;
        }
      }
    },
    {
      table: {
        headerRows: 0,
        widths: ['*', 'auto'],
        body: [
          [
            {
              text: 'Quantidade de Registros',
              alignment: 'right',
              bold: true,
              fontSize: 8
            },
            {
              text: qtdRegistros.toLocaleString('pt-BR'),
              alignment: 'right',
              fontSize: 8
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
        paddingTop: function () {
          return 10;
        },
        paddingBottom: function () {
          return 3;
        }
      }
    }
  ];

  function Rodape(currentPage, pageCount) {
    return [
      {
        text: currentPage + ' / ' + pageCount,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0]
      }
    ];
  }

  const docDefinitios = {
    pageSize: 'A4',
    // pageMargins: [15, 90, 15, 40],
    pageMargins: [2, 90, 2, 30],

    header: [reportTitle],
    content: [
      { text: 'Relação de Contas Pagas', style: 'header' },
      reportContent,
      details
    ],
    footer: Rodape,
    styles: styles
  };

  pdfMake.createPdf(docDefinitios).download();
}

export default RelContasPag;
