import { ApiDelete, ApiGetConsulta } from '../../../services/apiService';
import { Grid } from './Grid';

export const CtrNavegacao = (props) => {
  const handleEdit = async (numcon, tipo) => {
    try {
      const response = await ApiGetConsulta(
        `/contas/consulta/${numcon}`,
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      if (response.data.contas[0].cnt_tipger !== 'GRL' && tipo === 'editar') {
        alert(
          'Operação não permitida! Contas provenientes de algum documento.\nAltere diretamente o documento original e regere as contas'
        );
      } else {
        props.setFormData(response.data.contas[0]);
        props.setActiveTab(3);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await ApiDelete('/contas', { id });
      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      alert('Registro deletado com sucesso');
      props.handleSearch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      data={props.data}
      setData={props.setData}
      tamTexto={props.tamTexto}
      tamSubTexto={props.tamSubTexto}
      setInsertOrUpdate={props.setInsertOrUpdate}
      setViewOrUpdate={props.setViewOrUpdate}
      dataEmpresa={props.dataEmpresa}
      setTitle={props.setTitle}
    />
  );
};
