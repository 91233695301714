export function CadObs(props) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        {/* Observações */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Observações</span>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_observ"
              value={props.formData?.ent_observ || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        {/* Observações Governança */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Observações Governança</span>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_obsgov"
              value={props.formData?.ent_obsgov || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Cód. Convênio e Nome Convênio */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cód. Convênio</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Nome Convênio</span>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <select
              className="form-select form-select-sm border border-dark"
              name="cod_convenio"
              value={props.formData?.cod_convenio || ''}
              onChange={(e) => {
                const filter = props.options[20]?.filter(
                  (option) => option.codigo_convenio === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cod_convenio: e.target.value,
                  nome_convenio: filter[0].descricao
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[20]?.map((option, index) => (
                <option key={index} value={option.codigo_convenio}>
                  {option.codigo_convenio === props.formData?.cod_convenio
                    ? option.codigo_convenio
                    : option.descricao}
                </option>
              ))}
            </select>
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="nome_convenio"
              value={props.formData?.nome_convenio || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Origem do Contato e Descrição Contato */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Origem do Contato</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Descrição Contato</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <select
              className="form-select form-select-sm border border-dark"
              name="origem_do_contato"
              value={props.formData?.origem_do_contato || ''}
              onChange={(e) => {
                const filter = props.options[21]?.filter(
                  (option) => option.codigo === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  origem_do_contato: e.target.value,
                  descricao_contato: filter[0].descricao
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[21]?.map((option, index) => (
                <option key={index} value={option.codigo}>
                  {option.codigo === props.formData?.origem_do_contato
                    ? option.codigo
                    : option.descricao}
                </option>
              ))}
            </select>
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="descricao_contato"
              value={props.formData?.descricao_contato || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Tag CRM e Descrição Tag CRM */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Tag CRM</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Descrição Tag CRM</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <select
              className="form-select form-select-sm border border-dark"
              name="tag_crm"
              value={props.formData?.tag_crm || ''}
              onChange={(e) => {
                const filter = props.options[22]?.filter(
                  (option) => option.codigo_tipo_assunto === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  tag_crm: e.target.value,
                  descricao_tag_crm: filter[0].descricao
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[22]?.map((option, index) => (
                <option key={index} value={option.codigo_tipo_assunto}>
                  {option.codigo_tipo_assunto === props.formData?.tag_crm
                    ? option.codigo_tipo_assunto
                    : option.descricao}
                </option>
              ))}
            </select>
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="descricao_tag_crm"
              value={props.formData?.descricao_tag_crm || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Nº Excursão */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Nº Excursão</span>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_numdoc"
              value={props.formData?.ent_numdoc || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        {/* Escolha Hotel */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Escolha Hotel</span>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_escolha"
              value={props.formData?.ent_escolha || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
