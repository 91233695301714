import { useState, useEffect } from 'react';
import { useMemo } from 'react';

import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from '@tanstack/react-table';

function formatData(data, listColumns) {
  let columnExisted = '';
  let dataFormat = data.filter((item, index) => {
    if (index === 0 || columnExisted !== item[listColumns[0].id]) {
      columnExisted = item[listColumns[0].id];
      return item;
    }
  });

  dataFormat = dataFormat.map((item) => {
    const sum = data
      .filter((i) => i[listColumns[0].id] === item[listColumns[0].id])
      .reduce((total, i) => total + i[listColumns[2].id], 0);

    return {
      [listColumns[0].id]: item[listColumns[0].id],
      [listColumns[1].id]: '',
      [listColumns[2].id]: sum.toLocaleString('pt-BR', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL'
      }),
      subRows: []
    };
  });

  return dataFormat;
}

function formatChildrenData(dataInitial, data, listColumns) {
  let dataComplete = [...data];
  dataInitial.forEach((item) => {
    dataComplete.forEach((itemComplete) => {
      if (itemComplete[listColumns[0].id] === item[listColumns[0].id]) {
        itemComplete.subRows.push({
          [listColumns[1].id]: item[listColumns[1].id],
          [listColumns[2].id]: item[listColumns[2].id].toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL'
          })
        });
      }
    });
  });
  return dataComplete;
}

export const TabelaCustomizada = ({ dataInitial, listColumns, titulo }) => {
  const [expanded, setExpanded] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    const formattedData = formatData(dataInitial, listColumns);
    const completeData = formatChildrenData(
      dataInitial,
      formattedData,
      listColumns
    );
    setData(completeData);
  }, [dataInitial, listColumns]);

  const columns = useMemo(
    () => [
      {
        accessorKey: listColumns[0].id,
        header: () => listColumns[0].header,
        cell: ({ row, getValue }) => (
          <div style={{ paddingLeft: `${row.depth * 2}rem` }}>
            <div>
              {row.getCanExpand() && (
                <button
                  className="btn btn-sm"
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' }
                  }}
                >
                  {row.getIsExpanded() ? (
                    <i
                      className="fa-solid fa-caret-down"
                      style={{ transform: 'rotate(180deg)' }}
                    ></i>
                  ) : (
                    <i className="fa-solid fa-caret-down"></i>
                  )}
                </button>
              )}{' '}
              {getValue()}
            </div>
          </div>
        ),
        footer: (props) => props.column.id
      },
      {
        accessorFn: (row) => row.subgrupo,
        id: listColumns[1].id,
        cell: (info) => info.getValue(),
        header: () => listColumns[1].header,
        footer: (props) => props.column.id
      },
      {
        accessorKey: listColumns[2].id,
        header: () => listColumns[2].header,
        // eslint-disable-next-line no-unused-vars
        cell: ({ _, getValue }) => <div className="text-end">{getValue()}</div>,
        footer: (props) => props.column.id
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: { expanded },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  });

  return (
    data && (
      <div className="p-2" style={{ width: '95%', height: '72vh' }}>
        <div className="text-center">
          <h4 className="text">{titulo}</h4>
        </div>
        <div style={{ height: 'calc(100% - 60px)', overflowY: 'auto' }}>
          <table className="table table-striped table-hover">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr className="table-secondary" key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};
