import { Grid } from './Grid';

export const CtrNavegacao = (props) => {
  return (
    <Grid
      data={props.data}
      setData={props.setData}
      tamTexto={props.tamTexto}
      tamSubTexto={props.tamSubTexto}
    />
  );
};
