import React from 'react';

export const TabelaTipoDataValor = ({ data, title }) => {
  const headers = ['MESES', 'RECEITA', 'DESPESA', 'SALDO'];

  const totalReceita = data.reduce((total, item) => total + item.Receita, 0);
  const totalDespesa = data.reduce((total, item) => total + item.Despesa, 0);
  const totalSaldo = data.reduce((total, item) => total + item.Saldo, 0);

  return (
    <div style={{ width: '95%', height: '72vh' }}>
      <h4 style={{ backgroundColor: 'grey', color: 'white', padding: '10px' }}>
        {title}
      </h4>
      <div style={{ height: 'calc(100% - 60px)', overflowY: 'auto' }}>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center' }}>
                  {new Date(item.periodo).toLocaleDateString('pt-BR', {
                    month: 'long',
                    year: 'numeric'
                  })}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2
                  }).format(item.Receita)}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2
                  }).format(item.Despesa)}
                </td>
                <td
                  style={{
                    textAlign: 'center',
                    color: item.Saldo < 0 ? 'red' : 'blue'
                  }}
                >
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2
                  }).format(item.Saldo)}
                </td>
              </tr>
            ))}
            <tr>
              <td style={{ fontWeight: 'bold', textAlign: 'center' }}>TOTAL</td>
              <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'decimal',
                  minimumFractionDigits: 2
                }).format(totalReceita)}
              </td>
              <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'decimal',
                  minimumFractionDigits: 2
                }).format(totalDespesa)}
              </td>
              <td
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: totalSaldo < 0 ? 'red' : 'blue'
                }}
              >
                {new Intl.NumberFormat('pt-BR', {
                  style: 'decimal',
                  minimumFractionDigits: 2
                }).format(totalSaldo)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
