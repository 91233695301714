import { useEffect, useState } from 'react';

import {
  ApiDelete,
  ApiGet,
  ApiPut,
  NewApiPost
} from '../../../services/apiService';
import { Cadastro } from './Cadastro';
import { Grid } from './Grid';

export const Lancamento = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [dataGrid, setDataGrid] = useState([]);
  const [data, setData] = useState({});
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');

  const handleSearch = async () => {
    const body = {};

    try {
      const response = await ApiGet(
        '/tabela/contabil',
        body,
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      setDataGrid(response.data.dados);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = async (item) => {
    setData({
      empresa: item.empresa || '',
      centroCusto: item.centroCusto || '',
      fonteRecurso: item.fonteRecurso || '',
      naturezaDespesa: item.naturezaDespesa || '',
      codigoLancamento: item.codigoLancamento || '',
      contaDebito: item.contaDebito || '',
      contaCredito: item.contaCredito || '',
      usuario: item.usuario || '',
      dataAtualizacao: item.dataAtualizacao || '',
      dataInclusao: item.dataInclusao || '',
      horaAtualizacao: item.horaAtualizacao || '',
      id: item.id || '',
      sequencial: item.sequencial || ''
    });

    setActiveTab(2);
  };

  const handleDelete = async (id) => {
    try {
      const response = await ApiDelete('/tabela/contabil', { id });
      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      alert('Registro deletado com sucesso');
      handleSearch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleInsert = async () => {
    const body = {
      /* tla_codemp */ empresa: data.empresa,
      /* tla_codtcc */ centroCusto: data.centroCusto,
      /* tla_codfon */ fonteRecurso: data.fonteRecurso,
      /* tla_codnat */ naturezaDespesa: data.naturezaDespesa,
      /* tla_cod_lan_aut */ codigoLancamento: data.codigoLancamento,
      /* tla_conta_deb */ contaDebito: data.contaDebito,
      /* tla_conta_cre */ contaCredito: data.contaCredito,
      /* tla_usuario */ usuario: localStorage.getItem('sessionUsuario'),
      id: data.id || '',
      loja: data.loja || ''
    };

    try {
      const response = await NewApiPost(
        'tabela/contabil',
        body,
        localStorage.getItem('sessionToken')
      );

      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }
      alert('Cadastro realizado com sucesso');
      setData({});
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    const body = {
      /* tla_codemp */ empresa: data.empresa,
      /* tla_codtcc */ centroCusto: data.centroCusto,
      /* tla_codfon */ fonteRecurso: data.fonteRecurso,
      /* tla_codnat */ naturezaDespesa: data.naturezaDespesa,
      /* tla_cod_lan_aut */ codigoLancamento: data.codigoLancamento,
      /* tla_conta_deb */ contaDebito: data.contaDebito,
      /* tla_conta_cre */ contaCredito: data.contaCredito,
      /* tla_usuario */ usuario: localStorage.getItem('sessionUsuario'),
      id: data.id || '',
      loja: data.loja || ''
    };

    try {
      const response = await ApiPut(
        'tabela/contabil',
        body,
        localStorage.getItem('sessionToken')
      );

      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      alert('Atualização realizada com sucesso');

      setData({});
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    if (props.tabInsert === 1) {
      const data = new Date();
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const dia = String(data.getDate()).padStart(2, '0');

      const hora = data.getHours();
      const minuto = String(data.getMinutes()).padStart(2, '0');
      const segundo = String(data.getSeconds()).padStart(2, '0');

      setData({
        ...data,
        empresa: localStorage.getItem('sessionEmpresa'),
        loja: localStorage.getItem('sessionLoja'),
        usuario: localStorage.getItem('sessionUsuario'),
        dataAtualizacao: `${ano}-${mes}-${dia}`,
        horaAtualizacao: `${ano}-${mes}-${dia}`,
        dataInclusao: `${hora}:${minuto}:${segundo}ho`
      });
      setInsertOrUpdate('insert');
      props.setNvAcesso('insert');
      setActiveTab(2);
      props.setTabReturn(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabInsert]);

  useEffect(() => {
    if (props.tabReturn === 0) {
      setActiveTab(1);
      setData({});
    }
  }, [props.tabReturn]);

  useEffect(() => {
    if (props.tabUpdate === 1) {
      const fetch = async () => {
        if (insertOrUpdate === 'insert') await handleInsert(data);
        else await handleUpdate(data);

        setData({});

        await handleSearch();

        setActiveTab(1);
        props.setTabReturn(0);
        props.setTabUpdate(0);
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabUpdate]);

  return (
    <div>
      <div>
        <div className="nav nav-tabs">
          <p style={{ display: 'none' }}>{/* Tabela */}</p>
          <p style={{ display: 'none' }}>{/* Cadastro */}</p>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
            id="tab1"
          >
            <Grid
              handleEdit={handleEdit}
              dataGrid={dataGrid}
              handleDelete={handleDelete}
              setData={setData}
              tamTexto={props.tamTexto}
              tamSubTexto={props.tamSubTexto}
              setActiveTab={setActiveTab}
              data={data}
              setInsertOrUpdate={setInsertOrUpdate}
              handleSearch={handleSearch}
              setTabReturn={props.setTabReturn}
              setNvAcesso={props.setNvAcesso}
            />
          </div>
          <div
            className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
            id="tab2"
          >
            <Cadastro
              data={data}
              setData={setData}
              insertOrUpdate={insertOrUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
