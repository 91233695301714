import { useState } from 'react';
import InputMask from 'react-input-mask';

import { format, addDays } from 'date-fns';

export function Search(props) {
  const [anoDatnas, setAnoDatnas] = useState('');

  const months = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' }
  ];

  const handlePeriodClick = (period) => {
    const today = new Date();
    let start, end;
    switch (period) {
      case 'esta-semana':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, -1);
        }
        end = addDays(start, 6);
        break;
      case 'semana-passada':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, -1);
        }
        start = addDays(start, -7);
        end = addDays(start, 6);
        break;
      case 'proxima-semana':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, 1);
        }
        end = addDays(start, 6);
        break;
      case 'este-mes':
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case 'mes-passado':
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case 'proximo-mes':
        start = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        end = new Date(today.getFullYear(), today.getMonth() + 2, 0);
        break;
      case 'este-ano':
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date(today.getFullYear(), 11, 31);
        break;
      case 'ano-passado':
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        break;
      case 'proximo-ano':
        start = new Date(today.getFullYear() + 1, 0, 1);
        end = new Date(today.getFullYear() + 1, 11, 31);
        break;
      default:
        start = null;
        end = null;
    }

    props.setDataFiltro({
      ...props.dataFiltro,
      datnasInicial: start ? format(start, 'dd/MM/yyyy') : '',
      datnasFinal: end ? format(end, 'dd/MM/yyyy') : ''
    });
  };

  const calculateDates = (monthValue, yearValue) => {
    const month = parseInt(monthValue, 10);
    const year = parseInt(yearValue, 10);

    let lastDayOfMonth;
    if (month === 2) {
      lastDayOfMonth = new Date(year, 2, 0).getDate();
    } else {
      const monthsWith30Days = [4, 6, 9, 11];
      lastDayOfMonth = monthsWith30Days.includes(month) ? 30 : 31;
    }

    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month - 1, lastDayOfMonth);

    props.setDataFiltro({
      ...props.dataFiltro,
      datnasInicial: format(startDate, 'dd/MM/yyyy'),
      datnasFinal: format(endDate, 'dd/MM/yyyy')
    });
  };

  return (
    <>
      {/* Codpes */}
      <div>
        <span className="col-form-label">Código da Pessoa</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.codpes || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                codpes: e.target.value
              })
            }
          />
        </div>
      </div>

      {/* Nome */}
      <div>
        <span className="col-form-label">Nome</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.nome || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                nome: e.target.value
              })
            }
          />
        </div>
      </div>

      {/* CPF */}
      <div>
        <span className="col-form-label">CPF</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.cpf || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                cpf: e.target.value
              })
            }
          />
        </div>
      </div>

      {/* CGC */}
      <div>
        <span className="col-form-label">CNPJ</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.cgc || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                cgc: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Tipo Pessoa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.tipoPessoa || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                tipoPessoa: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.optionsTippes.map((option, index) => (
              <option key={index} value={option.tip_codtpes}>
                {option.tip_nomtpes}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <hr />
      </div>

      <div className="row d-flex">
        <div className="dropdown col-6">
          <button
            className="btn btn-info dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Períodos
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('esta-semana')}
              >
                Essa semana
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('semana-passada')}
              >
                Semana passada
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proxima-semana')}
              >
                Semana que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-mes')}
              >
                Esse mês
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('mes-passado')}
              >
                Mês passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-mes')}
              >
                Mês que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-ano')}
              >
                Esse ano
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('ano-passado')}
              >
                Ano passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-ano')}
              >
                Ano que vem
              </p>
            </li>
          </ul>
        </div>

        <div className="dropdown col-6">
          <button
            className="btn btn-info dropdown-toggle"
            type="button"
            id="dropdownMesesdatent"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Meses/Ano
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMesesdatent">
            <li className="dropdown-item">
              <InputMask
                mask="9999"
                maskChar=""
                type="text"
                className="form-control form-control-sm border border-dark"
                id="datnasIni1"
                placeholder="Ano"
                value={anoDatnas}
                onChange={(e) => setAnoDatnas(e.target.value)}
              />
            </li>
            {months.map((month) => (
              <li key={month.value}>
                <p
                  className="dropdown-item"
                  onClick={() => calculateDates(month.value, anoDatnas)}
                >
                  {month.label}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div>
        <span className="col-form-label">Data de Nascimento Inicial</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            inputMode="numeric"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datnasInicial || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datnasInicial: e.target.value
              })
            }
          />
        </div>
      </div>

      <div className="mb-3">
        <span className="col-form-label">Data de Nascimento Final</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            inputMode="numeric"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datnasFinal || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datnasFinal: e.target.value
              })
            }
          />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={props.handleSearch}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
}
