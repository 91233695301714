import { NumericFormat } from 'react-number-format';

export function CadConta(props) {
  const onChangeFormatNumeric = (values, id) => {
    const { formattedValue } = values;

    props.handleInput({
      target: { name: id, value: formattedValue }
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          {/* Código e Nome */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-3">
                <span className="col-form-label">Código</span>
              </div>
              <div className="col-9">
                <span className="col-form-label">Nome</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-3">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="codpes"
                  value={props.dataContas?.codpes || ''}
                  onChange={props.handleInput}
                />
              </div>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="nome"
                  value={props.dataContas?.nome || ''}
                  onChange={props.handleInput}
                />
              </div>
            </div>
          </div>

          {/* Forma e Tipo de Pagto. */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">Forma de Pagto.</span>
              </div>
              <div className="col-6">
                <span className="col-form-label">Tipo de Pagto.</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="forpag"
                  value={props.dataContas?.forpag || ''}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.options[5]?.map((option, index) => (
                    <option key={index} value={option.fpg_forpag}>
                      {option.fpg_desfpg}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="tippag"
                  value={props.dataContas?.tippag || ''}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.options[6]?.map((option, index) => (
                    <option key={index} value={option.tpg_tippag}>
                      {option.tpg_destpg}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Data de Movto. e N. Parcela */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">Data de Movto.</span>
              </div>
              <div className="col-6">
                <span className="col-form-label">N. Parcela</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <input
                  type="date"
                  className="form-control form-control-sm border border-dark"
                  name="datmov"
                  value={props.dataContas?.datmov || ''}
                  onChange={props.handleInput}
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control form-select-sm border border-dark"
                  name="parcela"
                  value={props.dataContas?.parcela || ''}
                  onChange={props.handleInput}
                />
              </div>
            </div>
          </div>

          {/* Data Base Vencto. e Valor da Parcela */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">Data Base Vencto.</span>
              </div>
              <div className="col-6">
                <span className="col-form-label">Valor da Parcela</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <input
                  type="date"
                  className="form-control form-control-sm border border-dark"
                  name="datbas"
                  value={props.dataContas?.datbas || ''}
                  onChange={props.handleInput}
                />
              </div>
              <div className="col-6">
                <NumericFormat
                  value={props.dataContas?.valpar || ''}
                  onValueChange={(values) => {
                    onChangeFormatNumeric(values, 'valpar');
                  }}
                  decimalScale={2}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix=""
                  className="form-control form-control-sm border border-dark"
                  name="valpar"
                  style={{ textAlign: 'right' }}
                  suffix={
                    props.value % 1 === 0
                      ? ',00'
                      : (props.value * 10) % 1 === 0
                      ? '0'
                      : ''
                  }
                />
              </div>
            </div>
          </div>

          {/* Valor por F. de Pagto */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">Valor por F. de Pagto</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <NumericFormat
                  value={props.dataContas?.valpag || ''}
                  onValueChange={(values) => {
                    onChangeFormatNumeric(values, 'valpag');
                  }}
                  decimalScale={2}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix=""
                  className="form-control form-control-sm border border-dark"
                  name="valpag"
                  style={{ textAlign: 'right' }}
                  suffix={
                    props.value % 1 === 0
                      ? ',00'
                      : (props.value * 10) % 1 === 0
                      ? '0'
                      : ''
                  }
                />
              </div>
            </div>
          </div>

          {/* Valor da Conta e Valor Total Parc */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">Valor da Conta</span>
              </div>
              <div className="col-6">
                <span className="col-form-label">Valor Total Parc</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <NumericFormat
                  value={props.dataContas?.valcon || ''}
                  onValueChange={(values) => {
                    onChangeFormatNumeric(values, 'valcon');
                  }}
                  decimalScale={2}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix=""
                  className="form-control form-control-sm border border-dark"
                  name="valcon"
                  style={{ textAlign: 'right' }}
                  suffix={
                    props.value % 1 === 0
                      ? ',00'
                      : (props.value * 10) % 1 === 0
                      ? '0'
                      : ''
                  }
                />
              </div>
              <div className="col-6">
                <NumericFormat
                  value={props.dataContas?.valtot || ''}
                  decimalScale={2}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix=""
                  disabled
                  className="form-control form-control-sm border border-dark"
                  name="valtot"
                  style={{ textAlign: 'right' }}
                  suffix={
                    props.value % 1 === 0
                      ? ',00'
                      : (props.value * 10) % 1 === 0
                      ? '0'
                      : ''
                  }
                />
              </div>
            </div>
          </div>

          {/* Data Prevista */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6"></div>
              <div className="col-6">
                <span className="col-form-label">Data Prevista</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6"></div>
              <div className="col-6">
                <input
                  type="date"
                  className="form-control form-control-sm border border-dark"
                  name="datpre"
                  value={props.dataContas?.datpre || ''}
                  onChange={props.handleInput}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          {/* Bandeira e Tipo de Cartão */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-8">
                <span className="col-form-label">Tipo de Cartão</span>
              </div>
              <div className="col-4">
                <span className="col-form-label">Cred/Dev</span>
              </div>
            </div>
            <div className="col-12 row">
              <div className="col-8">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="tipcar"
                  value={props.dataContas?.tipcar || ''}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.optionsTipcar?.map((option, index) => (
                    <option key={index} value={option.ccr_codpes}>
                      {option.ccr_nomcar}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-4">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="creddev"
                  value={props.dataContas?.creddev || ''}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.options[7]?.map((option, index) => (
                    <option key={index} value={option.codigo_pessoa}>
                      {option.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* NSU e Nr. Aprov. */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-4">
                <span className="col-form-label">NSU</span>
              </div>
              <div className="col-4">
                <span className="col-form-label">Nr. Aprov.</span>
              </div>
              <div className="col-4">
                <span className="col-form-label">Bandeira</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-4">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="nsu"
                  value={props.dataContas?.nsu || ''}
                  onChange={props.handleInput}
                />
              </div>
              <div className="col-4">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="aprov"
                  value={props.dataContas?.aprov || ''}
                  onChange={props.handleInput}
                />
              </div>
              <div className="col-4">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="bandeira"
                  value={props.dataContas?.bandeira || ''}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.options[8]?.map((option, index) => (
                    <option key={index} value={option.tbc_codbad}>
                      {option.tbc_descbad}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Banco e No. Doc. Pag. */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">Banco</span>
              </div>
              <div className="col-6">
                <span className="col-form-label">No. Doc. Pag.</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="banco"
                  value={props.dataContas?.banco || ''}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.options[9]?.map((option, index) => (
                    <option key={index} value={option.ban_codban}>
                      {option.ban_desban}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="docpag"
                  value={props.dataContas?.docpag || ''}
                  onChange={props.handleInput}
                />
              </div>
            </div>
          </div>

          {/* C. de Custo e Câmbio */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">C. de Custo</span>
              </div>
              <div className="col-6">
                <span className="col-form-label">Câmbio</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="cencus"
                  value={props.dataContas?.cencus || '1100'}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.options[10]?.map((option, index) => (
                    <option key={index} value={option.tcc_codtcc}>
                      {option.tcc_nomtcc}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="cambio"
                  value={props.dataContas?.cambio || ''}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.options[11]?.map((option, index) => (
                    <option key={index} value={option.dol_data}>
                      {option.dol_tipmoe} - {option.dol_valor}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* N. Despesa e Input Vazio */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">N. Despesa</span>
              </div>
              <div className="col-6"></div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="natdes"
                  value={props.dataContas?.natdes || ''}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.options[12]?.map((option, index) => (
                    <option key={index} value={option.tnd_codnat}>
                      {option.tnd_nomnat}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="inputvazio"
                  value={props.dataContas?.inputvazio || '0,00'}
                  onChange={props.handleInput}
                  disabled
                  style={{ textAlign: 'right' }}
                />
              </div>
            </div>
          </div>

          {/* Fonte Rec. */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">Fonte Rec.</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="fontrec"
                  value={props.dataContas?.fontrec || '160'}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.options[13]?.map((option, index) => (
                    <option key={index} value={option.tfo_codfon}>
                      {option.tfo_nomfon}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Projeto */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">Projeto</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="projeto"
                  value={props.dataContas?.projeto || '00003'}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.options[14]?.map((option, index) => (
                    <option key={index} value={option.tpj_codprj}>
                      {option.tpj_descri}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* C. Contato */}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">C. Contato</span>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="contrato"
                  value={props.dataContas?.contrato || ''}
                  onChange={props.handleInput}
                >
                  <option defaultValue></option>
                  {props.options[15]?.map((option, index) => (
                    <option key={index} value={option.tct_codctr}>
                      {option.tct_numctr}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
