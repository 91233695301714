import { menu_condicao } from '../services/menu-condicao';

export const handleCard = (area) => {
  const nivelAdm = String(localStorage.getItem('sessionNivelDeAdministracao'));

  if (area === 'HOTEL') {
    switchCardHotel(nivelAdm);
  } else {
    switchCardComer(nivelAdm);
  }

  return menu_condicao;
};

const updateSituacao = (keys, situacao) => {
  menu_condicao.forEach((item) => {
    const key = Object.keys(item)[0];
    if (keys.includes(key)) {
      item[key].situacao = situacao;
    }
  });
};

function switchCardHotel(nivelAdm) {
  switch (nivelAdm) {
    case '05':
      updateSituacao(
        [
          'hospedagem',
          'reserva',
          'confirmacaoReserva',
          'checkin',
          'eventos',
          'entrada',
          'saida',
          'parceiros',
          'portaria'
        ],
        'ATIVO'
      );
      break;

    case '08':
      updateSituacao(
        [
          'hospedagem',
          'reserva',
          'confirmacaoReserva',
          'checkin',
          'eventos',
          'entrada',
          'saida',
          'consumo',
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa',
          'parceiros',
          'portaria'
        ],
        'ATIVO'
      );
      break;

    case '10':
      updateSituacao(
        [
          'hospedagem',
          'reserva',
          'confirmacaoReserva',
          'checkin',
          'eventos',
          'entrada',
          'saida',
          'consumo',
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa',
          'parceiros',
          'portaria'
        ],
        'ATIVO'
      );
      break;

    case '12':
      updateSituacao(
        [
          'hospedagem',
          'reserva',
          'confirmacaoReserva',
          'checkin',
          'eventos',
          'entrada',
          'saida',
          'consumo',
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa',
          'parceiros',
          'portaria'
        ],
        'ATIVO'
      );
      break;

    case '14':
      updateSituacao(
        [
          'hospedagem',
          'reserva',
          'confirmacaoReserva',
          'checkin',
          'eventos',
          'entrada',
          'saida',
          'consumo',
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa',
          'parceiros',
          'portaria'
        ],
        'ATIVO'
      );
      break;

    case '16':
      updateSituacao(
        [
          'hospedagem',
          'reserva',
          'confirmacaoReserva',
          'checkin',
          'eventos',
          'entrada',
          'saida',
          'consumo',
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa',
          'parceiros',
          'portaria'
        ],
        'ATIVO'
      );
      break;

    case '20':
      updateSituacao(
        [
          'hospedagem',
          'reserva',
          'confirmacaoReserva',
          'checkin',
          'eventos',
          'entrada',
          'saida',
          'consumo',
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa',
          'parceiros',
          'portaria'
        ],
        'ATIVO'
      );
      break;

    case '50':
      updateSituacao(
        [
          'hospedagem',
          'reserva',
          'confirmacaoReserva',
          'checkin',
          'eventos',
          'entrada',
          'saida',
          'consumo',
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa',
          'parceiros',
          'portaria',
          'manutencao'
        ],
        'ATIVO'
      );
      break;

    case '99':
      updateSituacao(
        [
          'hospedagem',
          'reserva',
          'confirmacaoReserva',
          'checkin',
          'eventos',
          'entrada',
          'saida',
          'consumo',
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa',
          'parceiros',
          'portaria',
          'manutencao'
        ],
        'ATIVO'
      );
      break;
  }
}

function switchCardComer(nivelAdm) {
  switch (nivelAdm) {
    case '05':
      updateSituacao(
        [
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa'
        ],
        'ATIVO'
      );
      break;

    case '08':
      updateSituacao(
        [
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa'
        ],
        'ATIVO'
      );
      break;

    case '10':
      updateSituacao(
        [
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa'
        ],
        'ATIVO'
      );
      break;

    case '12':
      updateSituacao(
        [
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa'
        ],
        'ATIVO'
      );
      break;

    case '14':
      updateSituacao(
        [
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa'
        ],
        'ATIVO'
      );
      break;

    case '16':
      updateSituacao(
        [
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa'
        ],
        'ATIVO'
      );
      break;

    case '20':
      updateSituacao(
        [
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa'
        ],
        'ATIVO'
      );
      break;

    case '50':
      updateSituacao(
        [
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa',
          'manutencao'
        ],
        'ATIVO'
      );
      break;

    case '99':
      updateSituacao(
        [
          'contas',
          'contasPagas',
          'financeiro',
          'contabil',
          'pedidoVenda',
          'pedidoCompra',
          'compras',
          'estoque',
          'transferencia',
          'pessoa',
          'manutencao'
        ],
        'ATIVO'
      );
      break;
  }
}
