import { useEffect, useState } from 'react';

import { format } from 'date-fns';

const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: props.tamTexto }}>{props.title}</span>
        <div>
          <span onClick={() => props.handleSort(props.order, 'asc')}>
            <i className="fa-solid fa-arrow-up ms-2"></i>
          </span>
          <span
            className="ms-1"
            onClick={() => props.handleSort(props.order, 'desc')}
          >
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export const Grid = (props) => {
  const campos = [
    { title: 'Cód. Empresa', order: 'cnt_codemp' },
    { title: 'Nome da Empresa', order: 'emp_fantasia' },
    { title: 'Cód Loja', order: 'cnt_loja' },
    { title: 'Nome da Loja', order: 'loj_denloj' },
    { title: 'Cód. Nat Despesa', order: 'cnt_natdesp' },
    { title: 'Desc. Nat. despesa', order: 'tnd_nomnat' },
    { title: 'Cód. Ct. Contábil', order: 'cnt_codcont' },
    { title: 'Desc. Ct Contábil', order: 'tpc_descri' },
    { title: 'Cód. C. Custo (Final)', order: 'cnt_cencus' },
    { title: 'Desc. C. Custo (Final)', order: 'tcc_nomtcc' },
    { title: 'Descrição da Despesa', order: 'cnt_dscdps' },
    { title: 'Cód. Projeto', order: 'cnt_codprj' },
    { title: 'Descrição do Projeto', order: 'projeto' },
    { title: 'Código Fornecedor', order: 'cnt_codpes' },
    { title: 'Nome Fornecedor', order: 'cnt_nompes' },
    { title: 'Observação/Justificativa', order: 'cnt_observ' },
    { title: 'Data de Movimento', order: 'cnt_damov' },
    { title: 'Data de Vencimento', order: 'cnt_datven' },
    { title: 'Data de Pagamento', order: 'cnt_datpag' },
    { title: 'Valor da Despesa', order: 'cnt_valdps' }
  ];

  const receitaARealizar = props.data.reduce((acc, opt) => {
    return opt.cnt_tipcon === 'R' && opt.tabela === 'A realizar'
      ? acc + opt.cnt_valdps
      : acc;
  }, 0);

  const depesaARealizar = props.data.reduce((acc, opt) => {
    return opt.cnt_tipcon === 'P' && opt.tabela === 'A realizar'
      ? acc + opt.cnt_valdps
      : acc;
  }, 0);

  const saldoARealizar =
    props.data.reduce((acc, opt) => {
      return opt.cnt_tipcon === 'R' && opt.tabela === 'A realizar'
        ? acc + opt.cnt_valdps
        : acc;
    }, 0) -
    props.data.reduce((acc, opt) => {
      return opt.cnt_tipcon === 'P' && opt.tabela === 'A realizar'
        ? acc + opt.cnt_valdps
        : acc;
    }, 0);

  const receitaRealizada = props.data.reduce((acc, opt) => {
    return opt.cnt_tipcon === 'R' && opt.tabela === 'Realizada'
      ? acc + opt.cnt_valpag
      : acc;
  }, 0);

  const depesaRealizada = props.data.reduce((acc, opt) => {
    return opt.cnt_tipcon === 'P' && opt.tabela === 'Realizada'
      ? acc + opt.cnt_valpag
      : acc;
  }, 0);

  const saldoRealizada =
    props.data.reduce((acc, opt) => {
      return opt.cnt_tipcon === 'R' && opt.tabela === 'Realizada'
        ? acc + opt.cnt_valpag
        : acc;
    }, 0) -
    props.data.reduce((acc, opt) => {
      return opt.cnt_tipcon === 'P' && opt.tabela === 'Realizada'
        ? acc + opt.cnt_valpag
        : acc;
    }, 0);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
    setSelectedRow(null);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelectedRow(null);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
    setSelectedRow(null);
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'cnt_codemp':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_codemp?.localeCompare(b.cnt_codemp)
          )
        );
        break;
      case 'emp_fantasia':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.emp_fantasia?.localeCompare(b.emp_fantasia)
          )
        );
        break;
      case 'cnt_loja':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_loja?.localeCompare(b.cnt_loja)
          )
        );
        break;
      case 'loj_denloj':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.loj_denloj?.localeCompare(b.loj_denloj)
          )
        );
        break;
      case 'cnt_natdesp':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_natdesp?.localeCompare(b.cnt_natdesp)
          )
        );
        break;
      case 'tnd_nomnat':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.tnd_nomnat?.localeCompare(b.tnd_nomnat)
          )
        );
        break;
      case 'cnt_codcont':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_codcont?.localeCompare(b.cnt_codcont)
          )
        );
        break;
      case 'tpc_descri':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.tpc_descri?.localeCompare(b.tpc_descri)
          )
        );
        break;
      case 'cnt_cencus':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_cencus?.localeCompare(b.cnt_cencus)
          )
        );
        break;
      case 'tcc_nomtcc':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.centro_custo?.localeCompare(b.centro_custo)
          )
        );
        break;
      case 'cnt_dscdps':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_dscdps?.localeCompare(b.cnt_dscdps)
          )
        );
        break;
      case 'cnt_codprj':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_codprj?.localeCompare(b.cnt_codprj)
          )
        );
        break;
      case 'projeto':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.projeto?.localeCompare(b.projeto)
          )
        );
        break;
      case 'cnt_codpes':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_codpes?.localeCompare(b.cnt_codpes)
          )
        );
        break;
      case 'cnt_nompes':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_nompes?.localeCompare(b.cnt_nompes)
          )
        );
        break;
      case 'cnt_observ':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_observ?.localeCompare(b.cnt_observ)
          )
        );
        break;
      case 'cnt_damov':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_damov?.localeCompare(b.cnt_damov)
          )
        );
        break;
      case 'cnt_datven':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_datven?.localeCompare(b.cnt_datven)
          )
        );
        break;
      case 'cnt_datpag':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_datpag?.localeCompare(b.cnt_datpag)
          )
        );
        break;
      case 'cnt_valdps':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier * (parseFloat(a.cnt_valdps) - parseFloat(b.cnt_valdps))
          )
        );
        break;
      default:
        props.setData([...props.data]);
        break;
    }
  };

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.data.length / itemsPerPage));
    setCurrentPage(0);
    setSelectedRow(null);
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <table
        className="table table-striped table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            {campos.map((campo) => (
              <BlcTitle
                key={campo.order}
                title={campo.title}
                order={campo.order}
                handleSort={handleSort}
                tamTexto={props.tamTexto}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr
              key={index}
              className={selectedRow === index ? 'bg-info' : ''}
              onClick={() => handleRowClick(index)}
            >
              <th scope="row" style={{ fontSize: props.tamSubTexto }}>
                {item.cnt_codemp}
              </th>
              <td style={{ fontSize: props.tamSubTexto }}>
                {item.emp_fantasia}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_loja}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.loj_denloj}</td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {item.cnt_natdesp}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.tnd_nomnat}</td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {item.cnt_codcont}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.tpc_descri}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_cencus}</td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {item.centro_custo}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_dscdps}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_codprj}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.projeto}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_codpes}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_nompes}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_observ}</td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {format(new Date(item.cnt_damov), 'dd/MM/yyyy')}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {format(new Date(item.cnt_datven), 'dd/MM/yyyy')}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {item.cnt_datpag.slice(0, 10) !== '1899-12-30'
                  ? format(new Date(item.cnt_datpag), 'dd/MM/yyyy')
                  : ''}
              </td>
              <td className="text-end" style={{ fontSize: props.tamSubTexto }}>
                {item.cnt_valdps.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="20" className="align-middle">
              <nav className="d-flex align-items-center justify-content-center">
                <ul className="h-100 pagination pagination-sm">
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousPage"
                      onClick={() => handleFirstPage()}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousIten"
                      onClick={() => handlePageClick(currentPage - 1)}
                    >
                      <span aria-hidden="true">&lt;</span>
                    </button>
                  </li>
                  {displayedPageNumbers.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        pageNumber === currentPage ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(pageNumber)}
                      >
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextIten"
                      onClick={() => handlePageClick(currentPage + 1)}
                    >
                      <span aria-hidden="true">&gt;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextPage"
                      onClick={() => handleLastPage()}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
                <div className="d-flex flex-column">
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Número de Registros:{' '}
                    {props.data.length.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Número de Registros Despesa A Realizar:{' '}
                    {props.data
                      .filter(
                        (opt) =>
                          opt.cnt_tipcon === 'P' && opt.tabela === 'A realizar'
                      )
                      .length.toLocaleString('pt-BR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Número de Registros Receita A Realizar:{' '}
                    {props.data
                      .filter(
                        (opt) =>
                          opt.cnt_tipcon === 'R' && opt.tabela === 'A realizar'
                      )
                      .length.toLocaleString('pt-BR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Número de Registros Despesa Realizada:{' '}
                    {props.data
                      .filter(
                        (opt) =>
                          opt.cnt_tipcon === 'P' && opt.tabela === 'Realizada'
                      )
                      .length.toLocaleString('pt-BR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Número de Registros Receita Realizada:{' '}
                    {props.data
                      .filter(
                        (opt) =>
                          opt.cnt_tipcon === 'R' && opt.tabela === 'Realizada'
                      )
                      .length.toLocaleString('pt-BR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}
                  </p>
                </div>
              </nav>
            </td>
          </tr>
        </tfoot>
      </table>

      <div className="d-flex justify-content-between">
        <div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th></th>
                <th
                  colSpan={3}
                  className="text-center"
                  style={{ fontSize: props.tamTexto }}
                >
                  Financeiro
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th></th>
                <th
                  className="text-center"
                  style={{ fontSize: props.tamTexto }}
                >
                  A Realizar
                </th>
                <th
                  className="text-center"
                  style={{ fontSize: props.tamTexto }}
                >
                  Realizado
                </th>
                <th
                  className="text-center"
                  style={{ fontSize: props.tamTexto }}
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className="text-center"
                  style={{ fontSize: props.tamTexto }}
                >
                  Receita
                </td>
                <td className="text-end" style={{ fontSize: props.tamTexto }}>
                  {receitaARealizar.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td className="text-end" style={{ fontSize: props.tamTexto }}>
                  {receitaRealizada.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td className="text-end" style={{ fontSize: props.tamTexto }}>
                  {(receitaARealizar + receitaRealizada).toLocaleString(
                    'pt-BR',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="text-center"
                  style={{ fontSize: props.tamTexto }}
                >
                  Despesa
                </td>
                <td className="text-end" style={{ fontSize: props.tamTexto }}>
                  {depesaARealizar.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td className="text-end" style={{ fontSize: props.tamTexto }}>
                  {depesaRealizada.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td className="text-end" style={{ fontSize: props.tamTexto }}>
                  {(depesaARealizar + depesaRealizada).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
              </tr>
              <tr>
                <td
                  className="text-center"
                  style={{ fontSize: props.tamTexto }}
                >
                  Saldo
                </td>
                <td className="text-end" style={{ fontSize: props.tamTexto }}>
                  {saldoARealizar.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td className="text-end" style={{ fontSize: props.tamTexto }}>
                  {saldoRealizada.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td className="text-end" style={{ fontSize: props.tamTexto }}>
                  {(saldoARealizar + saldoRealizada).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
