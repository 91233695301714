import { useEffect, useState } from 'react';

import { getStartAndEndDates } from '../../../../functions/getStartAndEndDates';
import {
  ChartPieQt,
  TabelaCustomizada
  // TabelaDoisGruposValorSemData
} from '../../graficos';
import { FiltroDropdown } from '../FiltroDropdown';
import { FiltrosDate } from '../FiltrosDate';
import {
  fetchDataDropdown,
  fetchDataGraficoPizza,
  fetchDataTabela2Grupos
} from './functions';
import { ApiGet } from '../../../../services/apiService';
import { formatRequestArray } from '../../../../functions/formatRequestArray';
import { set } from 'date-fns';
export const AnaliseContas = () => {
  const { startDate: sdate, endDate: edate } = getStartAndEndDates(3);
  const [startDate, setStartDate] = useState(sdate);
  const [endDate, setEndDate] = useState(edate);
  const [dataDropdown, setDataDropdown] = useState({
    centroCusto: [],
    fonteRecurso: [],
    natureza: []
  });

  const [centroCusto, setCentroCusto] = useState('');
  const [natureza, setNatureza] = useState('');
  const [tipoPeriodo, setTipoPeriodo] = useState('pagamento');
  const [fonteRecurso, setFonteRecurso] = useState('');
  const [realizado, setRealizado] = useState('');
  const [realizadoDropdown, setRealizadoDropdown] = useState([]);

  const handleSetTipoPeriodo = (selectedOption) => {
    setTipoPeriodo(selectedOption.value);
  };

  //data para graficos
  const [dataPizzaR, setPizzaR] = useState([]);
  const [dataPizzaP, setPizzaP] = useState([]);
  const [dataTableR, setDataTableR] = useState([]);
  const [dataTableD, setDataTableD] = useState([]);

  useEffect(() => {
    const where = {
      centro_custo: { in: formatRequestArray(centroCusto) },
      tfo_nomfon: { in: formatRequestArray(fonteRecurso) },
      tnd_nomnat: { in: formatRequestArray(natureza) },
      tabela: { in: formatRequestArray(realizado) }
    };
    switch (tipoPeriodo) {
      case 'vencimento':
        where.cnt_datven = [startDate, endDate];
        break;
      case 'pagamento':
        where.cnt_datpag = [startDate, endDate];
        break;
      case 'movimento(competência)':
        where.cnt_damov = [startDate, endDate];
        break;
      default:
        break;
    }
    ApiGet(
      `/view`,
      {
        view: 'vi_contas',
        select: [
          'centro_custo as grupo',
          'tnd_nomnat as subgrupo',
          'sum(cnt_valdps) as sum'
        ],
        groupBy: 'tnd_nomnat, centro_custo',
        order: { column: '1, 2', isAscending: true },
        where: { ...where, ...{ tipo_conta: 'Receita' } }
      },
      localStorage.getItem('sessionToken')
    )
      .then((response) => {
        const dados = response.data.dados.map((item) => {
          return item;
        });
        setDataTableR(dados);
      })
      .catch((error) => {
        console.log('Erro durante request da tabela:', error);
      });
    ApiGet(
      `/view`,
      {
        view: 'vi_contas',
        select: [
          'centro_custo as grupo',
          'tnd_nomnat as subgrupo',
          'sum(cnt_valdps) as sum'
        ],
        groupBy: 'tnd_nomnat, centro_custo',
        order: { column: '1, 2', isAscending: true },
        where: { ...where, ...{ tipo_conta: 'Despesa' } }
      },
      localStorage.getItem('sessionToken')
    )
      .then((response) => {
        const dados = response.data.dados.map((item) => {
          return item;
        });
        setDataTableD(dados);
      })
      .catch((error) => {
        console.log('Erro durante request da tabela:', error);
      });
    ApiGet(
      `/view`,
      {
        view: 'vi_contas',
        select: ['tfo_nomfon as nome', 'sum(valor_conta) as sum'],
        groupBy: 'tfo_nomfon',
        order: { column: '1 ASC, 2', isAscending: false },
        where: { ...where, ...{ cnt_tipcon: 'R' } }
      },
      localStorage.getItem('sessionToken')
    )
      .then((response) => {
        const dados = response.data.dados.map((item) => {
          return item;
        });
        setPizzaR(dados);
      })
      .catch((error) => {
        setPizzaR([]);
        console.log('Erro durante request da grafico:', error);
      });

    ApiGet(
      `/view`,
      {
        view: 'vi_contas',
        select: ['tnd_nomnat as nome', 'sum(valor_conta) as sum'],
        groupBy: 'tnd_nomnat',
        order: { column: '1 ASC, 2', isAscending: false },
        where: { ...where, ...{ cnt_tipcon: 'P' } }
      },
      localStorage.getItem('sessionToken')
    )
      .then((response) => {
        const dados = response.data.dados.map((item) => {
          return item;
        });
        setPizzaP(dados);
      })
      .catch((error) => {
        setPizzaP([]);
        console.log('Erro durante request da grafico:', error);
      });
  }, [
    centroCusto,
    fonteRecurso,
    natureza,
    startDate,
    endDate,
    tipoPeriodo,
    realizado
  ]);

  useEffect(() => {
    const fetch = async () => {
      const response5 = await fetchDataDropdown();
      setDataDropdown(response5.data.grafico);
    };
    fetch();
    ApiGet(
      `/view`,
      {
        view: 'vi_contas',
        select: ['tabela as nome'],
        groupBy: 'tabela',
        order: { column: 'tabela', isAscending: true }
      },
      localStorage.getItem('sessionToken')
    )
      .then((response) => {
        const dados = response.data.dados.map((item) => {
          return { item: item.nome, codigo: item.codigo };
        });
        console.log(dados);
        setRealizadoDropdown(dados);
      })
      .catch((error) => {
        console.log('Erro durante request dropdown de Realizado:', error);
      });
  }, []);

  return (
    <div className="w-100" style={{ backgroundColor: '#eeeeee' }}>
      <h4>Dashboard Análise Contas Gerais</h4>
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 mb-2 p-1">
        <FiltrosDate
          title1={
            tipoPeriodo === 'vencimento'
              ? 'Data Venc Inicial'
              : tipoPeriodo === 'pagamento'
              ? 'Data Pag Inicial'
              : 'Data Mov Incial'
          }
          title2={
            tipoPeriodo === 'vencimento'
              ? 'Data Venc Final'
              : tipoPeriodo === 'pagamento'
              ? 'Data Pag Final'
              : 'Data Mov Final'
          }
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <FiltroDropdown
          title="Centro de Custo"
          listaItems={dataDropdown.centroCusto || []}
          value={centroCusto}
          setValue={setCentroCusto}
        />
        <FiltroDropdown
          title="Fonte de Recurso"
          listaItems={dataDropdown.fonteRecurso}
          value={fonteRecurso}
          setValue={setFonteRecurso}
        />
        <FiltroDropdown
          title="Natureza de Despesa"
          listaItems={dataDropdown.natureza}
          value={natureza}
          setValue={setNatureza}
        />
        <FiltroDropdown
          title="Realizado"
          listaItems={realizadoDropdown}
          value={realizado}
          setValue={setRealizado}
        />
        <FiltroDropdown
          title="Tipo Periodo"
          listaItems={[
            { item: 'pagamento' },
            { item: 'vencimento' },
            { item: 'movimento(competência)' }
          ]}
          value={tipoPeriodo}
          setValue={handleSetTipoPeriodo}
          isDate={true}
        />
      </div>
      <div className="w-100 h-100">
        <div className="d-flex flex-column flex-lg-row justify-content-center w-100 h-100">
          <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100 gap-3">
            {dataTableD.length > 0 && (
              <TabelaCustomizada
                listColumns={[
                  { id: 'grupo', header: 'Centro Custo' },
                  { id: 'subgrupo', header: 'Natureza Despesa' },
                  { id: 'sum', header: 'Valor' }
                ]}
                dataInitial={dataTableD}
                titulo={'Natureza de Despesa'}
              />
            )}
            {dataTableR.length > 0 && (
              <TabelaCustomizada
                listColumns={[
                  { id: 'grupo', header: 'Centro Custo' },
                  { id: 'subgrupo', header: 'Fonte de Recurso' },
                  { id: 'sum', header: 'Valor' }
                ]}
                dataInitial={dataTableR}
                titulo={'Fonte de Recurso'}
              />
            )}
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <ChartPieQt
              data={dataPizzaP}
              titulo={'Gráfico Natureza de Despesa'}
              isMonetary={true}
            />
            <ChartPieQt
              data={dataPizzaR}
              titulo={'Gráfico Fonte de Recurso'}
              isMonetary={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
