import { useEffect, useState } from 'react';

import { ApiGet } from '../../services/apiService';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';

import { format } from 'date-fns';

export function Hospegadem(props) {
  const addMonths = (date, months) => {
    const d = new Date(date);
    d.setMonth(d.getMonth() + months);
    return d;
  };
  const initialDate = new Date();
  const finalDate = addMonths(initialDate, 3);

  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [dataFiltro, setDataFiltro] = useState({
    datinclInicial: format(initialDate, 'dd/MM/yyyy'),
    datinclFinal: format(finalDate, 'dd/MM/yyyy')
  });

  const handleSearch = async () => {
    const isDataInicial = dataFiltro.datinclInicial !== '';
    const isDataFinal = dataFiltro.datinclFinal !== '';

    if (!isDataInicial || !isDataFinal) {
      alert('Preencher as duas datas');
      return;
    }

    const body = {
      tipoTabela: dataFiltro.tipo,
      dataIni: dataFiltro.datinclInicial,
      dataFim: dataFiltro.datinclFinal
    };

    try {
      const response = await ApiGet(
        '/v1/hospedagem',
        body,
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      setData(response.data.hospedagem);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-Hospedagem') {
      handleSearch();
    }
  }, [activeTab, props.isOpen]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Hospedagem"
      tabIndex="-1"
      aria-labelledby="lblHospedagem"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblHospedagem"
              style={{ fontSize: props.tamTitulo }}
            >
              Hospedagem/Vendas
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-Hospedagem' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasPedVenda"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Navegação</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    dataFiltro={dataFiltro}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {activeTab === 1 && (
              <button
                className="btn btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasPedVenda"
                aria-controls="offcanvasExample"
                style={{ fontSize: props.tamTexto }}
              >
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
