import { useState, useEffect } from 'react';

import RelPedVenda from '../../../relatorio/PedVenda';
import RelPedVendaRel from '../../../relatorio/PedVendaRel';
import RelPedVendaRelItem from '../../../relatorio/PedVendaRelItem';
import RelPedVendaRelPolo from '../../../relatorio/PedVendaRelPolo';
import { ApiGet } from '../../../services/apiService';

export const Relatorio = (props) => {
  const [relPedvenda, setRelPedvenda] = useState([]);

  const [isLoadingBas, setIsLoadingBas] = useState(false);
  const [isLoadingIPVenda, setIsLoadingIPVenda] = useState(false);
  const [isLoadingIPPolo, setIsLoadingIPPolo] = useState(false);
  const [isLoadingItem, setIsLoadingItem] = useState(false);

  const handleClickBas = () => {
    setIsLoadingBas(true);

    setTimeout(() => {
      RelPedVenda(props.data, relPedvenda, props.filtroPesquisa);
      setIsLoadingBas(false);
    }, 1000);
  };

  const handleClickItem = async () => {
    setIsLoadingItem(true);

    const tamNumped = props.numped ? props.numped.padStart(6, '0') : '';
    const tamCodpes = props.codpes ? props.codpes.padStart(5, '0') : '';
    const formartDamov =
      props.datmov[0] === '' || props.datmov[1] === '' ? [''] : props.datmov;

    const body = {
      empresa: props.empresa,
      loja: props.loja,
      undadm: props.undadm,
      pedcli: '',
      numped: tamNumped,
      sitped: props.sitped,
      tipped: props.tipped,
      codpes: tamCodpes,
      nomcli: props.nomcli,
      locped: props.locped,
      datmov: formartDamov,
      valor: props.valor,
      order: 'itm_item'
    };

    try {
      const response = await ApiGet(
        '/pedvenda/relatorio',
        { pedvenda: body },
        props.token
      );
      setTimeout(() => {
        RelPedVendaRelItem(
          response.data.pedidoVenda,
          relPedvenda,
          props.filtroPesquisa
        );
        setIsLoadingItem(false);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickItensPVenda = async () => {
    setIsLoadingIPVenda(true);

    const tamNumped = props.numped ? props.numped.padStart(6, '0') : '';
    const tamCodpes = props.codpes ? props.codpes.padStart(5, '0') : '';
    const formartDamov =
      props.datmov[0] === '' || props.datmov[1] === '' ? [''] : props.datmov;

    const body = {
      empresa: props.empresa,
      loja: props.loja,
      undadm: props.undadm,
      pedcli: '',
      numped: tamNumped,
      sitped: props.sitped,
      tipped: props.tipped,
      codpes: tamCodpes,
      nomcli: props.nomcli,
      locped: props.locped,
      datmov: formartDamov,
      valor: props.valor,
      order: 'ped_numped, itm_item'
    };

    try {
      const response = await ApiGet(
        '/pedvenda/relatorio',
        { pedvenda: body },
        props.token
      );
      setTimeout(() => {
        RelPedVendaRel(
          response.data.pedidoVenda,
          relPedvenda,
          props.filtroPesquisa
        );
        setIsLoadingIPVenda(false);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickItensPPolo = async () => {
    setIsLoadingIPPolo(true);

    const tamNumped = props.numped ? props.numped.padStart(6, '0') : '';
    const tamCodpes = props.codpes ? props.codpes.padStart(5, '0') : '';
    const formartDamov =
      props.datmov[0] === '' || props.datmov[1] === '' ? [''] : props.datmov;

    const body = {
      empresa: props.empresa,
      loja: props.loja,
      undadm: props.undadm,
      pedcli: '',
      numped: tamNumped,
      sitped: props.sitped,
      tipped: props.tipped,
      codpes: tamCodpes,
      nomcli: props.nomcli,
      locped: props.locped,
      datmov: formartDamov,
      valor: props.valor,
      order: 'itm_codfab, itm_item'
    };

    try {
      const response = await ApiGet(
        '/pedvenda/relatorio',
        { pedvenda: body },
        props.token
      );
      setTimeout(() => {
        RelPedVendaRelPolo(
          response.data.pedidoVenda,
          relPedvenda,
          props.filtroPesquisa
        );
        setIsLoadingIPPolo(false);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const item1 = localStorage.getItem('sessionFantasia');
    const item2 = localStorage.getItem('sessionLoja');
    const item3 = localStorage.getItem('sessionCnpj');
    const item4 = localStorage.getItem('sessionEndereco');
    const item5 = localStorage.getItem('sessionTelefone');

    const newData = {
      empresa: item1,
      loja: item2,
      cnpj: item3,
      endereco: item4,
      telefone: item5
    };

    setRelPedvenda([...relPedvenda, newData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-2">
      <button
        type="button"
        className="btn btn-primary mx-2"
        onClick={() => handleClickBas()}
        disabled={isLoadingBas}
        style={{ width: '150px', height: '60px' }}
      >
        {isLoadingBas ? (
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          'Relatório Básico'
        )}
      </button>
      <button
        type="button"
        className="btn btn-primary mx-2"
        onClick={() => handleClickItensPVenda()}
        disabled={isLoadingIPVenda}
        style={{ width: '180px', height: '60px' }}
      >
        {isLoadingIPVenda ? (
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          'Rel Itens p/ Ped Venda'
        )}
      </button>
      <button
        type="button"
        className="btn btn-primary mx-2"
        onClick={() => handleClickItensPPolo()}
        disabled={isLoadingIPPolo}
        style={{ width: '180px', height: '60px' }}
      >
        {isLoadingIPPolo ? (
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          'Rel Itens p/ Fabricante'
        )}
      </button>
      <button
        type="button"
        className="btn btn-primary mx-2"
        onClick={() => handleClickItem()}
        disabled={isLoadingItem}
        style={{ width: '180px', height: '60px' }}
      >
        {isLoadingItem ? (
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          'Rel Itens'
        )}
      </button>
    </div>
  );
};
