import ReactSelect from 'react-select';

export function CadTipoPessoa(props) {
  const optionsTipoPessoa = props.optionsTippes.map((option) => {
    return { value: option.tip_codtpes, label: option.tip_nomtpes };
  });

  const handleChange = (event) => {
    props.setFormData({
      ...props.formData,
      pessoaTipo: event.map((item) => item.value)
    });
  };

  return (
    <div className="col-12 row">
      {/* Tipo de Pessoa */}
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="col-12">
          <span className="col-form-label">Tipo de Pessoa</span>
        </div>
        <div className="col-12">
          <ReactSelect
            closeMenuOnSelect
            isMulti
            options={optionsTipoPessoa}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                padding: 0,
                margin: 0,
                overflowY: 'auto',
                maxHeight: '65px'
              })
            }}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
