export const Menu = (props) => {
  return (
    <div className="modal" id={props.id} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <ul className="list-group">
            {/* <div className="d-flex justify-content-center">
              <select
                className="form-select w-75 mt-1 mb-1"
                value={props.typeDashboard}
                onChange={(e) => props.setTypeDashboard(e.target.value)}
              >
                <option value="metabase">Metabase</option>
                <option value="react">React</option>
              </select>
            </div> */}
            {props.lista.map((item) => (
              <li key={item.id} className="list-group-item">
                <button
                  className="btn w-100"
                  data-bs-toggle="modal"
                  data-bs-target={props.modalId}
                  onClick={async () => {
                    props.setSelectedDashboard(
                      await props.handleDashboard(item.id)
                    );
                    props.setSelectedIdDashboardReact(item.id);
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    backgroundColor: '#e9ecef',
                    transition: 'background-color 0.3s'
                  }}
                  onMouseOver={(event) => {
                    event.target.style.backgroundColor = '#6c757d';
                  }}
                  onMouseOut={(event) => {
                    event.target.style.backgroundColor = '#e9ecef';
                  }}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
