import { useEffect, useState } from 'react';

import RelContasCompleto from '../../relatorio/RelContasCompleto';
import { ApiGet, NewApiGet } from '../../services/apiService';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';

export const Contabil = (props) => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [dataFiltro, setDataFiltro] = useState({});
  const [options, setOptions] = useState([]);
  const [relConta, setRelConta] = useState([]);
  const [filtroPesquisa, setFiltroPesquisa] = useState([]);

  const handleSearch = async () => {
    const tamNumcon = dataFiltro.numcon
      ? dataFiltro.numcon.padStart(7, '0')
      : '';
    const tamCodpes = dataFiltro.codpes
      ? dataFiltro.codpes.padStart(5, '0')
      : '';
    const tamCaxger = dataFiltro.caxger
      ? dataFiltro.caxger.padStart(5, '0')
      : '';
    const tamNumcax = dataFiltro.numcax
      ? dataFiltro.numcax.padStart(5, '0')
      : '';

    let datmov = null;
    let datven = null;
    let datpag = null;

    const conditionDatmovInicial =
      dataFiltro.datmovInicial !== '' &&
      dataFiltro.datmovInicial !== null &&
      dataFiltro.datmovInicial !== undefined;
    const conditionDatmovFinal =
      dataFiltro.datmovFinal !== '' &&
      dataFiltro.datmovFinal !== null &&
      dataFiltro.datmovFinal !== undefined;

    const conditionDatvenInicial =
      dataFiltro.datvenInicial !== '' &&
      dataFiltro.datvenInicial !== null &&
      dataFiltro.datvenInicial !== undefined;
    const conditionDatvenFinal =
      dataFiltro.datvenFinal !== '' &&
      dataFiltro.datvenFinal !== null &&
      dataFiltro.datvenFinal !== undefined;

    const conditionDatpagInicial =
      dataFiltro.datpagInicial !== '' &&
      dataFiltro.datpagInicial !== null &&
      dataFiltro.datpagInicial !== undefined;
    const conditionDatpagFinal =
      dataFiltro.datpagFinal !== '' &&
      dataFiltro.datpagFinal !== null &&
      dataFiltro.datpagFinal !== undefined;

    if (conditionDatmovInicial && conditionDatmovFinal) {
      datmov = [dataFiltro.datmovInicial, dataFiltro.datmovFinal];
    }

    if (conditionDatvenInicial && conditionDatvenFinal) {
      datven = [dataFiltro.datvenInicial, dataFiltro.datvenFinal];
    }

    if (conditionDatpagInicial && conditionDatpagFinal) {
      datpag = [dataFiltro.datpagInicial, dataFiltro.datpagFinal];
    }

    // const body = {
    //   codigoEmpresa: dataFiltro.empresa || '',
    //   loja: dataFiltro.loja || '',
    //   unidadeAdministrativa: dataFiltro.undadm || '',
    //   numeroContrato: tamNumcon || '',
    //   documentoPagamento: dataFiltro.docpag || '',
    //   tipoContrato: dataFiltro.tipcon || '',
    //   escriturado: dataFiltro.escrit || '',
    //   codigoPessoa: tamCodpes || '',
    //   nomePessoa: dataFiltro.nompes || '',
    //   centroCusto: dataFiltro.cencus || '',
    //   naturezaDespesa: dataFiltro.natdesp || '',
    //   recurso: dataFiltro.fontrec || '',
    //   dataMovimento: datmov || '',
    //   dataVencimento: datven || '',
    //   valorDespesa: dataFiltro.valdps || '',
    //   valorPago: dataFiltro.valpag || '',
    //   dataPagamento: null,
    //   caixaGerado: tamCaxger || '',
    //   caixaLiquidado: tamNumcax || '',
    //   tabela: 'contas'
    // };

    const conditions = [
      {
        condition: dataFiltro.empresa,
        campo: 'Empresa',
        conteudo: dataFiltro.empresa
      },
      { condition: dataFiltro.loja, campo: 'Loja', conteudo: dataFiltro.loja },
      {
        condition: dataFiltro.undadm,
        campo: 'Und Adm',
        conteudo: dataFiltro.undadm
      },
      {
        condition: tamNumcon,
        campo: 'Num Con',
        conteudo: tamNumcon
      },
      {
        condition: dataFiltro.docpag,
        campo: 'Doc Pag',
        conteudo: dataFiltro.docpag
      },
      {
        condition: dataFiltro.tipcon,
        campo: 'Tp Con',
        conteudo: dataFiltro.tipcon
      },
      {
        condition: dataFiltro.escrit,
        campo: 'Escrit',
        conteudo: dataFiltro.escrit
      },
      {
        condition: tamCodpes,
        campo: 'Cod Pes',
        conteudo: tamCodpes
      },
      {
        condition: dataFiltro.nompes,
        campo: 'Nom Pes',
        conteudo: dataFiltro.nompes
      },
      { condition: datmov, campo: 'Dt Mov', conteudo: datmov },
      { condition: datven, campo: 'Dt Ven', conteudo: datven },
      {
        condition: dataFiltro.valdps,
        campo: 'Val Dps',
        conteudo: dataFiltro.valdps
      },
      {
        condition: dataFiltro.valpag,
        campo: 'Val Pag',
        conteudo: dataFiltro.valpag
      }
    ];

    let pesquisasDetalhes = [];

    conditions.forEach(({ condition, campo, conteudo }) => {
      if (condition) pesquisasDetalhes.push({ campo, conteudo });
    });

    setFiltroPesquisa(pesquisasDetalhes);

    try {
      const response = await ApiGet(
        '/view',
        {
          view: 'vi_contas',
          select: [
            '*',
            '(SELECT emp_fantasia FROM empresa WHERE emp_codemp = cnt_codemp) as emp_fantasia',
            '(SELECT loj_denloj FROM loja WHERE loj_codloj = cnt_loja) as loj_denloj',
            '(SELECT tpj_descri FROM tab_projeto WHERE cnt_codprj = tpj_codprj) as projeto',
            '(SELECT tpc_descri FROM tab_plano_conta WHERE cnt_codcont = tpc_codpcn) as tpc_descri'
          ],
          where: {
            tabela: dataFiltro.tabela || '',
            cnt_codemp: dataFiltro.empresa || '',
            cnt_loja: dataFiltro.loja || '',
            cnt_undadm: dataFiltro.undadm || '',
            cnt_numcon: tamNumcon || '',
            cnt_docpag: dataFiltro.docpag || '',
            cnt_tipcon: dataFiltro.tipcon || '',
            cnt_escrit: dataFiltro.escrit || '',
            cnt_codpes: tamCodpes || '',
            cnt_nompes: dataFiltro.nompes || '',
            cnt_cencus: dataFiltro.cencus || '',
            cnt_natdesp: dataFiltro.natdesp || '',
            cnt_recurs: dataFiltro.fontrec || '',
            cnt_valdps: dataFiltro.valdps || '',
            cnt_valpag: dataFiltro.valpag || '',
            cnt_caxger: tamCaxger || '',
            cnt_numcax: tamNumcax || '',
            cnt_damov: datmov || '',
            cnt_datven: datven || '',
            cnt_datpag: datpag || ''
          },
          order: { column: 'cnt_codemp', isAscending: true }
        },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }
      setData(response.data.dados);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCombobox = async () => {
    try {
      const response1 = await NewApiGet(
        '/tabela/empresa',
        localStorage.getItem('sessionToken')
      );
      const resEmpresa = response1.data.tabela;

      const response2 = await NewApiGet(
        '/tabela/loja',
        localStorage.getItem('sessionToken')
      );
      const resLoja = response2.data.tabela;

      const response3 = await NewApiGet(
        '/tabela/unidadeAdm',
        localStorage.getItem('sessionToken')
      );
      const resUndAdm = response3.data.tabela;

      const response4 = await NewApiGet(
        '/tabela/centroCusto',
        localStorage.getItem('sessionToken')
      );
      const resCencus = response4.data.tabela;

      const response5 = await NewApiGet(
        '/tabela/naturezaDespesa',
        localStorage.getItem('sessionToken')
      );
      const resNatdes = response5.data.tabela;

      const response6 = await NewApiGet(
        '/tabela/fonteRecurso',
        localStorage.getItem('sessionToken')
      );
      const resFontrec = response6.data.tabela;

      const response7 = await NewApiGet(
        '/tabela/formaPag',
        localStorage.getItem('sessionToken')
      );
      const resForPag = response7.data.tabela;

      const response8 = await NewApiGet(
        '/tabela/tipoPag',
        localStorage.getItem('sessionToken')
      );
      const resTipPag = response8.data.tabela;

      setOptions([
        resEmpresa,
        resLoja,
        resUndAdm,
        resCencus,
        resNatdes,
        resFontrec,
        resForPag,
        resTipPag
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-Contabil') {
      handleSearch();
    }
  }, [activeTab, props.isOpen]);

  useEffect(() => {
    handleCombobox();

    const item1 = localStorage.getItem('sessionFantasia');
    const item2 = localStorage.getItem('sessionLoja');
    const item3 = localStorage.getItem('sessionCnpj');
    const item4 = localStorage.getItem('sessionEndereco');
    const item5 = localStorage.getItem('sessionTelefone');

    const newData = {
      empresa: item1,
      loja: item2,
      cnpj: item3,
      endereco: item4,
      telefone: item5
    };

    setRelConta([...relConta, newData]);
  }, []);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Contabil"
      tabIndex="-1"
      aria-labelledby="lblContas"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblContas"
              style={{ fontSize: props.tamTitulo }}
            >
              Contábil
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-Contabil' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasTransferencia"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                    options={options}
                    dataEmpresa={props.dataEmpresa}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Navegação</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            <button
              className="btn btn-primary"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTransferencia"
              aria-controls="offcanvasExample"
              style={{ fontSize: props.tamTexto }}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => RelContasCompleto(data, relConta, filtroPesquisa)}
              style={{ fontSize: props.tamTexto }}
            >
              <i className="fa-solid fa-sheet-plastic"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
