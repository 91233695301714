import { formatRequestArray } from '../../../../functions/formatRequestArray';
import { NewApiGet } from '../../../../services/apiService';

export const fetchDataLine = async (
  startDate = '',
  endDate = '',
  param = '',
  cliente = ''
) => {
  const params = new URLSearchParams({
    item: formatRequestArray(param),
    dataInicial: startDate,
    dataFinal: endDate,
    cliente: formatRequestArray(cliente)
  });
  try {
    const response = await NewApiGet(
      `/v1/graficos/vendasPorData?${params.toString()}`,
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataPie = async (
  startDate = '',
  endDate = '',
  cliente = ''
) => {
  try {
    const response = await NewApiGet(
      `/v1/graficos/vendasPorItem?dataInicial=${startDate}&dataFinal=${endDate}&cliente=${formatRequestArray(
        cliente
      )}`,
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataBar = (
  startDate = '',
  endDate = '',
  item = '',
  cfop = ''
) => {
  try {
    item = formatRequestArray(item);
    const response = NewApiGet(
      `/v1/graficos/topClientsVendas?dataInicial=${startDate}&dataFinal=${endDate}&item=${item}&cfop=${cfop}`,
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataSheet = (startDate = '', endDate = '', cfop = '') => {
  try {
    const response = NewApiGet(
      `/v1/graficos/relatorioVendas?dataInicial=${startDate}&dataFinal=${endDate}&cfop=${cfop}`,
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataDropdownItem = async () => {
  try {
    const response = await NewApiGet(
      `/v1/graficos/filtro/itemsVenda`,
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataDropdownCliente = async () => {
  try {
    const response = await NewApiGet(
      `/v1/graficos/filtro/clientesVenda`,
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
