export function CadOutros(props) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Situação e Usuário */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Situação</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Usuário</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_sitpes"
              value={props.formData?.pes_sitpes || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_usuario"
              value={props.formData?.pes_usuario || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Observação */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Observação</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_observ"
              value={props.formData?.pes_observ || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Cód. Categoria e Tipo de Atividade */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Cód. Categoria</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Tipo de Atividade</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_codcateg"
              value={props.formData?.pes_codcateg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_tipativ"
              value={props.formData?.pes_tipativ || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Data da Alteração e Hora da Alteração */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Data da Alteração</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Hora da Alteração</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="pes_datalt"
              value={props.formData?.pes_datalt?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_horalt"
              value={props.formData?.pes_horalt || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
