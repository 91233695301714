import { ResponsiveLine } from '@nivo/line';

export const ChartLine = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data</p>;
  }
  // Sort data by date
  const sortedData = [...data].sort(
    (a, b) => new Date(a.periodo) - new Date(b.periodo)
  );

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short' };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(num);
  };

  // Helper function to get the start of the month for a date
  const getMonthStart = (date) => {
    // return new Date(date.getFullYear(), date.getMonth(), 1);
    const [year, month] = date.split('-');
    return new Date(year, month - 1, 1);
  };

  // Group data by month
  const groupedData = sortedData.reduce((acc, item) => {
    // const monthStart = getMonthStart(new Date(item.periodo)).getTime();
    const monthStart = getMonthStart(item.periodo).getTime();
    acc[monthStart] = (acc[monthStart] || 0) + item.sum;
    return acc;
  }, {});

  // Convert grouped data to array
  const monthlyData = Object.entries(groupedData).map(([monthStart, sum]) => ({
    periodo: new Date(parseInt(monthStart)),
    sum
  }));

  // Sort monthly data by date
  const sortedMonthlyData = monthlyData.sort((a, b) => a.periodo - b.periodo);
  const dataInfo = [
    {
      id: 'Data',
      color: 'red',
      data: sortedMonthlyData.map((item) => ({
        x: formatDate(item.periodo),
        y: item.sum
      }))
    }
  ];
  return (
    <div style={{ height: '400px', width: '95%' }}>
      <ResponsiveLine
        data={dataInfo}
        margin={{ top: 50, right: 85, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Periodo',
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Valor Total',
          legendOffset: -55,
          legendPosition: 'middle'
        }}
        colors={(d) => d.color}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        enablePointLabel={true}
        pointLabelYOffset={-12}
        lineWidth={3}
        useMesh={true}
        yFormat={(value) => `R$${formatNumber(value)}`}
        pointLabel="yFormatted"
        // tooltip={({ point }) => {
        //   return (
        //     <div
        //       style={{ padding: '10px', color: 'black', background: 'white' }}
        //     >
        //       Valor: {formatNumber(point.data.y)}
        //     </div>
        //   );
        // }}
      />
    </div>
  );
};
