import { useEffect, useState } from 'react';

import { ApiGet } from '../../../services/apiService';

import { format } from 'date-fns';

export function CadPessoa(props) {
  const [valueRadio, setValueRadio] = useState('');
  const [valueDoc, setValueDoc] = useState('');
  const [valueEvento, setValueEvento] = useState('');
  const [dataEvento, setdataEvento] = useState([]);

  const handleRadioChange = (e) => {
    setValueRadio(e.target.value);
  };

  const handleTipoPessoa = async () => {
    /*
      1 - Cliente
      4 - Funcionario
      8 - Empresa
      32 - Agencia
    */

    // Verificação dos campos
    if (valueDoc === '' || valueRadio === '') {
      alert('preencha todos os campos');
      return;
    }

    if (props.tipoPessoa === 'cliente' || props.tipoPessoa === 'funcionario') {
      let body = {
        id: props.tipoPessoa === 'cliente' ? '    1' : '    4',
        cpf: valueRadio === 'CPF' ? valueDoc : '',
        rg: valueRadio === 'RG' ? valueDoc : '',
        cnpj: valueRadio === 'CNPJ' ? valueDoc : ''
      };

      const response = await ApiGet(
        '/pessoa/tipo',
        body,
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data?.mensagem.msg);
        props.setActiveTab(3);
        return;
      } else if (response.data?.pessoa.length === 0) {
        alert('Nenhuma pessoa encontrada');
        props.setActiveTab(3);
        return;
      } else {
        const codpes = response.data?.pessoa[0]?.pes_codpes;
        const nome = response.data?.pessoa[0]?.pes_nome;
        if (props.tipoPessoa === 'cliente') {
          props.setFormData({
            ...props.formData,
            res_codhos: codpes,
            res_nomhos: nome
          });
        } else {
          props.setFormData({
            ...props.formData,
            res_codfun: codpes,
            res_nomfun: nome
          });
        }
        setValueDoc('');
        setValueRadio(valueRadio);
        props.setActiveTab(3);
      }
    } else if (
      props.tipoPessoa === 'empresa' ||
      props.tipoPessoa === 'filial'
    ) {
      const response = await ApiGet(
        '/pessoa/tipo',
        {
          id: '    8',
          cpf: valueRadio === 'CPF' ? valueDoc : '',
          rg: valueRadio === 'RG' ? valueDoc : '',
          cnpj: valueRadio === 'CNPJ' ? valueDoc : ''
        },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data?.mensagem.msg);
        props.setActiveTab(3);
        return;
      }

      const response1 = await ApiGet(
        '/pessoa/tipo',
        {
          id: '   32',
          cpf: valueRadio === 'CPF' ? valueDoc : '',
          rg: valueRadio === 'RG' ? valueDoc : '',
          cnpj: valueRadio === 'CNPJ' ? valueDoc : ''
        },
        localStorage.getItem('sessionToken')
      );
      if (response1.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data?.mensagem.msg);
        props.setActiveTab(3);
        return;
      }

      const resEmpFil = [...response.data.pessoa, ...response1.data.pessoa];
      if (resEmpFil.length === 0) {
        alert('Nenhuma empresa/filial encontrada');
        props.setActiveTab(3);
        return;
      } else {
        const codpes = resEmpFil[0]?.pes_codpes;
        const nome = resEmpFil[0]?.pes_nome;
        if (props.tipoPessoa === 'empresa') {
          props.setFormData({
            ...props.formData,
            res_codemp: codpes,
            res_nomemp: nome
          });
        } else {
          props.setFormData({
            ...props.formData,
            res_codfil: codpes,
            nome_filial: nome
          });
        }
        setValueDoc('');
        setValueRadio(valueRadio);
        props.setActiveTab(3);
      }
    }
  };

  const handleEvento = async () => {
    const body = {
      codevn: '',
      evento: '',
      datini: []
    };

    try {
      const response = await ApiGet(
        '/v1/evento',
        { evento: body },
        localStorage.getItem('sessionToken')
      );
      const sortedData = response.data.evento.sort(
        (a, b) => new Date(a.evn_datini) - new Date(b.evn_datini)
      );
      setdataEvento(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleEvento();
  }, []);

  return props.tipoPessoa !== 'evento' ? (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="form-check me-2">
            <input
              className="form-check-input"
              type="radio"
              name="document"
              id="cnpj"
              value="CNPJ"
              onChange={handleRadioChange}
            />
            <label className="form-check-label" htmlFor="cnpj">
              CNPJ
            </label>
          </div>
          <div className="form-check me-2">
            <input
              className="form-check-input"
              type="radio"
              name="document"
              id="cpf"
              value="CPF"
              onChange={handleRadioChange}
            />
            <label className="form-check-label" htmlFor="cpf">
              CPF
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="document"
              id="rg"
              value="RG"
              onChange={handleRadioChange}
            />
            <label className="form-check-label" htmlFor="rg">
              RG
            </label>
          </div>
        </div>
        <div className="mt-3">
          <input
            type="text"
            className="form-control"
            placeholder="Digite o documento"
            value={valueDoc}
            onChange={(e) => setValueDoc(e.target.value)}
          />
        </div>
        <button
          className="btn btn-sm btn-primary w-100 mt-2"
          onClick={handleTipoPessoa}
        >
          Concluir
        </button>
      </div>
    </div>
  ) : (
    <>
      <div className="d-flex justify-content-center align-items-center mb-2">
        <input
          className="form-control form-control-sm border border-dark"
          type="text"
          placeholder="Digite o nome do evento"
          value={valueEvento}
          onChange={(e) => setValueEvento(e.target.value)}
        />
      </div>

      <table
        className="table table-striped table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            <th scope="col" className="grid">
              Cod Evento
            </th>
            <th scope="col" className="grid">
              Nome Evento
            </th>
            <th scope="col" className="grid">
              Dt. Inicial
            </th>
            <th scope="col" className="grid"></th>
          </tr>
        </thead>
        <tbody>
          {dataEvento
            ?.filter((item) =>
              item.evn_evento.includes(valueEvento.toUpperCase())
            )
            .map((data, index) => (
              <tr key={index}>
                <td scope="row">{data.evn_codevn}</td>
                <td>{data.evn_evento}</td>
                <td>{format(new Date(data.evn_datini), 'dd/MM/yyyy')}</td>
                <td>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        props.setFormData({
                          ...props.formData,
                          res_codevn: data.evn_codevn,
                          res_evento: data.evn_evento
                        });
                        setValueDoc('');
                        setValueRadio(valueRadio);
                        props.setActiveTab(3);
                      }}
                    >
                      <i className="fa-solid fa-check"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
