import React from 'react';

export const SheetTable = ({ data }) => {
  const headers = [
    'CodigoItem',
    'Descrição',
    'U.Emb',
    'Qt.Embalagem',
    'Unidade',
    'Quantidade',
    'Valor Total'
  ];

  return (
    <div style={{ width: '95%', height: '40vh' }}>
      <h4 style={{ backgroundColor: 'grey', color: 'white', padding: '10px' }}>
        Detalhamento dos Itens Vendidos
      </h4>
      <div style={{ height: 'calc(100% - 60px)', overflowY: 'auto' }}>
        <table className="table table-striped table-hover">
          <thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 0,
              backgroundColor: 'white'
            }}
          >
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.coditem}</td>
                <td>{item.item_desc}</td>
                <td>{item.u_emb}</td>
                <td>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 0
                  }).format(item.qtdembalagem)}
                </td>
                <td>{item.unidade}</td>
                <td>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 0
                  }).format(item.quantidade)}
                </td>
                <td>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2
                  }).format(item.valortotal)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
