export const menu_venda = (area) => {
  switch (area) {
    case 'HOTEL':
      return [
        {
          id: 'EST_VENDA_HOTEL',
          name: ' Análise de Vendas Hotel'
        },
        {
          id: 'FIN_REC_HOTEL',
          name: 'Análise de Receita de Hotelaria'
        }
      ];
    case 'COMER':
      return [
        {
          id: 'EST_VENDA',
          name: ' Análise de Vendas'
        }
      ];
    default:
      return [];
  }
};

export const menu_estoque = [
  {
    id: 'EST_COMPRA',
    name: 'Análise de Compras'
  }
];

export const menu_financeiro = (area) => {
  const menu = [
    {
      id: 'FIN_NDFR',
      name: 'Análise por Natureza de Despesa e Fonte de Recurso'
    },
    {
      id: 'FIN_CTCUST',
      name: 'Análise por Centro de Custo'
    },
    {
      id: 'CON_GERAL',
      name: 'Análise de Contas Geral'
    },
    {
      id: 'FIN_CONTABIL',
      name: 'Análise Contábil'
    }
  ];

  switch (area) {
    case 'HOTEL':
      break;
    default:
      break;
  }

  return menu;
};
