import { useEffect, useState } from 'react';

import { ApiPut } from '../../../services/apiService';

const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: props.tamTexto }}>{props.title}</span>
        <div>
          <span onClick={() => props.handleSort(props.order, 'asc')}>
            <i className="fa-solid fa-arrow-up ms-2"></i>
          </span>
          <span
            className="ms-1"
            onClick={() => props.handleSort(props.order, 'desc')}
          >
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export function Grid(props) {
  const campos = [
    { title: 'Nome Hóspede', order: 'nome' },
    { title: 'Nº Reserva', order: 'numres' },
    { title: 'Nº Apto', order: 'numapa' },
    { title: 'Status', order: 'status' },
    { title: 'Cod. Evento', order: 'evento' },
    { title: 'CkOnline', order: 'checkin' },
    { title: 'Placa', order: 'placa' }
  ];

  const reservasUnicas = new Set(props.data?.map((item) => item.res_numres));

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'numres':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.res_numres?.localeCompare(b.res_numres)
          )
        );
        break;
      case 'nome':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier *
              a.enh_nomctt?.trim().localeCompare(b.enh_nomctt?.trim())
          )
        );
        break;
      case 'numapa':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.res_numapa?.localeCompare(b.res_numapa)
          )
        );
        break;
      case 'status':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.enh_status?.localeCompare(b.enh_status)
          )
        );
        break;
      case 'evento':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.res_codevn?.localeCompare(b.res_codevn)
          )
        );
        break;
      case 'checkin':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.enh_checkin?.localeCompare(b.enh_checkin)
          )
        );
        break;
      case 'placa':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.res_placar?.localeCompare(b.res_placar)
          )
        );
        break;
      case 'observ':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.res_observ?.localeCompare(b.res_observ)
          )
        );
        break;
      default:
        props.setData([...props.data]);
        break;
    }
  };

  const solicitarPlaca = (res, placa) => {
    const numres = res;
    const placaInserida = prompt('Digite a placa do veículo:', placa);

    if (placaInserida === null) {
      alert('Você cancelou a inserção da placa.');
    } else if (placaInserida === '') {
      alert('Você não inseriu uma placa.');
    } else {
      handlePlaca(numres, placaInserida);
    }
  };

  const handlePlaca = async (Res, Placa) => {
    try {
      const numresFormat = Res.padStart(10, '0');
      const placaFormat = Placa.toUpperCase();
      const response = await ApiPut(
        '/reserva/v1/placa',
        { reserva: numresFormat, placa: placaFormat },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      props.handleSearch();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.data.length / itemsPerPage));
    setCurrentPage(0);
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <table
        className="table table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            {campos.map((campo) => (
              <BlcTitle
                key={campo.order}
                title={campo.title}
                order={campo.order}
                handleSort={handleSort}
                tamTexto={props.tamTexto}
              />
            ))}
            <th
              scope="col"
              className="grid"
              style={{ fontSize: props.tamTexto }}
            >
              Editar
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((data, index) => (
            <tr
              key={index}
              className={
                data.enh_status === 'PRESENTE' ||
                data.enh_status === 'CONCLUIDO'
                  ? 'table-success'
                  : data.enh_checkin === 'SIM'
                  ? 'table-warning'
                  : 'table-danger'
              }
            >
              <td style={{ fontSize: props.tamSubTexto }}>{data.enh_nomctt}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.res_numres}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.res_numapa}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.enh_status}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.res_codevn}</td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {data.enh_checkin ? data.enh_checkin : 'NAO'}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>{data.res_placar}</td>
              <td
                style={{ fontSize: props.tamSubTexto }}
                className="text-center"
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    solicitarPlaca(data.res_numres, data.res_placar);
                  }}
                >
                  Placa
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="8" className="align-middle">
              <nav className="d-flex align-items-center justify-content-center">
                <ul className="h-100 pagination pagination-sm">
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousPage"
                      onClick={() => handleFirstPage()}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousIten"
                      onClick={() => handlePageClick(currentPage - 1)}
                    >
                      <span aria-hidden="true">&lt;</span>
                    </button>
                  </li>
                  {displayedPageNumbers.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        pageNumber === currentPage ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(pageNumber)}
                      >
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextIten"
                      onClick={() => handlePageClick(currentPage + 1)}
                    >
                      <span aria-hidden="true">&gt;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextPage"
                      onClick={() => handleLastPage()}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
                <div className="d-flex flex-column">
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Qtd. de Reserva: {reservasUnicas.size}
                  </p>
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Qtd. de Hóspede: {props.data.length}
                  </p>
                </div>
              </nav>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}
