import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function RelEstoque(data, relEstoque, filtro) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const formattedTime = currentDate.toLocaleTimeString();

  const reportTitle = [
    {
      fontSize: 11,
      bold: true,
      margin: [15, 15, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Empresa: ' + relEstoque[0].empresa, alignment: 'left' },
        { text: 'Data: ' + formattedDate, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Loja: ' + relEstoque[0].loja, alignment: 'left' },
        { text: `Hora:      ` + formattedTime, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      text: [
        'CNPJ: ' + relEstoque[0].cnpj,
        'Endereço: ' + relEstoque[0].endereco,
        'Telefone: ' + relEstoque[0].telefone
      ].join('\n'),
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0]
    }
  ];

  const maxLineLength = 150;
  let currentLine = '';
  const reportContent = [];

  const filtroDetails = filtro.map((detalhe) => {
    const { campo, conteudo } = detalhe;
    if (campo.includes('Dt Mov')) {
      const [dataIni, dataEnd] = conteudo;
      return `${campo} = ${dataIni.trim()} a ${dataEnd.trim()}`;
    } else if (campo.includes('Dt Ven')) {
      const [dataIni, dataEnd] = conteudo;
      return `${campo} = ${dataIni.trim()} a ${dataEnd.trim()}`;
    } else {
      return `${campo} = ${conteudo}`;
    }
  });

  filtroDetails.forEach((filtroItem, index) => {
    const filterPrefix = index === 0 ? 'Filtrado por: ' : '';
    const newLine = `${filterPrefix}${currentLine}${filtroItem}; `;
    if (newLine.length <= maxLineLength) {
      currentLine = newLine;
    } else {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
      currentLine = `${filtroItem}; `;
    }
  });

  if (currentLine !== '') {
    if (currentLine.includes('Filtrado por: ')) {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
    } else {
      reportContent.push({
        text: `.                     ${currentLine}`,
        fontSize: 8
      });
    }
  }

  const dados = data.map((opt) => {
    return [
      { text: opt.etq_undadm, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.itm_tipo, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.itm_grupo, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.itm_subgrupo, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.etq_item, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.itm_descri, fontSize: 8, margin: [0, 2, 0, 2] },
      {
        text: opt.etq_qtdest.toLocaleString('pt-BR'),
        fontSize: 8,
        margin: [0, 2, 0, 2],
        alignment: 'right'
      },
      {
        text: opt.etq_minimo.toLocaleString('pt-BR'),
        fontSize: 8,
        margin: [0, 2, 0, 2],
        alignment: 'right'
      },
      {
        text: opt.resup.toLocaleString('pt-BR'),
        fontSize: 8,
        margin: [0, 2, 0, 2],
        alignment: 'right',
        style: {
          color: opt.etq_qtdest < opt.etq_minimo ? 'red' : 'black'
        }
      }
    ];
  });

  const qtdRegistros = dados.length;

  const styles = {
    tableHeader: {
      bold: true
    }
  };

  const details = [
    {
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', '*', 32, 32, 32],
        body: [
          [
            { text: 'Und Adm', style: 'tableHeader', fontSize: 9 },
            { text: 'Tipo', style: 'tableHeader', fontSize: 9 },
            { text: 'Grupo', style: 'tableHeader', fontSize: 9 },
            { text: 'Sub Grupo', style: 'tableHeader', fontSize: 9 },
            { text: 'Item', style: 'tableHeader', fontSize: 9 },
            { text: 'Descri', style: 'tableHeader', fontSize: 9 },
            { text: 'Qtd Est', style: 'tableHeader', fontSize: 9 },
            { text: 'Minimo', style: 'tableHeader', fontSize: 9 },
            { text: 'Resup', style: 'tableHeader', fontSize: 9 }
          ],
          ...dados
        ]
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
        }
      }
    },
    {
      table: {
        headerRows: 0,
        widths: ['*', 'auto'],
        body: [
          [
            {
              text: 'Quantidade de registros:',
              alignment: 'right',
              bold: true,
              fontSize: 8
            },
            {
              text: qtdRegistros.toLocaleString('pt-BR'),
              alignment: 'right',
              fontSize: 8
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
        paddingTop: function () {
          return 3;
        },
        paddingBottom: function () {
          return 3;
        }
      }
    }
  ];

  function Rodape(currentPage, pageCount) {
    return [
      {
        text: currentPage + ' / ' + pageCount,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0]
      }
    ];
  }

  const docDefinitios = {
    pageSize: 'A4',
    // pageMargins: [15, 90, 15, 40],
    pageMargins: [2, 90, 2, 30],

    header: [reportTitle],
    content: [
      { text: 'Relação de Estoque', style: 'header' },
      reportContent,
      details
    ],
    footer: Rodape,
    styles: styles
  };

  pdfMake.createPdf(docDefinitios).download();
}

export default RelEstoque;
