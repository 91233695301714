import { DebounceInput } from 'react-debounce-input';

export const FiltrosDate = (props) => {
  let title1 = props.title1;
  let title2 = props.title2;

  if (props.title1 === undefined && props.title2 === undefined) {
    title1 = 'Data Inicial';
    title2 = 'Data Final';
  }
  return (
    <div className="w-100 d-flex flex-column flex-md-row gap-2">
      <div
        className="d-flex flex-column w-100 p-1 rounded"
        style={{ backgroundColor: '#fff' }}
      >
        <label className="form-label">{title1}</label>
        <DebounceInput
          type="date"
          className="form-control"
          debounceTimeout={1000}
          value={props.startDate}
          onChange={(event) => props.setStartDate(event.target.value)}
          style={{ marginTop: -8 }}
        />
      </div>

      <div
        className="d-flex flex-column w-100 p-1 rounded"
        style={{ backgroundColor: '#fff' }}
      >
        <label className="form-label">{title2}</label>
        <DebounceInput
          type="date"
          className="form-control"
          debounceTimeout={1000}
          value={props.endDate}
          onChange={(event) => props.setEndDate(event.target.value)}
          style={{ marginTop: -8 }}
        />
      </div>
    </div>
  );
};
