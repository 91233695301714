import { useEffect, useState } from 'react';

const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: props.tamTexto }}>{props.title}</span>
        <div>
          <span onClick={() => props.handleSort(props.order, 'asc')}>
            <i className="fa-solid fa-arrow-up ms-2"></i>
          </span>
          <span
            className="ms-1"
            onClick={() => props.handleSort(props.order, 'desc')}
          >
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export const Grid = (props) => {
  const campos = [
    // { title: 'Emp', order: 'empresa' },
    // { title: 'Loja', order: 'loja' },
    { title: 'Und Adm', order: 'undadm' },
    // { title: 'Tipo', order: 'tipo' },
    { title: 'Grupo', order: 'grupo' },
    { title: 'Sub Grupo', order: 'subgrupo' },
    { title: 'Item', order: 'item' },
    { title: 'Des Cri', order: 'descri' },
    { title: 'Qtd Est', order: 'qtdest' },
    { title: 'Minimo', order: 'minimo' },
    { title: 'Resup', order: 'resup' }
  ];

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
    setSelectedRow(null);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelectedRow(null);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
    setSelectedRow(null);
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'empresa':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.etq_empresa.localeCompare(b.etq_empresa)
          )
        );
        break;
      case 'loja':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.etq_loja.localeCompare(b.etq_loja)
          )
        );
        break;
      case 'undadm':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.etq_undadm.localeCompare(b.etq_undadm)
          )
        );
        break;
      case 'grupo':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.itm_grupo.localeCompare(b.itm_grupo)
          )
        );
        break;
      case 'subgrupo':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.itm_subgrupo.localeCompare(b.itm_subgrupo)
          )
        );
        break;
      case 'item':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.etq_item.localeCompare(b.etq_item)
          )
        );
        break;
      case 'descri':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.itm_descri?.localeCompare(b.itm_descri)
          )
        );
        break;
      case 'qtdest':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier * (parseFloat(a.etq_qtdest) - parseFloat(b.etq_qtdest))
          )
        );
        break;
      case 'minimo':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier * (parseFloat(a.etq_minimo) - parseFloat(b.etq_minimo))
          )
        );
        break;
      case 'resup':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * (parseFloat(a.resup) - parseFloat(b.resup))
          )
        );
        break;
      default:
        props.setData([...props.data]);
        break;
    }
  };

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.data.length / itemsPerPage));
    setCurrentPage(0);
    setSelectedRow(null);
    // eslint-disable-next-line
    }, [props.data]);

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    // eslint-disable-next-line
    }, [currentPage]);

  return (
    <>
      <table
        className="table table-striped table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            {campos.map((campo) => (
              <BlcTitle
                key={campo.order}
                title={campo.title}
                order={campo.order}
                handleSort={handleSort}
                tamTexto={props.tamTexto}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((data, index) => (
            <tr
              key={index}
              className={selectedRow === index ? 'bg-info' : ''}
              onClick={() => handleRowClick(index)}
            >
              {/* <th scope="row" style={{ fontSize: props.tamSubTexto }}>
                {data.etq_empresa}
              </th>
              <td style={{ fontSize: props.tamSubTexto }}>{data.etq_loja}</td> */}
              <td className="grid" style={{ fontSize: props.tamSubTexto }}>
                {data.etq_undadm}
              </td>
              {/* <td className="grid" style={{ fontSize: props.tamSubTexto }}>
                {data.itm_tipo}
              </td> */}
              <td className="grid" style={{ fontSize: props.tamSubTexto }}>
                {data.itm_grupo}
              </td>
              <td className="grid" style={{ fontSize: props.tamSubTexto }}>
                {data.itm_subgrupo}
              </td>
              <td className="grid" style={{ fontSize: props.tamSubTexto }}>
                {data.etq_item}
              </td>
              <td className="grid" style={{ fontSize: props.tamSubTexto }}>
                {data.itm_descri}
              </td>
              <td className="text-end" style={{ fontSize: props.tamSubTexto }}>
                {data.etq_qtdest.toLocaleString()}
              </td>
              <td className="text-end" style={{ fontSize: props.tamSubTexto }}>
                {data.etq_minimo.toLocaleString()}
              </td>
              <td className="text-end" style={{ fontSize: props.tamSubTexto }}>
                {data.resup.toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="8" className="align-middle">
              <nav className="d-flex align-items-center justify-content-center">
                <ul className="h-100 pagination pagination-sm">
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousPage"
                      onClick={() => handleFirstPage()}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousIten"
                      onClick={() => handlePageClick(currentPage - 1)}
                    >
                      <span aria-hidden="true">&lt;</span>
                    </button>
                  </li>
                  {displayedPageNumbers.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        pageNumber === currentPage ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(pageNumber)}
                      >
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextIten"
                      onClick={() => handlePageClick(currentPage + 1)}
                    >
                      <span aria-hidden="true">&gt;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextPage"
                      onClick={() => handleLastPage()}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
                <p
                  className="text-muted ms-2"
                  style={{ fontSize: props.tamTexto }}
                >
                  Número de Registros:{' '}
                  {props.data.length.toLocaleString('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}
                </p>
              </nav>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};
