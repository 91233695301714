export function CadGeral(props) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Cód. Empresa e Empresa */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cód. Empresa</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Empresa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_empresa"
              value={props.formData?.ent_empresa || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="empresa"
              value={props.formData?.empresa || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
        </div>

        {/* Cód. Loja e Loja */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cód. Loja</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Loja</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_loja"
              value={props.formData?.ent_loja || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="loja"
              value={props.formData?.loja || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
        </div>

        {/* Cód. Und Adm e Unidade Administrativa */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cód. Und. Adm</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Unidade Administrativa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_undadm"
              value={props.formData?.ent_undadm || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="unidade_administrativa"
              value={props.formData?.unidade_administrativa || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Data Inclusão e Hora Inclusão */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Data Inclusão</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Hora Inclusão</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="ent_datincl"
              value={props.formData?.ent_datincl?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_horincl"
              value={props.formData?.ent_horincl || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
        </div>

        {/* Data Alteração e Hora Alteração */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Data Alteração</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Hora Alteração</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="ent_datatz"
              value={props.formData?.ent_datatz?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_horatz"
              value={props.formData?.ent_horatz || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
        </div>

        {/* Usuário Alteração */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Usuário Alteração</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_codusu"
              value={props.formData?.ent_codusu || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
}
