import { useEffect, useState } from 'react';

const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: props.tamTexto }}>{props.title}</span>
        <div>
          <span onClick={() => props.handleSort(props.order, 'asc')}>
            <i className="fa-solid fa-arrow-up ms-2"></i>
          </span>
          <span
            className="ms-1"
            onClick={() => props.handleSort(props.order, 'desc')}
          >
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export function Grid(props) {
  const campos = [
    { title: 'Cód. Pessoa', order: 'codpes' },
    { title: 'Nome', order: 'nome' },
    { title: 'Email', order: 'email' },
    { title: 'Celular', order: 'celular' },
    { title: 'Comercial', order: 'comercial' },
    { title: 'Residencial', order: 'residencial' }
  ];

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
    setSelectedRow(null);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelectedRow(null);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
    setSelectedRow(null);
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'codpes':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.pes_codpes.localeCompare(b.pes_codpes)
          )
        );
        break;
      case 'nome':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.pes_nome.localeCompare(b.pes_nome)
          )
        );
        break;
      case 'email':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.pes_email.localeCompare(b.pes_email)
          )
        );
        break;
      case 'celular':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier *
              `${a.pes_precel} ${a.pes_telcel}`.localeCompare(
                `${b.pes_precel} ${b.pes_telcel}`
              )
          )
        );
        break;
      case 'comercial':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier *
              `${a.pes_precom} ${a.pes_telcom}`.localeCompare(
                `${b.pes_precom} ${b.pes_telcom}`
              )
          )
        );
        break;
      case 'residencial':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier *
              `${a.pes_preres} ${a.pes_telres}`.localeCompare(
                `${b.pes_preres} ${b.pes_telres}`
              )
          )
        );
        break;
      default:
        props.setData([...props.data]);
        break;
    }
  };

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.data.length / itemsPerPage));
    setCurrentPage(0);
    setSelectedRow(null);
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <table
      className="table table-striped table-hover table-bordered table-sm align-middle"
      style={{ height: '100%' }}
    >
      <thead>
        <tr className="table-secondary">
          {campos.map((campo) => (
            <BlcTitle
              key={campo.order}
              title={campo.title}
              order={campo.order}
              handleSort={handleSort}
              tamTexto={props.tamTexto}
            />
          ))}
          <th
            scope="col"
            style={{ fontSize: props.tamTexto, width: '80px' }}
            className="grid"
          >
            Visualizar
          </th>
          {localStorage.getItem('sessionNivelDeAcesso') === '16' ||
          localStorage.getItem('sessionNivelDeAcesso') === '22' ||
          localStorage.getItem('sessionNivelDeAcesso') === '50' ||
          localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
            <th
              scope="col"
              style={{ fontSize: props.tamTexto, width: '80px' }}
              className="grid"
            >
              Editar
            </th>
          ) : null}
          {localStorage.getItem('sessionNivelDeAcesso') === '22' ||
          localStorage.getItem('sessionNivelDeAcesso') === '50' ||
          localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
            <th
              scope="col"
              style={{ fontSize: props.tamTexto, width: '80px' }}
              className="grid"
            >
              Deletar
            </th>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item, index) => (
          <tr
            key={index}
            className={selectedRow === index ? 'bg-info' : ''}
            onClick={() => handleRowClick(index)}
          >
            <td scope="row" style={{ fontSize: props.tamSubTexto }}>
              {item.pes_codpes}
            </td>
            <td style={{ fontSize: props.tamSubTexto }}>{item.pes_nome}</td>
            <td style={{ fontSize: props.tamSubTexto }}>{item.pes_email}</td>
            <td style={{ fontSize: props.tamSubTexto }}>
              {item.pes_precel} {item.pes_telcel}
            </td>
            <td className="grid" style={{ fontSize: props.tamSubTexto }}>
              {item.pes_precom} {item.pes_telcom}
            </td>
            <td style={{ fontSize: props.tamSubTexto }}>
              {item.pes_preres} {item.pes_telres}
            </td>
            <td>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  style={{ fontSize: props.tamSubTexto }}
                  onClick={() => {
                    props.setViewOrUpdate('view');
                    props.handleEdit(item.pes_codpes);
                  }}
                >
                  <i className="fa-solid fa-eye"></i>
                </button>
              </div>
            </td>
            {localStorage.getItem('sessionNivelDeAcesso') === '16' ||
            localStorage.getItem('sessionNivelDeAcesso') === '22' ||
            localStorage.getItem('sessionNivelDeAcesso') === '50' ||
            localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
              <td>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    style={{ fontSize: props.tamSubTexto }}
                    onClick={() => {
                      props.setInsertOrUpdate('update');
                      props.setViewOrUpdate('update');
                      props.handleEdit(item.pes_codpes);
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                </div>
              </td>
            ) : null}
            {localStorage.getItem('sessionNivelDeAcesso') === '22' ||
            localStorage.getItem('sessionNivelDeAcesso') === '50' ||
            localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
              <td>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    style={{ fontSize: props.tamSubTexto }}
                    onClick={async () => {
                      const id = item.pes_codpes;
                      await props.handleDelete(id);
                    }}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
              </td>
            ) : null}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="8">
            <nav className="d-flex align-items-center justify-content-center">
              <ul className="h-100 pagination pagination-sm">
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="PreviousPage"
                    onClick={() => handleFirstPage()}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="PreviousIten"
                    onClick={() => handlePageClick(currentPage - 1)}
                  >
                    <span aria-hidden="true">&lt;</span>
                  </button>
                </li>
                {displayedPageNumbers.map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === currentPage ? 'active' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageClick(pageNumber)}
                    >
                      {pageNumber + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="NextIten"
                    onClick={() => handlePageClick(currentPage + 1)}
                  >
                    <span aria-hidden="true">&gt;</span>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="NextPage"
                    onClick={() => handleLastPage()}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </li>
              </ul>
              <p
                className="text-muted ms-2"
                style={{ fontSize: props.tamTexto }}
              >
                Número de Registros:{' '}
                {props.data.length.toLocaleString('pt-BR', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
              </p>
            </nav>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
