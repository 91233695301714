import { NumericFormat } from 'react-number-format';

export const CadConsumo = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Num Con</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Tip Con</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="numeroConsumo"
              value={props.formData?.numeroConsumo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="tipoConsumo"
              value={props.formData?.tipoConsumo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Num Carga</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Num Saída</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="numeroCarga"
              value={props.formData?.numeroCarga || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="numeroSaida"
              value={props.formData?.numeroSaida || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Forma de Pagt.</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Tipo de Pagt.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="formaPagamento"
              value={props.formData.formaPagamento || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[4]?.map((option, index) => (
                <option key={index} value={option.fpg_forpag}>
                  {option.fpg_desfpg}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="tipoPagamento"
              value={props.formData.tipoPagamento || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[3]?.map((option, index) => (
                <option key={index} value={option.tpg_tippag}>
                  {option.tpg_destpg}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Código</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Pessoa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="codigoPessoa"
              value={props.formData?.codigoPessoa || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="nomePessoa"
              value={props.formData?.nomePessoa || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Código</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Vendedor</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="codigoVendedor"
              value={props.formData?.codigoVendedor || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="nomeVendedor"
              value={props.formData?.nomeVendedor || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Dt Movimento</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Dt Fechamento</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="dataMovimento"
              value={props.formData?.dataMovimento?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="dataFechamento"
              value={props.formData?.dataFechamento?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Valor</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Desconto</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <NumericFormat
              value={props.formData.valor || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  valor: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-3">
            <NumericFormat
              value={props.formData.valorDesconto || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  valorDesconto: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
