export function CadDados(props) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Cód. Hóspede e Nome Hóspede */}
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Hóspede</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Hóspede</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_codpes"
              value={props.formData?.pes_codpes || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_nome"
              value={props.formData?.pes_nome || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Email */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Email</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_email"
              value={props.formData?.pes_email || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Sexo e Profissão e Nacionalidade */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Sexo</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Profissão</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Nacionalidade</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_sexo"
              value={props.formData?.pes_sexo || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_profis"
              value={props.formData?.pes_profis || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_nacion"
              value={props.formData?.pes_nacion || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* RG e CPF e CNPJ */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">RG</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">CPF</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">CNPJ</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_rg"
              value={props.formData?.pes_rg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_cpf"
              value={props.formData?.pes_cpf || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_cgc"
              value={props.formData?.pes_cgc || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Passaporte e Certidão e Org. Exp.*/}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Passaporte</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Certidão</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Org. Exp.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_npassprt"
              value={props.formData?.pes_npassprt || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_certnasc"
              value={props.formData?.pes_certnasc || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_orgexp"
              value={props.formData?.pes_orgexp || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
