import { useState } from 'react';

import { CadConsumo } from './CadConsumo';
import { CadGeral } from './CadGeral';

export const CadastroItems = (props) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleInput = (event) => {
    const { name, value } = event.target;
    props.setFormDataItems({ ...props.formDataItems, [name]: value });
  };

  return (
    <>
      <div className="nav nav-tabs">
        <p
          className={`nav-link ${
            activeTab === 1 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(1)}
          style={{ fontSize: props.tamTexto }}
        >
          Consumo
        </p>
        <p
          className={`nav-link ${
            activeTab === 2 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(2)}
          style={{ fontSize: props.tamTexto }}
        >
          Geral
        </p>
      </div>

      <div className="tab-content">
        <div
          className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
          id="tabCadastro1"
        >
          <CadConsumo
            dataEmpresa={props.dataEmpresa}
            handleInput={handleInput}
            formDataItems={props.formDataItems}
            setFormDataItems={props.setFormDataItems}
            viewOrUpdateItems={props.viewOrUpdateItems}
            options={props.options}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
          id="tabCadastro2"
        >
          <CadGeral
            dataEmpresa={props.dataEmpresa}
            handleInput={handleInput}
            formDataItems={props.formDataItems}
            setFormDataItems={props.setFormDataItems}
            viewOrUpdateItems={props.viewOrUpdateItems}
            options={props.options}
          />
        </div>
      </div>
    </>
  );
};
