import { useEffect, useState } from 'react';

import { format } from 'date-fns';

const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: props.tamTexto }}>{props.title}</span>
        <div>
          <span onClick={() => props.handleSort(props.order, 'asc')}>
            <i className="fa-solid fa-arrow-up ms-2"></i>
          </span>
          <span
            className="ms-1"
            onClick={() => props.handleSort(props.order, 'desc')}
          >
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export function Grid(props) {
  const campos = [
    { title: 'Cód. Evento', order: 'codevn' },
    { title: 'Nome Evento', order: 'evento' },
    { title: 'Data Inicial', order: 'datini' }
  ];

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'codevn':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier *
              a.evn_codevn?.trim().localeCompare(b.evn_codevn?.trim())
          )
        );
        break;
      case 'evento':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.evn_evento?.localeCompare(b.evn_evento)
          )
        );
        break;
      case 'datini':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.evn_datini?.localeCompare(b.evn_datini)
          )
        );
        break;
      default:
        props.setData([...props.data]);
        break;
    }
  };

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.data.length / itemsPerPage));
    setCurrentPage(0);
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <table
      className="table table-striped table-hover table-bordered table-sm align-middle"
      style={{ height: '100%' }}
    >
      <thead>
        <tr className="table-secondary">
          {campos.map((campo) => (
            <BlcTitle
              key={campo.order}
              title={campo.title}
              order={campo.order}
              handleSort={handleSort}
              tamTexto={props.tamTexto}
            />
          ))}
          <th scope="col" className="grid" style={{ fontSize: props.tamTexto }}>
            Editar
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((data, index) => (
          <tr key={index}>
            <td scope="row" style={{ fontSize: props.tamSubTexto }}>
              {data.evn_codevn}
            </td>
            <td style={{ fontSize: props.tamSubTexto }}>{data.evn_evento}</td>
            <td style={{ fontSize: props.tamSubTexto }}>
              {format(new Date(data.evn_datini), 'dd/MM/yyyy')}
            </td>
            <td>
              <div style={{ display: 'flex', gap: '5px' }}>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() =>
                    props.handleEdit(data.evn_codevn, data.evn_evento)
                  }
                  style={{ fontSize: props.tamSubTexto }}
                >
                  <i className="fas fa-edit"></i>
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4" className="align-middle">
            <nav className="d-flex align-items-center justify-content-center">
              <ul className="h-100 pagination pagination-sm">
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="PreviousPage"
                    onClick={() => handleFirstPage()}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="PreviousIten"
                    onClick={() => handlePageClick(currentPage - 1)}
                  >
                    <span aria-hidden="true">&lt;</span>
                  </button>
                </li>
                {displayedPageNumbers.map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === currentPage ? 'active' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageClick(pageNumber)}
                    >
                      {pageNumber + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="NextIten"
                    onClick={() => handlePageClick(currentPage + 1)}
                  >
                    <span aria-hidden="true">&gt;</span>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="NextPage"
                    onClick={() => handleLastPage()}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </li>
              </ul>
              <div>
                <p
                  className="text-muted ms-2"
                  style={{ fontSize: props.tamTexto }}
                >
                  Número de Registros: {props.data.length}
                </p>
              </div>
            </nav>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
