import { ResponsiveBar } from '@nivo/bar';

export const GraficoBarraSum = ({ data }) => {
  const sortedData = [...data].sort(
    (a, b) => new Date(a.periodo) - new Date(b.periodo)
  );
  const formatSum = (sum) => {
    return sum.toLocaleString('pt-BR', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL'
    });
  };
  const chartData = sortedData.map((item) => ({
    periodo: new Date(item.periodo).toLocaleDateString('pt-BR', {
      month: 'short',
      year: 'numeric'
    }),
    Valor: item.sum
  }));

  return (
    <div style={{ height: '400px', width: '95%' }}>
      <h4 style={{ backgroundColor: 'gray', color: 'white' }}>
        Total do Saldo por Período
      </h4>
      <ResponsiveBar
        data={chartData}
        keys={['Valor']}
        indexBy="periodo"
        margin={{ top: 10, right: 0, bottom: 95, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id, data }) =>
          data[id] < 0 ? 'lightcoral' : 'lightskyblue'
        }
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Periodo',
          legendPosition: 'middle',
          legendOffset: 39,
          truncateTickAt: 4
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Valor Total',
          legendPosition: 'middle',
          legendOffset: -55,
          truncateTickAt: 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 3.6]]
        }}
        tooltip={({ id, value }) => (
          <div
            style={{
              padding: '10px',
              color: 'black',
              background: 'white'
            }}
          >
            <strong>
              {id}: {formatSum(value)}
            </strong>
          </div>
        )}
        label={(d) => new Intl.NumberFormat('de-DE').format(d.value)}
      />
    </div>
  );
};
