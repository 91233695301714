import { useEffect, useState } from 'react';

import { format } from 'date-fns';

const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: props.tamTexto }}>{props.title}</span>
        <div>
          <span onClick={() => props.handleSort(props.order, 'asc')}>
            <i className="fa-solid fa-arrow-up ms-2"></i>
          </span>
          <span
            className="ms-1"
            onClick={() => props.handleSort(props.order, 'desc')}
          >
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export function Grid(props) {
  const campos = [
    { title: 'Nº Saída', order: 'numsai' },
    { title: 'Nº Reserva', order: 'numres' },
    { title: 'Nº Entrada', order: 'nument' },
    { title: 'Nº Apto', order: 'numapa' },
    { title: 'Situação', order: 'sitsai' },
    { title: 'Nome Hóspede', order: 'nomhos' },
    { title: 'Telefone', order: 'telcel' },
    { title: 'Dt Entrada', order: 'datent' },
    { title: 'Dt Saída', order: 'datsai' },
    { title: 'Vl Diária', order: 'diaria' }
  ];

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
    setSelectedRow(null);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelectedRow(null);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
    setSelectedRow(null);
  };

  function cleanNumber(numberString) {
    if (numberString) {
      return Number(numberString.replace(/[^0-9]/g, ''));
    } else {
      return 0;
    }
  }

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'empresa':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.sai_empresa.localeCompare(b.sai_empresa)
          )
        );
        break;
      case 'loja':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.sai_loja.localeCompare(b.sai_loja)
          )
        );
        break;
      case 'numres':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.sai_numres.localeCompare(b.sai_numres)
          )
        );
        break;

      case 'nument':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.sai_nument.localeCompare(b.sai_nument)
          )
        );
        break;
      case 'numsai':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.sai_numsai.localeCompare(b.sai_numsai)
          )
        );
        break;
      case 'numapa':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.sai_numapa.localeCompare(b.sai_numapa)
          )
        );
        break;
      case 'sitsai':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.sai_sitsai.localeCompare(b.sai_sitsai)
          )
        );
        break;
      case 'nomhos':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier *
              a.sai_nomhos.trim().localeCompare(b.sai_nomhos.trim())
          )
        );
        break;
      case 'datent':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.sai_datent.localeCompare(b.sai_datent)
          )
        );
        break;
      case 'datsai':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.sai_datsai.localeCompare(b.sai_datsai)
          )
        );
        break;
      case 'telcel':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier *
              (cleanNumber(a.sai_telcel) - cleanNumber(b.sai_telcel))
          )
        );
        break;
      case 'diaria':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier *
              (parseFloat(a.sai_val1dia) - parseFloat(b.sai_val1dia))
          )
        );
        break;
      default:
        props.setData([...props.data]);
        break;
    }
  };

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.data.length / itemsPerPage));
    setCurrentPage(0);
    setSelectedRow(null);
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <table
      className="table table-striped table-hover table-bordered table-sm align-middle"
      style={{ height: '100%' }}
    >
      <thead>
        <tr className="table-secondary">
          {campos.map((campo) => (
            <BlcTitle
              key={campo.order}
              title={campo.title}
              order={campo.order}
              handleSort={handleSort}
              tamTexto={props.tamTexto}
            />
          ))}
          <th
            scope="col"
            style={{ fontSize: props.tamTexto, width: '80px' }}
            className="grid"
          >
            Visualizar
          </th>
          {localStorage.getItem('sessionNivelDeAcesso') === '16' ||
          localStorage.getItem('sessionNivelDeAcesso') === '22' ||
          localStorage.getItem('sessionNivelDeAcesso') === '50' ||
          localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
            <th
              scope="col"
              style={{ fontSize: props.tamTexto, width: '80px' }}
              className="grid"
            >
              Editar
            </th>
          ) : null}
          {localStorage.getItem('sessionNivelDeAcesso') === '22' ||
          localStorage.getItem('sessionNivelDeAcesso') === '50' ||
          localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
            <th
              scope="col"
              style={{ fontSize: props.tamTexto, width: '80px' }}
              className="grid"
            >
              Deletar
            </th>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item, index) => (
          <tr
            key={index}
            className={selectedRow === index ? 'bg-info' : ''}
            onClick={() => handleRowClick(index)}
          >
            <td scope="row" style={{ fontSize: props.tamSubTexto }}>
              {item.sai_numsai}
            </td>
            <td style={{ fontSize: props.tamSubTexto }}>{item.sai_numres}</td>
            <td style={{ fontSize: props.tamSubTexto }}>{item.sai_nument}</td>
            <td style={{ fontSize: props.tamSubTexto }}>{item.sai_numapa}</td>
            <td style={{ fontSize: props.tamSubTexto }}>{item.sai_sitsai}</td>
            <td className="grid" style={{ fontSize: props.tamSubTexto }}>
              {item.sai_nomhos}
            </td>
            <td style={{ fontSize: props.tamSubTexto }}>
              {item.sai_telcel ? `(${item.sai_precel}) ${item.sai_telcel}` : ''}
            </td>
            <td style={{ fontSize: props.tamSubTexto }}>
              {format(new Date(item.sai_datent), 'dd/MM/yyyy')}
            </td>
            <td style={{ fontSize: props.tamSubTexto }}>
              {item.sai_datsai
                ? format(new Date(item.sai_datsai), 'dd/MM/yyyy')
                : ''}
            </td>
            <td className="text-end" style={{ fontSize: props.tamSubTexto }}>
              {item.sai_val1dia != null
                ? item.sai_val1dia.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                : item.sai_val1dia}
            </td>
            <td>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  style={{ fontSize: props.tamSubTexto }}
                  onClick={() => {
                    props.setViewOrUpdate('view');
                    props.handleEdit(item.sai_numsai);
                  }}
                >
                  <i className="fa-solid fa-eye"></i>
                </button>
              </div>
            </td>
            {localStorage.getItem('sessionNivelDeAcesso') === '16' ||
            localStorage.getItem('sessionNivelDeAcesso') === '22' ||
            localStorage.getItem('sessionNivelDeAcesso') === '50' ||
            localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
              <td>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    style={{ fontSize: props.tamSubTexto }}
                    onClick={() => {
                      props.setInsertOrUpdate('update');
                      props.setViewOrUpdate('update');
                      props.handleEdit(item.sai_numsai);
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                </div>
              </td>
            ) : null}
            {localStorage.getItem('sessionNivelDeAcesso') === '22' ||
            localStorage.getItem('sessionNivelDeAcesso') === '50' ||
            localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
              <td>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    style={{ fontSize: props.tamSubTexto }}
                    onClick={async () => {
                      const id = item.sai_numsai;
                      await props.handleDelete(id);
                    }}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
              </td>
            ) : null}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="13" className="align-middle">
            <nav className="d-flex align-items-center justify-content-center">
              <ul className="h-100 pagination pagination-sm">
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="PreviousPage"
                    onClick={() => handleFirstPage()}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="PreviousIten"
                    onClick={() => handlePageClick(currentPage - 1)}
                  >
                    <span aria-hidden="true">&lt;</span>
                  </button>
                </li>
                {displayedPageNumbers.map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === currentPage ? 'active' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageClick(pageNumber)}
                    >
                      {pageNumber + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="NextIten"
                    onClick={() => handlePageClick(currentPage + 1)}
                  >
                    <span aria-hidden="true">&gt;</span>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="NextPage"
                    onClick={() => handleLastPage()}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </li>
              </ul>
            </nav>
          </td>
        </tr>
        <tr>
          <td colSpan="13" className="align-middle">
            <div className="d-flex gap-2">
              <div className="d-flex flex-column">
                <p
                  className="text-muted ms-2"
                  style={{ fontSize: props.tamTexto }}
                >
                  Nº Saídas: {props.data.length}
                </p>
                <p
                  className="text-muted ms-2"
                  style={{ fontSize: props.tamTexto }}
                >
                  Saídas Faturadas:{' '}
                  {props.data.filter((item) => item.sai_sitsai === 'F').length}
                </p>
                <p
                  className="text-muted ms-2"
                  style={{ fontSize: props.tamTexto }}
                >
                  Saídas Canceladas:{' '}
                  {props.data.filter((item) => item.sai_sitsai === 'C').length}
                </p>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
