import { useEffect, useState } from 'react';

import { ApiGet, ApiGetConsulta } from '../../../services/apiService';

export const Dashboard = (props) => {
  const [url, setUrl] = useState('');
  const [undadm, setUndadm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [comboboxUndadm, setComboboxUndadm] = useState([]);

  const handleCombobox = async () => {
    try {
      const response2 = await ApiGet(
        '/combobox/details',
        { combobox: { table: 'unid_adm' } },
        localStorage.getItem('sessionToken')
      );
      setComboboxUndadm(response2.data.combobox);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDashboard = async (id) => {
    try {
      const response = await ApiGetConsulta(
        `/dashboard_app/${id}`,
        localStorage.getItem('sessionToken')
      );
      setUrl(response.data.dashboard);
      setIsOpen(true);
    } catch (err) {
      console.log(err);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    handleCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ height: '100vh' }}>
      <div className="flex">
        <div className="mb-3">
          <label className="col" style={{ fontSize: props.tamTexto }}>
            Und Adm
          </label>
          <div>
            <select
              className="form-select form-control border border-dark border-opacity-75"
              value={undadm}
              onChange={(e) => setUndadm(e.target.value)}
              style={{ width: '250px' }}
            >
              <option defaultValue></option>
              {comboboxUndadm.map((option, index) => (
                <option key={index} value={option.uad_coduad}>
                  {option.uad_coduad} - {option.uad_nomuad}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          type="button"
          className="mt-2 btn btn-primary"
          onClick={() => handleDashboard(undadm)}
        >
          Dashboard
        </button>
      </div>

      {isOpen && (
        <iframe
          src={url}
          // frameBorder="0"
          width="100%"
          height="100%"
          // allowtransparency
        ></iframe>
      )}
    </div>
  );
};
