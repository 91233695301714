import { NumericFormat } from 'react-number-format';

export const CadConsumo = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Num Con</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Tip Con</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="numeroConsumo"
              value={props.formDataItems?.numeroConsumo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="tipoConsumo"
              value={props.formDataItems?.tipoConsumo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">item</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">situacaoItem</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">descricao Item</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="item"
              value={props.formDataItems?.item || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="situacaoItem"
              value={props.formDataItems?.situacaoItem || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="descricaoItem"
              value={props.formDataItems?.descricaoItem || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">valor</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">valor Item</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <NumericFormat
              value={props.formDataItems.valor || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormDataItems({
                  ...props.formDataItems,
                  valor: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-3">
            <NumericFormat
              value={props.formDataItems.valorItem || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormDataItems({
                  ...props.formDataItems,
                  valorItem: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">data Inclusao</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">hora Inclusao</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="dataInclusao"
              value={props.formDataItems?.dataInclusao?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="horaInclusao"
              value={props.formDataItems?.horaInclusao || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
