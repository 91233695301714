import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function RelReserva(data, relReserva, filtro) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const formattedTime = currentDate.toLocaleTimeString();

  const reportTitle = [
    {
      fontSize: 11,
      bold: true,
      margin: [15, 15, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Empresa: ' + relReserva[0].empresa, alignment: 'left' },
        { text: 'Data: ' + formattedDate, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Loja: ' + relReserva[0].loja, alignment: 'left' },
        { text: `Hora:      ` + formattedTime, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      text: [
        'CNPJ: ' + relReserva[0].cnpj,
        'Endereço: ' + relReserva[0].endereco,
        'Telefone: ' + relReserva[0].telefone
      ].join('\n'),
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0]
    }
  ];

  const maxLineLength = 150;
  let currentLine = '';
  const reportContent = [];

  const filtroDetails = filtro.map((detalhe) => {
    const { campo, conteudo } = detalhe;
    if (campo.includes('Dt Ent')) {
      const [dataIni, dataEnd] = conteudo;
      return `${campo} = ${dataIni.trim()} a ${dataEnd.trim()}`;
    } else {
      return `${campo} = ${conteudo}`;
    }
  });

  filtroDetails.forEach((filtroItem, index) => {
    const filterPrefix = index === 0 ? 'Filtrado por: ' : '';
    const newLine = `${filterPrefix}${currentLine}${filtroItem}; `;
    if (newLine.length <= maxLineLength) {
      currentLine = newLine;
    } else {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
      currentLine = `${filtroItem}; `;
    }
  });

  if (currentLine !== '') {
    if (currentLine.includes('Filtrado por: ')) {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
    } else {
      reportContent.push({
        text: `.                     ${currentLine}`,
        fontSize: 8
      });
    }
  }

  let valorTotal = 0;

  const dados = data.slice(0, 20000).map((opt) => {
    const dataFormatadaDatent = new Date(opt.res_datent).toLocaleDateString(
      'pt-BR'
    );

    valorTotal = valorTotal + opt.res_val1dia;

    return [
      { text: opt.res_numres, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.res_sitres, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.res_numapa, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.res_nomhos, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.res_email, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.res_precel, fontSize: 8, margin: [0, 2, 0, 2] },
      { text: opt.res_telcel, fontSize: 8, margin: [0, 2, 0, 2] },
      {
        text:
          opt.res_val1dia != null
            ? opt.res_val1dia.toLocaleString('pt-BR', {
                minimumFractionDigits: 2
              })
            : opt.res_val1dia,
        fontSize: 8,
        margin: [0, 2, 0, 2],
        alignment: 'right'
      },
      { text: dataFormatadaDatent, fontSize: 8, margin: [0, 2, 0, 2] }
    ];
  });

  const styles = {
    tableHeader: {
      bold: true
    }
  };
  const qtdRegistros = dados.length;

  // const valorTotalStr = valorTotal.toLocaleString('pt-BR', {
  //   style: 'currency',
  //   currency: 'BRL',
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2
  // });

  // const valorTotalWidth = (valorTotal.length + 2) * 6;

  const details = [
    {
      table: {
        headerRows: 1,
        widths: [
          'auto',
          'auto',
          'auto',
          '*',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto'
        ],
        body: [
          [
            { text: 'Num Res', style: 'tableHeader', fontSize: 9 },
            { text: 'Sit Res', style: 'tableHeader', fontSize: 9 },
            { text: 'Num Apa', style: 'tableHeader', fontSize: 9 },
            { text: 'Nome', style: 'tableHeader', fontSize: 9 },
            { text: 'E-mail', style: 'tableHeader', fontSize: 9 },
            { text: 'DDD', style: 'tableHeader', fontSize: 9 },
            { text: 'Telefone', style: 'tableHeader', fontSize: 9 },
            { text: 'Valor', style: 'tableHeader', fontSize: 9 },
            { text: 'Data Ent', style: 'tableHeader', fontSize: 9 }
          ],
          ...dados
        ]
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
        }
      }
    },
    {
      table: {
        headerRows: 0,
        widths: ['*', 'auto'],
        body: [
          [
            {
              text: 'Valor total:',
              alignment: 'right',
              bold: true,
              fontSize: 8
            },
            {
              text: valorTotal?.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            }
          ],
          [
            {
              text: 'Quantidade de registros:',
              alignment: 'right',
              bold: true,
              fontSize: 8
            },
            { text: qtdRegistros, alignment: 'right', fontSize: 8 }
          ]
        ]
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
        paddingTop: function () {
          return 3;
        },
        paddingBottom: function () {
          return 3;
        }
      }
    }
  ];

  function Rodape(currentPage, pageCount) {
    return [
      {
        text: currentPage + ' / ' + pageCount,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0]
      }
    ];
  }

  const docDefinitios = {
    pageSize: 'A4',
    pageMargins: [15, 90, 15, 40],

    header: [reportTitle],
    content: [
      { text: 'Relação de Reserva', style: 'header' },
      reportContent,
      details
    ],
    footer: Rodape,
    styles: styles
  };

  pdfMake.createPdf(docDefinitios).download();
}

export default RelReserva;
