import { useState } from 'react';
import InputMask from 'react-input-mask';

import { format, addDays } from 'date-fns';

export const Search = (props) => {
  const [anoDamov, setAnoDamov] = useState('');
  const [anoDatven, setAnoDatven] = useState('');
  const [anoDatpag, setAnoDatpag] = useState('');

  const months = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' }
  ];

  const handlePeriodClick = (period, option) => {
    const today = new Date();
    let start, end;
    switch (period) {
      case 'esta-semana':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, -1);
        }
        end = addDays(start, 6);
        break;
      case 'semana-passada':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, -1);
        }
        start = addDays(start, -7);
        end = addDays(start, 6);
        break;
      case 'proxima-semana':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, 1);
        }
        end = addDays(start, 6);
        break;
      case 'este-mes':
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case 'mes-passado':
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case 'proximo-mes':
        start = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        end = new Date(today.getFullYear(), today.getMonth() + 2, 0);
        break;
      case 'este-ano':
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date(today.getFullYear(), 11, 31);
        break;
      case 'ano-passado':
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        break;
      case 'proximo-ano':
        start = new Date(today.getFullYear() + 1, 0, 1);
        end = new Date(today.getFullYear() + 1, 11, 31);
        break;
      default:
        start = null;
        end = null;
    }

    if (option === 'damov') {
      props.setDataFiltro({
        ...props.dataFiltro,
        datmovInicial: start ? format(start, 'dd/MM/yyyy') : '',
        datmovFinal: end ? format(end, 'dd/MM/yyyy') : ''
      });
    } else if (option === 'datven') {
      props.setDataFiltro({
        ...props.dataFiltro,
        datvenInicial: start ? format(start, 'dd/MM/yyyy') : '',
        datvenFinal: end ? format(end, 'dd/MM/yyyy') : ''
      });
    } else if (option === 'datpag') {
      props.setDataFiltro({
        ...props.dataFiltro,
        datpagInicial: start ? format(start, 'dd/MM/yyyy') : '',
        datpagFinal: end ? format(end, 'dd/MM/yyyy') : ''
      });
    }
  };

  const calculateDates = (monthValue, option, yearValue) => {
    const month = parseInt(monthValue, 10);
    const year = parseInt(yearValue, 10);

    let lastDayOfMonth;
    if (month === 2) {
      lastDayOfMonth = new Date(year, 2, 0).getDate();
    } else {
      const monthsWith30Days = [4, 6, 9, 11];
      lastDayOfMonth = monthsWith30Days.includes(month) ? 30 : 31;
    }

    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month - 1, lastDayOfMonth);

    if (option === 'damov') {
      props.setDataFiltro({
        ...props.dataFiltro,
        datmovInicial: format(startDate, 'dd/MM/yyyy'),
        datmovFinal: format(endDate, 'dd/MM/yyyy')
      });
    } else if (option === 'datven') {
      props.setDataFiltro({
        ...props.dataFiltro,
        datvenInicial: format(startDate, 'dd/MM/yyyy'),
        datvenFinal: format(endDate, 'dd/MM/yyyy')
      });
    } else if (option === 'datpag') {
      props.setDataFiltro({
        ...props.dataFiltro,
        datpagInicial: format(startDate, 'dd/MM/yyyy'),
        datpagFinal: format(endDate, 'dd/MM/yyyy')
      });
    }
  };

  return (
    <>
      {/* tabela */}
      <div>
        <span className="col-form-label">Sit. Conta</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.tabela || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                tabela: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="A realizar">A realizar</option>
            <option value="Realizada">Realizada</option>
          </select>
        </div>
      </div>

      {/* emp */}
      <div>
        <span className="col-form-label">Empresa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.empresa || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                empresa: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[0]?.map((option, index) => (
              <option key={index} value={option.emp_codemp}>
                {option.emp_codemp} - {option.emp_fantasia}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* loja */}
      <div>
        <span className="col-form-label">Loja</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.loja || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                loja: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[1]?.map((option, index) => (
              <option key={index} value={option.loj_codloj}>
                {option.loj_codloj} - {option.loj_denloj}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* undadm */}
      <div>
        <span className="col-form-label">Unidade Administrativa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.undadm || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                undadm: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[2]
              ?.slice()
              .sort((a, b) =>
                a.nome_unidade_adm.localeCompare(b.nome_unidade_adm)
              )
              .map((option, index) => (
                <option key={index} value={option.codigo_unidade_adm}>
                  {option.codigo_unidade_adm} - {option.nome_unidade_adm}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* Centro de Custo */}
      <div>
        <span className="col-form-label">Centro de Custo</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.cencus || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                cencus: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[3]
              ?.filter((item) => item.tcc_tipo === 'A')
              .sort((a, b) => a.tcc_nomtcc.localeCompare(b.tcc_nomtcc))
              .map((option, index) => (
                <option key={index} value={option.tcc_codtcc}>
                  {option.tcc_nomtcc}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* Fonte de Recurso */}
      <div>
        <span className="col-form-label">Fonte de Recurso</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.fontrec || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                fontrec: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[5]
              ?.filter((item) => item.tfo_tipo === 'A')
              .sort((a, b) => a.tfo_nomfon.localeCompare(b.tfo_nomfon))
              .map((option, index) => (
                <option key={index} value={option.tfo_codfon}>
                  {option.tfo_nomfon}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* Natureza da Despesa */}
      <div>
        <span className="col-form-label">Natureza da Despesa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.natdesp || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                natdesp: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[4]
              ?.filter((item) => item.tnd_tipo === 'A')
              .sort((a, b) => a.tnd_nomnat.localeCompare(b.tnd_nomnat))
              .map((option, index) => (
                <option key={index} value={option.tnd_codnat}>
                  {option.tnd_nomnat}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* Documento de Pagamento */}
      <div>
        <span className="col-form-label">Documento de Pagamento</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.docpag || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                docpag: e.target.value
              })
            }
          />
        </div>
      </div>

      {/* Número da Conta */}
      <div>
        <span className="col-form-label">Número da Conta</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.numcon || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                numcon: e.target.value
              })
            }
          />
        </div>
      </div>

      {/* Código do Cliente */}
      <div>
        <span className="col-form-label">Código do Cliente</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.codpes || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                codpes: e.target.value
              })
            }
          />
        </div>
      </div>

      {/* Nome do Cliente */}
      <div>
        <span className="col-form-label">Nome do Cliente</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.nompes || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                nompes: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Caixa Gerou</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.caxger || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                caxger: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Caixa Liquidou</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.numcax || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                numcax: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* tipcon */}
      <div>
        <span className="col-form-label">Tipo de Conta</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.tipcon || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                tipcon: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="R">R - Receitas</option>
            <option value="P">P - Despesas</option>
          </select>
        </div>
      </div>

      {/* escrit */}
      <div>
        <span className="col-form-label">Escriturado</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.escrit || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                escrit: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="S">S - Escriturado</option>
            <option value="N">N - Não Escriturado</option>
          </select>
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* datmov */}
      <div className="row d-flex">
        <div className="dropdown col-6">
          <button
            className="btn btn-info dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Períodos
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('esta-semana', 'damov')}
              >
                Essa semana
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('semana-passada', 'damov')}
              >
                Semana passada
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proxima-semana', 'damov')}
              >
                Semana que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-mes', 'damov')}
              >
                Esse mês
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('mes-passado', 'damov')}
              >
                Mês passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-mes', 'damov')}
              >
                Mês que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-ano', 'damov')}
              >
                Esse ano
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('ano-passado', 'damov')}
              >
                Ano passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-ano', 'damov')}
              >
                Ano que vem
              </p>
            </li>
          </ul>
        </div>

        <div className="dropdown col-6">
          <button
            className="btn btn-info dropdown-toggle"
            type="button"
            id="dropdownMesesDamov"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Meses/Ano
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMesesDamov">
            <li className="dropdown-item">
              <InputMask
                mask="9999"
                maskChar=""
                type="text"
                className="form-control form-control-sm border border-dark"
                id="datmovIni1"
                placeholder="Ano"
                value={anoDamov}
                onChange={(e) => setAnoDamov(e.target.value)}
              />
            </li>
            {months.map((month) => (
              <li key={month.value}>
                <p
                  className="dropdown-item"
                  onClick={() => calculateDates(month.value, 'damov', anoDamov)}
                >
                  {month.label}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div>
        <span className="col-form-label">Data de Movimento Inicial</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datmovInicial || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datmovInicial: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Data de Movimento Final</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datmovFinal || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datmovFinal: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* datven */}
      <div className="row">
        <div className="dropdown col-6">
          <button
            className="btn btn-info dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Períodos
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('esta-semana', 'datven')}
              >
                Essa semana
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('semana-passada', 'datven')}
              >
                Semana passada
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proxima-semana', 'datven')}
              >
                Semana que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-mes', 'datven')}
              >
                Esse mês
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('mes-passado', 'datven')}
              >
                Mês passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-mes', 'datven')}
              >
                Mês que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-ano', 'datven')}
              >
                Esse ano
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('ano-passado', 'datven')}
              >
                Ano passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-ano', 'datven')}
              >
                Ano que vem
              </p>
            </li>
          </ul>
        </div>

        <div className="dropdown col-6">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMesesDatven"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Meses/Ano
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMesesDatven">
            <li className="dropdown-item">
              <InputMask
                mask="9999"
                maskChar=""
                type="text"
                className="form-control form-control-sm border border-dark"
                id="datvenIni1"
                placeholder="Ano"
                value={anoDatven}
                onChange={(e) => setAnoDatven(e.target.value)}
              />
            </li>
            {months.map((month) => (
              <li key={month.value}>
                <p
                  className="dropdown-item"
                  onClick={() =>
                    calculateDates(month.value, 'datven', anoDatven)
                  }
                >
                  {month.label}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div>
        <span className="col-form-label">Data de Vencimento Inicial</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datvenInicial || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datvenInicial: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Data de Vencimento Final</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datvenFinal || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datvenFinal: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* datpag */}
      <div className="row">
        <div className="dropdown col-6">
          <button
            className="btn btn-info dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Períodos
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('esta-semana', 'datpag')}
              >
                Essa semana
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('semana-passada', 'datpag')}
              >
                Semana passada
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proxima-semana', 'datpag')}
              >
                Semana que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-mes', 'datpag')}
              >
                Esse mês
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('mes-passado', 'datpag')}
              >
                Mês passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-mes', 'datpag')}
              >
                Mês que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-ano', 'datpag')}
              >
                Esse ano
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('ano-passado', 'datpag')}
              >
                Ano passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-ano', 'datpag')}
              >
                Ano que vem
              </p>
            </li>
          </ul>
        </div>

        <div className="dropdown col-6">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMesesDatpag"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Meses/Ano
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMesesDatpag">
            <li className="dropdown-item">
              <InputMask
                mask="9999"
                maskChar=""
                type="text"
                className="form-control form-control-sm border border-dark"
                id="datpagIni1"
                placeholder="Ano"
                value={anoDatpag}
                onChange={(e) => setAnoDatpag(e.target.value)}
              />
            </li>
            {months.map((month) => (
              <li key={month.value}>
                <p
                  className="dropdown-item"
                  onClick={() =>
                    calculateDates(month.value, 'datpag', anoDatpag)
                  }
                >
                  {month.label}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div>
        <span className="col-form-label">Data de Pagamento Inicial</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datpagInicial || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datpagInicial: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Data de Pagamento Final</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datpagFinal || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datpagFinal: e.target.value
              })
            }
          />
        </div>
      </div>

      {/* button */}
      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={props.handleSearch}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
};
