import { ResponsivePie } from '@nivo/pie';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
//recebe um array de objetos com os campos nome e sum
export const ChartPieQt = ({ data, titulo, isMonetary }) => {
  const totalSum = data.reduce((acc, item) => acc + parseFloat(item.sum), 0);
  const threshold = totalSum * 0.01; // 1% do total
  data.sort((a, b) => b.sum - a.sum);

  let otherSum = 0;
  let nivoData = [];

  // Ordena os dados em ordem alfabética de ID para garantir consistência nas cores
  const sortedIds = data.map((d) => d.nome).sort();
  const colorScale = scaleOrdinal(schemeCategory10).domain(sortedIds);

  const formatSum = (sum) => {
    if (isMonetary) {
      return sum.toLocaleString('pt-BR', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL'
      });
    } else {
      return sum.toLocaleString('pt-BR', {
        maximumFractionDigits: 0
      });
    }
  };
  data.forEach((item) => {
    if (item.sum < threshold) {
      otherSum += item.sum;
    } else {
      nivoData.push({ id: item.nome, value: parseFloat(item.sum) });
    }
  });

  // Adiciona o valor de outros
  if (otherSum > 0) {
    nivoData.push({ id: 'Outros', value: otherSum });
  }

  return (
    <div style={{ height: '400px', width: '95%' }}>
      <h4>{titulo}</h4>
      <ResponsivePie
        data={nivoData}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor="#333333"
        radialLabelsLinkColor={{ from: 'color' }}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor="#333333"
        arcLabel={(e) =>
          `${((parseFloat(e.value) / parseFloat(totalSum)) * 100).toFixed(2)}%`
        }
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        colors={({ id }) => colorScale(id)}
        // colors={{ scheme: 'category10' }}
        tooltip={({ datum }) => {
          return (
            <div
              style={{
                padding: '10px',
                color: 'black',
                background: 'white'
              }}
            >
              {datum.id}: {formatSum(datum.value)}
            </div>
          );
        }}
      />
    </div>
  );
};
