import { NumericFormat } from 'react-number-format';

export function CadPagamento(props) {
  const onChangeFormatNumeric = (values, id) => {
    const { formattedValue } = values;

    props.handleInput({
      target: { name: id, value: formattedValue }
    });
  };

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Forma de Pagamento */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Forma de Pagamento</span>
          </div>
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="ent_forpag"
              value={props.formData.ent_forpag || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[5]?.map((option, index) => (
                <option key={index} value={option.fpg_forpag}>
                  {option.fpg_desfpg}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Tipo de Pagamento */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Tipo de Pagamento</span>
          </div>
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="ent_tippag"
              value={props.formData.ent_tippag || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[6]?.map((option, index) => (
                <option key={index} value={option.tpg_tippag}>
                  {option.tpg_destpg}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Vl Pg da Diária  */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Vl Pg da Diária</span>
          </div>
          <div className="col-12">
            <NumericFormat
              value={props.formData.ent_pagdia || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  ent_pagdia: formattedValue,
                  ent_lojdia: '00001'
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Vl Pg de Consumo */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Vl Pg de Consumo</span>
          </div>
          <div className="col-12">
            <NumericFormat
              value={props.formData.ent_pagcon || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  ent_pagcon: formattedValue,
                  ent_lojcon: '00001'
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Valor da Diária */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Valor da Diária</span>
          </div>
          <div className="col-12">
            <NumericFormat
              value={props.formData.ent_val1dia || ''}
              onValueChange={(values) =>
                onChangeFormatNumeric(values, 'ent_val1dia')
              }
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Loc. Pagto Diária */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Loc. Pagto Diária</span>
          </div>
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_lojdia"
              value={props.formData.ent_lojdia || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Loc. Pagto Consumo */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Loc. Pagto Consumo</span>
          </div>
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ent_lojcon"
              value={props.formData.ent_lojcon || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
