export function CadReserva(props) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Nº Reserva e Nº Apto */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Nº Reserva</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Nº Apto</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Cód. Grupo</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_numres"
              value={props.formData?.res_numres || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_numapa"
              value={props.formData?.res_numapa || ''}
              onChange={(e) => {
                const filter = props.options[19].filter(
                  (option) => option.apt_numapa.trim() === e.target.value.trim()
                );

                const filterUndadm = props.options[2].filter(
                  (option) =>
                    option.codigo_unidade_adm === filter[0]?.apt_blcapa
                );

                const filterEmpresa = props.options[0].filter(
                  (option) =>
                    option.emp_codemp === filterUndadm[0]?.codigo_empresa
                );

                const filterLoja = props.options[1].filter(
                  (option) => option.loj_codloj === filterUndadm[0]?.codigo_loja
                );

                props.setFormData({
                  ...props.formData,
                  res_numapa: e.target.value,
                  res_tipapa: filter[0]?.apt_tipapa,
                  res_empresa: filterUndadm[0]?.codigo_empresa,
                  empresa: filterEmpresa[0]?.emp_razsoc,
                  res_loja: filterUndadm[0]?.codigo_loja,
                  loja: filterLoja[0]?.loj_denloj,
                  res_undadm: filterUndadm[0]?.codigo_unidade_adm,
                  unidade_administrativa: filterUndadm[0]?.nome_unidade_adm
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_grupo"
              value={props.formData?.res_grupo}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Cód. Funcionário e Nome Funcionário */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cód. Func.</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Nome Funcionário</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4 d-flex">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_codfun"
              value={props.formData?.res_codfun || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                props.setTipoPessoa('funcionario');
                props.setActiveTab(5);
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <i className="fa-regular fa-file"></i>
            </button>
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_nomfun"
              value={props.formData?.res_nomfun || ''}
              disabled
            />
          </div>
        </div>

        {/* Cód. Hóspede e Nome Hóspede */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cód. Hóspede</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Nome Hóspede</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4 d-flex">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_codhos"
              value={props.formData?.res_codhos || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                props.setTipoPessoa('cliente');
                props.setActiveTab(5);
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <i className="fa-regular fa-file"></i>
            </button>
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_nomhos"
              value={props.formData?.res_nomhos || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  res_codhos: '',
                  res_nomhos: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Cód. Evento e Nome Evento */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cód. Evento</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Nome Evento</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4 d-flex">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_codevn"
              value={props.formData?.res_codevn || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                props.setTipoPessoa('evento');
                props.setActiveTab(5);
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <i className="fa-regular fa-file"></i>
            </button>
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_evento"
              value={props.formData?.res_evento || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
        </div>

        {/* Cód. Empresa e Nome Empresa */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cód. Empresa</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Nome Empresa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4 d-flex">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_codemp"
              value={props.formData?.res_codemp || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                props.setTipoPessoa('empresa');
                props.setActiveTab(5);
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <i className="fa-regular fa-file"></i>
            </button>
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_nomemp"
              value={props.formData?.res_nomemp || ''}
              disabled
            />
          </div>
        </div>

        {/* Cód. Filial e Nome Filial */}
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cód. Filial</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Nome Filial</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4 d-flex">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_codfil"
              value={props.formData?.res_codfil || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                props.setTipoPessoa('filial');
                props.setActiveTab(5);
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <i className="fa-regular fa-file"></i>
            </button>
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="nome_filial"
              value={props.formData?.nome_filial || ''}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Tipo Apto */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Tipo Apto</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_tipapa"
              value={props.formData?.res_tipapa || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[17]?.map((option, index) => (
                <option key={index} value={option.tipo_apartamento}>
                  {option.descricao_apartamento}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Tipo de Hóspede */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Tipo de Hóspede</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_tiphos"
              value={props.formData?.res_tiphos || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[16]?.map((option, index) => (
                <option key={index} value={option.tipo_hospede}>
                  {option.descricao_hospede}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Tipo Reserva */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Tipo Reserva</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_tipres"
              value={props.formData?.res_tipres || 'RS'}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option value="RS">RESERVADO</option>
              <option value="BL">BLOQUEADO</option>
              <option value="AL">ALLOIMEN</option>
            </select>
          </div>
        </div>

        {/* Tipo da Diária */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Tipo da Diária</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_tipdia"
              value={props.formData?.res_tipdia || 'DAL'}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[18]
                ?.filter((option) => option.diaria_consumo[0] === 'D')
                .map((option, index) => (
                  <option key={index} value={option.diaria_consumo}>
                    {option.descricao}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {/* Tipo do Consumo */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Tipo do Consumo</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_tipcons"
              value={props.formData?.res_tipcons || 'COL'}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[18]
                ?.filter((option) => option.diaria_consumo[0] === 'C')
                .map((option, index) => (
                  <option key={index} value={option.diaria_consumo}>
                    {option.descricao}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {/* Nº Adultos e Adultos Lives e Nº Crianças e Crianças Livres */}
        <div className="col-12 row">
          <div className="col-2">
            <span className="col-form-label">Nº Adul.</span>
          </div>
          <div className="col-2">
            <span className="col-form-label">Adul. Liv.</span>
          </div>
          <div className="col-2">
            <span className="col-form-label">Nº Cri.</span>
          </div>
          <div className="col-2">
            <span className="col-form-label">Cri. Liv.</span>
          </div>
          <div className="col-2">
            <span className="col-form-label">Nº Pets</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_numocu"
              value={props.formData?.res_numocu || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_oculiv"
              value={props.formData?.res_oculiv || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_numcri"
              value={props.formData?.res_numcri || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_criliv"
              value={props.formData?.res_criliv || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_numpet"
              value={props.formData?.res_numpet || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Data Entrada e Hora Entrada */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Data Entrada</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Hora Entrada</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="res_datent"
              value={props.formData?.res_datent?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_horent"
              value={props.formData?.res_horent || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Data Saída e Hora Saída */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Data Saída</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Hora Saída</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="res_datsai"
              value={props.formData?.res_datsai?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_horsai"
              value={props.formData?.res_horsai || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Data Limite e Hora Limite */}
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Data Limite</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Hora Limite</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="res_datlimite"
              value={props.formData?.res_datlimite?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_horlimite"
              value={props.formData?.res_horlimite || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
