import { useEffect, useState } from 'react';

import { Card } from '../../components/comp-dashboards/data-cards/Card';
import { Menu } from '../../components/comp-dashboards/menu/Menu';
import { MetabaseDashboard } from '../../components/comp-dashboards/metabase-dashboards/MatabaseDashboard';
import { ReactDashboard } from '../../components/comp-dashboards/react-dashboards/ReactDashboard';

import { menu_estoque, menu_financeiro, menu_venda } from './const';
import { DataCards, handleDashboard } from './functions';

export const Dashboard = (props) => {
  const [dataVenda, setDataVenda] = useState({});
  const [dataFinanceiro, setDataFinanceiro] = useState({});
  const [typeDashboard, setTypeDashboard] = useState('react');
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [selectedIdDashboardReact, setSelectedIdDashboardReact] =
    useState(null);

  const listaVenda = menu_venda(props.dataEmpresa.area);
  const listaFinanceiro = menu_financeiro(props.dataEmpresa.area);

  useEffect(() => {
    const handleData = async () => {
      const dataVenda = await DataCards(
        props.dataEmpresa.area,
        props.dataEmpresa.nivel,
        props.datPeriodDashboardIni,
        props.datPeriodDashboardEnd,
        'venda'
      );

      const dataFinanceiro = await DataCards(
        props.dataEmpresa.area,
        props.dataEmpresa.nivel,
        props.datPeriodDashboardIni,
        props.datPeriodDashboardEnd,
        'financeiro'
      );

      setDataVenda({
        label1: dataVenda.card[0]?.venda1[0] + ':',
        value1: dataVenda.card[0]?.venda1[1].toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      });

      setDataFinanceiro({
        label1: dataFinanceiro.card[0]?.financeiro1[0] + ':',
        value1: dataFinanceiro.card[0]?.financeiro1[1].toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }),
        label2: dataFinanceiro.card[0]?.financeiro2[0] + ':',
        value2: dataFinanceiro.card[0]?.financeiro2[1].toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }),
        label3: dataFinanceiro.card[0]?.financeiro3[0] + ':',
        value3: dataFinanceiro.card[0]?.financeiro3[1].toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      });
    };
    handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datPeriodDashboardIni, props.datPeriodDashboardEnd]);

  return (
    <>
      <div style={{ width: '95vw', marginLeft: '2.5vw' }}>
        <div className="row mt-3">
          {/* VENDA */}
          <div className="col-sm-12 col-md-12 col-lg-4 mb-1">
            <Card
              id={
                localStorage.getItem('sessionToken')
                  ? '#VendaList'
                  : '#ModalError'
              }
              title="Venda"
              icon="fa fa-solid fa-chart-column"
              data={dataVenda}
              bgDashboard={props.bgDashboard1}
              fontDashboard={props.fontDashboard1}
              tamTexto={props.tamTexto}
              tamSubTexto={props.tamSubTexto}
            />
            <Menu
              title="Venda"
              id="VendaList"
              modalId="#Venda"
              lista={listaVenda || []}
              typeDashboard={typeDashboard}
              handleDashboard={handleDashboard}
              setTypeDashboard={setTypeDashboard}
              setSelectedDashboard={setSelectedDashboard}
              setSelectedIdDashboardReact={setSelectedIdDashboardReact}
            />
            {typeDashboard === 'metabase' ? (
              <MetabaseDashboard
                id="Venda"
                title="Venda"
                src={selectedDashboard}
                tamTitulo={props.tamTitulo}
              />
            ) : (
              <ReactDashboard
                id="Venda"
                title="Venda"
                src={selectedDashboard}
                tamTitulo={props.tamTitulo}
                selectedIdDashboardReact={selectedIdDashboardReact}
              />
            )}
          </div>

          {/* ESTOQUE */}
          <div className="col-sm-12 col-md-12 col-lg-4 mb-1">
            <Card
              id={
                localStorage.getItem('sessionToken')
                  ? '#EstoqueList'
                  : '#ModalError'
              }
              title="Estoque"
              icon="fa fa-solid fa-folder-closed"
              data={{}}
              bgDashboard={props.bgDashboard2}
              fontDashboard={props.fontDashboard2}
              tamTexto={props.tamTexto}
              tamSubTexto={props.tamSubTexto}
            />
            <Menu
              title="Estoque"
              id="EstoqueList"
              modalId="#Estoque"
              lista={menu_estoque}
              typeDashboard={typeDashboard}
              handleDashboard={handleDashboard}
              setTypeDashboard={setTypeDashboard}
              setSelectedDashboard={setSelectedDashboard}
              setSelectedIdDashboardReact={setSelectedIdDashboardReact}
            />
            {typeDashboard === 'metabase' ? (
              <MetabaseDashboard
                id="Estoque"
                title="Estoque"
                src={selectedDashboard}
                tamTitulo={props.tamTitulo}
              />
            ) : (
              <ReactDashboard
                id="Estoque"
                title="Estoque"
                src={selectedDashboard}
                tamTitulo={props.tamTitulo}
                selectedIdDashboardReact={selectedIdDashboardReact}
              />
            )}
          </div>

          {/* FINANCEIRO */}
          <div className="col-sm-12 col-md-12 col-lg-4 mb-1">
            <Card
              id={
                localStorage.getItem('sessionToken')
                  ? '#FinanceiroList'
                  : '#ModalError'
              }
              title="Financeiro"
              icon="fa fa-solid fa-coins"
              data={dataFinanceiro}
              bgDashboard={props.bgDashboard3}
              fontDashboard={props.fontDashboard3}
              tamTexto={props.tamTexto}
              tamSubTexto={props.tamSubTexto}
            />
            <Menu
              title="Financeiro"
              id="FinanceiroList"
              modalId="#Financeiro"
              lista={listaFinanceiro || []}
              typeDashboard={typeDashboard}
              handleDashboard={handleDashboard}
              setTypeDashboard={setTypeDashboard}
              setSelectedDashboard={setSelectedDashboard}
              setSelectedIdDashboardReact={setSelectedIdDashboardReact}
            />
            {typeDashboard === 'metabase' ? (
              <MetabaseDashboard
                id="Financeiro"
                title="Financeiro"
                src={selectedDashboard}
                tamTitulo={props.tamTitulo}
              />
            ) : (
              <ReactDashboard
                id="Financeiro"
                title="Financeiro"
                src={selectedDashboard}
                tamTitulo={props.tamTitulo}
                selectedIdDashboardReact={selectedIdDashboardReact}
              />
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="msgError"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className="alert alert-danger alert-dismissible"
                role="alert"
              >
                <div style={{ fontSize: props.tamTexto }}>Não Logado</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
