import { useEffect, useState } from 'react';

import { getStartAndEndDates } from '../../../../functions/getStartAndEndDates';
import { ChartBar, ChartLine, ChartPieQt, SheetTable } from '../../graficos';
import { FiltroDropdown } from '../FiltroDropdown';
import { FiltrosDate } from '../FiltrosDate';
import {
  fetchDataBar,
  fetchDataDropdownCliente,
  fetchDataDropdownItem,
  fetchDataLine,
  fetchDataPie,
  fetchDataSheet
} from './functions';
export const AnaliseVenda = () => {
  const [dataLine, setDataLine] = useState([]);
  const [dataPieVL, setDataPieVL] = useState([]);
  const [dataPieQT, setDataPieQT] = useState([]);
  const [dataBar, setDataBar] = useState([]);
  const [dataSheet, setDataSheet] = useState([]);
  const [item, setItem] = useState('');
  const [cliente, setCliente] = useState('');
  const [dataDropdownItem, setDataDropdownItem] = useState([]);
  const [dataDropdownCliente, setDataDropdownCliente] = useState([]);

  const loadDataFilter = getStartAndEndDates(3);

  const [startDate, setStartDate] = useState(loadDataFilter.startDate);
  const [endDate, setEndDate] = useState(loadDataFilter.endDate);
  useEffect(() => {
    const fetch = async () => {
      const response1 = await fetchDataLine(startDate, endDate, item, cliente);
      const response2 = await fetchDataBar(startDate, endDate, item);
      const response3 = await fetchDataPie(startDate, endDate, cliente);
      const response4 = await fetchDataSheet(startDate, endDate);

      const gDataPieVL = response3.data.grafico.map((item) => ({
        nome: item.nome,
        sum: item.valor_total
      }));

      const gDataPieQT = response3.data.grafico.map((item) => ({
        nome: item.nome,
        sum: item.quantidade_total
      }));

      setDataLine(response1.data.grafico);
      setDataBar(response2.data.grafico);
      setDataPieVL(gDataPieVL);
      setDataPieQT(gDataPieQT);
      setDataSheet(response4.data.grafico);
    };
    fetch();
  }, [startDate, endDate, item, cliente]);

  useEffect(() => {
    const fetch = async () => {
      const response1 = await fetchDataDropdownItem();
      const response2 = await fetchDataDropdownCliente();

      setDataDropdownItem(response1.data.grafico);
      setDataDropdownCliente(response2.data.grafico);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-100" style={{ backgroundColor: '#eeeeee' }}>
      <h4>Dashboard Análise Vendas</h4>
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 mb-2 p-1">
        <FiltrosDate
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <FiltroDropdown
          title="ITENS"
          listaItems={dataDropdownItem}
          value={item}
          setValue={setItem}
        />
        <FiltroDropdown
          title="CLIENTES"
          listaItems={dataDropdownCliente}
          value={cliente}
          setValue={setCliente}
        />
      </div>
      <div className="w-100 h-100">
        <div className="d-flex flex-column flex-md-row justify-content-center text-center w-100 h-100">
          <ChartLine data={dataLine} />
          <SheetTable data={dataSheet} />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center text-center w-100 h-100">
          <ChartPieQt
            data={dataPieVL}
            titulo={'Gráfico Valor Total'}
            isMonetary={true}
          />
          <ChartPieQt
            data={dataPieQT}
            titulo={'Gráfico Quantidade'}
            isMonetary={false}
          />
          <ChartBar data={dataBar} />
        </div>
      </div>
    </div>
  );
};
