import { ComprasTransferencias } from './estoque/ComprasTransferencias';
// import { AnaliseCentroCusto } from './financeiro/AnaliseCentroCusto';
import { AnaliseContas } from './financeiro/AnaliseContas';
import { AnaliseReceitaDespesa } from './financeiro/AnaliseReceitaDespesa';
import { CentroCusto } from './financeiro/CentroCusto';
import { DashboardContabil } from './financeiro/DashboardContabil';
import { ModeloDashboard } from './financeiro/modeloCC';
import { AnaliseVenda } from './venda/AnaliseVenda';
import { AnaliseVendaHotel } from './venda/AnaliseVendaHotel';

export const ReactDashboard = (props) => {
  return (
    <div
      className="modal fade"
      id={props.id}
      tabIndex="-1"
      aria-labelledby="lblDashboard"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ height: '2vh', backgroundColor: '#eeeeee' }}
          >
            <h1
              className="modal-title"
              id="lblDashboard"
              style={{ fontSize: props.tamTitulo }}
            >
              {props.title}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body" style={{ padding: 0, margin: 0 }}>
            <div style={{ backgroundColor: '#eeeeee' }}>
              {/* Venda */}
              {props.selectedIdDashboardReact === 'EST_VENDA' && (
                <AnaliseVenda />
              )}

              {props.selectedIdDashboardReact === 'EST_VENDA_HOTEL' && (
                <AnaliseVendaHotel />
              )}

              {/* Estoque */}
              {props.selectedIdDashboardReact === 'EST_COMPRA' && (
                <ComprasTransferencias />
              )}

              {/* Financeiro */}
              {props.selectedIdDashboardReact === 'FIN_NDFR' && (
                <AnaliseReceitaDespesa />
              )}
              {props.selectedIdDashboardReact === 'FIN_CTCUST' && (
                <CentroCusto />
              )}
              {props.selectedIdDashboardReact === 'CON_GERAL' && (
                <AnaliseContas />
              )}
              {props.selectedIdDashboardReact === 'FIN_REC_HOTEL' && (
                <ModeloDashboard />
              )}
              {props.selectedIdDashboardReact === 'FIN_CONTABIL' && (
                <DashboardContabil />
              )}
              {/* {props.selectedIdDashboardReact === 'FIN_CC_RD' && (
                <AnaliseCentroCusto />
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
