import { ResponsiveBar } from '@nivo/bar';

export const ChartBar = ({ data }) => {
  const sortedData = [...data].sort((a, b) => a.sumvalitem - b.sumvalitem);

  const formatSum = (sum) => {
    return sum.toLocaleString('pt-BR', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL'
    });
  };

  const chartData = sortedData.map((item) => ({
    cliente: item.cliente.substring(0, 20),
    sumvalitem: item.sumvalitem
  }));

  return (
    <div style={{ height: '400px', width: '95%' }}>
      <h4>Top 10 Clientes</h4>
      {sortedData.length === 0 && <p>No data</p>}
      <ResponsiveBar
        data={chartData}
        keys={['sumvalitem']}
        indexBy="cliente"
        margin={{ top: 0, right: 20, bottom: 80, left: 110 }}
        padding={0.2}
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 2,
          tickRotation: -45,
          legend: 'Valor Total',
          legendPosition: 'middle',
          legendOffset: 40
        }}
        valueFormat={(value) => `${formatSum(value)}`}
        // pointLabel="yFormatted"
        tooltip={({ value, data }) => (
          <div
            style={{
              padding: '10px',
              color: 'black',
              background: 'white'
            }}
          >
            {data.cliente}: {formatSum(value)}
          </div>
        )}
        // axisLeft={{
        //   tickSize: 5,
        //   tickPadding: 5,
        //   tickRotation: 0,
        //   legend: 'cliente',
        //   legendPosition: 'middle',
        //   legendOffset: -40
        // }}
        labelSkipWidth={80} // original é 12, 80 faz com que não apareça quando a barra está pequena
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};
