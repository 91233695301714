import { useEffect, useState } from 'react';

import { NewApiGet } from '../../../services/apiService';
import { CadDados } from './CadDados';
import { CadEndereco } from './CadEndereco';
import { CadOutros } from './CadOutros';
import { CadTelefone } from './CadTelefone';
import { CadTipoPessoa } from './CadTipoPessoa';

export function Cadastro(props) {
  const [activeTab, setActiveTab] = useState(1);

  const handleInput = (event) => {
    const { name, value } = event.target;
    props.setFormData({ ...props.formData, [name]: value });
  };

  const handleEndereco = () => {
    const filterPais = props.optionsPais.filter(
      (option) => option.tps_despais === props.formData?.pes_paisres
    );

    const filterEstado = props.optionsEstado.filter(
      (option) => option.tuf_coduf === props.formData?.pes_uf
    );

    props.setFormData({
      ...props.formData,
      pes_paisres: filterPais[0]?.tps_despais,
      id_pais: filterPais[0]?.tps_id_pais,
      pes_uf: filterEstado[0]?.tuf_coduf,
      id_estado: filterEstado[0]?.tuf_id
    });
  };

  const handleCep = async (event) => {
    const id = event.target.value;
    const id_format = id.replace(/\D/g, '');

    try {
      const response = await NewApiGet(
        `/local/cep/${id_format}`,
        localStorage.getItem('sessionToken')
      );
      const cep = response.data.cep[0];

      props.setFormData({
        ...props.formData,
        id_estado: cep?.ces_iduf || props.formData?.id_estado,
        pes_uf: cep?.ces_coduf || props.formData?.pes_uf,
        pes_cidade: cep?.ces_cidadeibge || props.formData?.pes_cidade,
        pes_bairro: cep?.ces_bairro ? cep.ces_bairro : '',
        pes_endere: cep?.ces_endereco ? cep.ces_endereco : ''
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props.activeTab === 2) {
      handleEndereco();
    }
  }, [props.activeTab]);

  return (
    <>
      <div className="nav nav-tabs">
        <p
          className={`nav-link ${
            activeTab === 1 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(1)}
          style={{ fontSize: props.tamTexto }}
        >
          Dados
        </p>
        <p
          className={`nav-link ${
            activeTab === 2 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(2)}
          style={{ fontSize: props.tamTexto }}
        >
          Endereço
        </p>
        <p
          className={`nav-link ${
            activeTab === 3 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(3)}
          style={{ fontSize: props.tamTexto }}
        >
          Telefone
        </p>
        <p
          className={`nav-link ${
            activeTab === 4 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(4)}
          style={{ fontSize: props.tamTexto }}
        >
          Outros
        </p>
        <p
          className={`nav-link ${
            activeTab === 5 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(5)}
          style={{ fontSize: props.tamTexto }}
        >
          Tipo de Pessoa
        </p>
      </div>

      <div className="tab-content">
        <div
          className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
          id="tabCadastro1"
        >
          <CadDados
            handleInput={handleInput}
            formData={props.formData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
          id="tabCadastro2"
        >
          <CadEndereco
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
            optionsPais={props.optionsPais}
            optionsEstado={props.optionsEstado}
            optionsCidade={props.optionsCidade}
            handleCep={handleCep}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
          id="tabCadastro3"
        >
          <CadTelefone
            handleInput={handleInput}
            formData={props.formData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}
          id="tabCadastro4"
        >
          <CadOutros
            handleInput={handleInput}
            formData={props.formData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 5 ? 'active' : ''}`}
          id="tabCadastro5"
        >
          <CadTipoPessoa
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
            optionsTippes={props.optionsTippes}
          />
        </div>
      </div>
    </>
  );
}
