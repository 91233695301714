import { useState } from 'react';

import { CadFNRH } from './CadFNRH';
import { CadGeral } from './CadGeral';
import { CadHospede } from './CadHospede';
import { CadObs } from './CadObs';
import { CadPagamento } from './CadPagamento';
import { CadReserva } from './CadReserva';

export function Cadastro(props) {
  const [activeTab, setActiveTab] = useState(1);

  const handleInput = (event) => {
    const { name, value } = event.target;
    props.setFormData({ ...props.formData, [name]: value });
  };

  return (
    <div>
      <div className="nav nav-tabs">
        <p
          className={`nav-link ${
            activeTab === 1 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(1)}
          style={{ fontSize: props.tamTexto }}
        >
          Reserva
        </p>
        <p
          className={`nav-link ${
            activeTab === 2 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(2)}
          style={{ fontSize: props.tamTexto }}
        >
          Hóspede
        </p>
        <p
          className={`nav-link ${
            activeTab === 3 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(3)}
          style={{ fontSize: props.tamTexto }}
        >
          Observação
        </p>
        <p
          className={`nav-link ${
            activeTab === 4 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(4)}
          style={{ fontSize: props.tamTexto }}
        >
          Pagamento
        </p>
        <p
          className={`nav-link ${
            activeTab === 5 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(5)}
          style={{ fontSize: props.tamTexto }}
        >
          FNRH
        </p>
        <p
          className={`nav-link ${
            activeTab === 6 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(6)}
          style={{ fontSize: props.tamTexto }}
        >
          Geral
        </p>
      </div>

      <div className="tab-content">
        <div
          className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
          id="tabCadastro1"
        >
          <CadReserva
            formData={props.formData}
            setFormData={props.setFormData}
            handleInput={handleInput}
            options={props.options}
            viewOrUpdate={props.viewOrUpdate}
            setTipoPessoa={props.setTipoPessoa}
            setActiveTab={props.setActiveTab}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
          id="tabCadastro2"
        >
          <CadHospede
            formData={props.formData}
            handleInput={handleInput}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
          id="tabCadastro3"
        >
          <CadObs
            formData={props.formData}
            setFormData={props.setFormData}
            handleInput={handleInput}
            options={props.options}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}
          id="tabCadastro4"
        >
          <CadPagamento
            formData={props.formData}
            setFormData={props.setFormData}
            handleInput={handleInput}
            options={props.options}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 5 ? 'active' : ''}`}
          id="tabCadastro5"
        >
          <CadFNRH
            formData={props.formData}
            setFormData={props.setFormData}
            handleInput={handleInput}
            options={props.options}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 6 ? 'active' : ''}`}
          id="tabCadastro6"
        >
          <CadGeral
            formData={props.formData}
            handleInput={handleInput}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
      </div>
    </div>
  );
}
