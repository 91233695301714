import { useEffect, useState } from 'react';

import { ChartPieQt, TabelaQuatroGrupos } from '../../graficos';
import { FiltroDropdown } from '../FiltroDropdown';
import { FiltrosDate } from '../FiltrosDate';
import {
  fetchDataTabelaEstoque,
  fetchDataGraficoPizzaEstoque,
  fetchDataDropdownUnidAdm
} from './functions';

export const ComprasTransferencias = () => {
  function getStartAndEndDates() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const sdate = firstDay.toISOString().slice(0, 10);
    const edate = lastDay.toISOString().slice(0, 10);

    return { sdate, edate };
  }

  const { sdate, edate } = getStartAndEndDates();
  const [startDate, setStartDate] = useState(sdate);
  const [endDate, setEndDate] = useState(edate);
  const [unidadeAdm, setUnidadeAdm] = useState([]);
  const [dataDropdown, setDataDropdown] = useState([]);

  const [dataGraficoC, setDataGraficoC] = useState([]);
  const [dataGraficoT, setDataGraficoT] = useState([]);
  const [dataTableC, setDataTableC] = useState([]);
  const [dataTableT, setDataTableT] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const unidadeAdmValues = unidadeAdm.map((item) => {
        const foundItem = dataDropdown.administrativo.find(
          (adminItem) => adminItem.item === item.value
        );
        return foundItem ? foundItem.codigo : null;
      });
      const response1 = await fetchDataGraficoPizzaEstoque(
        startDate,
        endDate,
        unidadeAdmValues,
        'C'
      );
      const response2 = await fetchDataGraficoPizzaEstoque(
        startDate,
        endDate,
        unidadeAdmValues,
        'T'
      );
      const response3 = await fetchDataTabelaEstoque(
        startDate,
        endDate,
        unidadeAdmValues,
        'C'
      );
      const response4 = await fetchDataTabelaEstoque(
        startDate,
        endDate,
        unidadeAdmValues,
        'T'
      );

      setDataGraficoC(response1.data?.grafico || []);
      setDataGraficoT(response2.data?.grafico || []);
      setDataTableC(response3.data?.grafico || []);
      setDataTableT(response4.data?.grafico || []);
    };
    fetch();
  }, [startDate, endDate, unidadeAdm]);

  useEffect(() => {
    const fetch = async () => {
      const response5 = await fetchDataDropdownUnidAdm();
      setDataDropdown(response5.data.grafico);
    };
    fetch();
  }, []);
  return (
    <div className="w-100" style={{ backgroundColor: '#eeeeee' }}>
      <h4>Dashboard Análise Compras e Transferências</h4>
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 mb-2 p-1">
        <FiltrosDate
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <FiltroDropdown
          title="Unidade Adm"
          value={unidadeAdm}
          setValue={setUnidadeAdm}
          listaItems={dataDropdown.administrativo}
        />
      </div>
      <div className="w-100 h-100">
        <div className="d-flex flex-column flex-md-row justify-content-center text-center w-100 h-100">
          <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100 gap-3">
            <TabelaQuatroGrupos
              data={dataTableC}
              headers={['Descrição', 'ID', 'Unidade', 'UADM', 'QTD']}
              title={'Compras'}
              isHeight={true}
            />
            <TabelaQuatroGrupos
              data={dataTableT}
              headers={['Descrição', 'ID', 'Unidade', 'UADM', 'QTD']}
              title={'Transferências'}
              isHeight={true}
            />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100 gap-3">
            <ChartPieQt data={dataGraficoC} titulo="Compras" />
            <ChartPieQt data={dataGraficoT} titulo="Transferências" />
          </div>
        </div>
      </div>
    </div>
  );
};
