import { ResponsiveLine } from '@nivo/line';

export const GraficoLinhaRDporPeriodo = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data</p>;
  }

  const despesaData = data.filter((item) => item.tipo_conta === 'Despesa');
  const receitaData = data.filter((item) => item.tipo_conta === 'Receita');

  // Sort data by date
  const sortedDespesaData = [...despesaData].sort(
    (a, b) => new Date(a.cnt_datpag) - new Date(b.cnt_datpag)
  );
  const sortedReceitaData = [...receitaData].sort(
    (a, b) => new Date(a.cnt_datpag) - new Date(b.cnt_datpag)
  );

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short' };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };
  const formatNumber = (num) => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(num);
  };
  const dataInfo = [
    {
      id: 'Despesa',
      color: 'lightcoral',
      data: sortedDespesaData.map((item) => ({
        x: formatDate(item.periodo),
        y: item.sum
      }))
    },
    {
      id: 'Receita',
      color: 'lightskyblue',
      data: sortedReceitaData.map((item) => ({
        x: formatDate(item.periodo),
        y: item.sum
      }))
    }
  ];

  return (
    <div style={{ height: '400px', width: '95%' }}>
      <h4 style={{ backgroundColor: 'gray', color: 'white' }}>
        Despesa x Receita por Período
      </h4>
      <ResponsiveLine
        data={dataInfo}
        margin={{ top: 25, right: 85, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Periodo',
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Valor Total',
          legendOffset: -55,
          legendPosition: 'middle'
        }}
        colors={(d) => d.color}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        enablePointLabel={true}
        pointLabelYOffset={-12}
        lineWidth={3}
        useMesh={true}
        yFormat={(value) => `R$${formatNumber(value)}`}
        pointLabel="yFormatted"
        // tooltip={({ point }) => {
        //   return (
        //     <div
        //       style={{ padding: '10px', color: 'black', background: 'white' }}
        //     >
        //       {point.id.slice(0, -2)}: R${formatNumber(point.data.y)}
        //     </div>
        //   );
        // }}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </div>
  );
};
