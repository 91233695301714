import { useEffect, useState } from 'react';

import { ApiGet, ApiPut, NewApiPost } from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { SearchCadastro } from './cadastro/SearchCadastro';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';

import { addDays, format, subDays } from 'date-fns';
import Swal from 'sweetalert2';

export function EventosRecep(props) {
  const date = new Date();
  const initialDate = subDays(date, 1);
  const finalDate = addDays(date, 1);

  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [formData, setFormData] = useState([]);
  const [title, setTitle] = useState('');
  const [dataFiltro, setDataFiltro] = useState({
    datentInicial: format(initialDate, 'dd/MM/yyyy'),
    datentFinal: format(finalDate, 'dd/MM/yyyy')
  });
  const [dataFiltroCadastro, setDataFiltroCadastro] = useState({
    sitres: 'N'
  });

  const handleChavent = async (
    numres,
    seqenc,
    nompes,
    codevn,
    status,
    chave
  ) => {
    if (status === 'AUSENTE' || status === 'CONCLUIDO') {
      Swal.fire({
        title: 'Esse hóspede não está presente',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Fechar',
        allowOutsideClick: false
      });
      return;
    }

    if (chave == 'SIM') {
      Swal.fire({
        title: 'Corrigir',
        html: 'Deseja desfazer a entrega da chave?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await ApiPut(
            '/v1/evento/entregarchave',
            {
              numero_reserva: numres,
              chave: 'nao',
              sequencial: seqenc,
              usuario: localStorage.getItem('sessionUsuario')
            },
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) != '100')
            alert(response.data?.mensagem?.msg);
          if (response.data?.evento === '100') {
            Swal.fire({
              title: 'Chave Recebida',
              icon: 'success',
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            handleEdit(codevn);
          }
        }
      });
    } else {
      Swal.fire({
        title: 'Confirmação',
        html: `Confirmar entrega de chave para:<br>${nompes}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await ApiPut(
            '/v1/evento/entregarchave',
            {
              numero_reserva: numres,
              chave: 'sim',
              sequencial: seqenc,
              usuario: localStorage.getItem('sessionUsuario')
            },
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) != '100')
            alert(response.data?.mensagem?.msg);
          if (response.data?.evento === '100') {
            Swal.fire({
              title: 'Chave Entregue',
              icon: 'success',
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            handleEdit(codevn);
          }
        }
      });
    }
  };

  const handleEdit = async (codevn, evento) => {
    const evncod = codevn ? codevn.padStart(10, '0') : '';
    try {
      const response = await ApiGet(
        '/v1/evento/hospede',
        { evento: { res_codevn: evncod } },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      setDataFiltroCadastro({
        ...dataFiltroCadastro,
        codevn: evncod
      });
      setFormData(response.data.evento);
      setTitle(evento);
      setActiveTab(2);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearchCadastro = async () => {
    const tamNumres = dataFiltroCadastro.numres?.padStart(10, '0') || '';
    const tamNumapa = dataFiltroCadastro.numapa?.padStart(5, ' ') || '';
    const nome = dataFiltroCadastro.nome?.toUpperCase() || '';

    const body = {
      enh_nomctt: nome || '',
      enh_numres: tamNumres || '',
      res_numapa: tamNumapa || '',
      res_sitres: dataFiltroCadastro.sitres || '',
      res_codevn: dataFiltroCadastro.codevn || '',
      enh_checkin: dataFiltroCadastro.checkin || '',
      enh_chaveent: dataFiltroCadastro.chaveent || '',
      entrada: dataFiltroCadastro.ocupado || ''
    };

    try {
      const response = await ApiGet(
        '/v1/evento/hospede',
        { evento: body },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      setFormData(response.data.evento);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = async () => {
    const tamCodevn = dataFiltro.codevn?.padStart(10, '0') || '';
    const nome = dataFiltro.evento?.toUpperCase() || '';
    let data = [];

    const isDataInicial = dataFiltro.datentInicial !== '';
    const isDataFinal = dataFiltro.datentFinal !== '';

    if (!isDataInicial || !isDataFinal) {
      alert('Preencher as duas datas');
      return;
    } else {
      data = [dataFiltro.datentInicial, dataFiltro.datentFinal];
    }

    const body = {
      codevn: tamCodevn || '',
      evento: nome || '',
      datini: data || ''
    };

    try {
      const response = await ApiGet(
        '/v1/evento',
        { evento: body },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      const sortedData = response.data.evento.sort(
        (a, b) => new Date(a.evn_datini) - new Date(b.evn_datini)
      );

      setData(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  const EntradaSim = (numres, seqenc, nome) => {
    Swal.fire({
      title: 'Confirmação',
      html: `Confirmar entrada do hóspede:<br>${nome}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await ApiPut(
          '/v1/evento/status',
          {
            numero_reserva: numres,
            sequencial: seqenc,
            status: 'presente',
            usuario: localStorage.getItem('sessionUsuario')
          },
          localStorage.getItem('sessionToken')
        );
        if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
          Swal.fire({
            title: 'Hóspede Presente',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Fechar',
            allowOutsideClick: false
          });
          handleSearch();
        } else {
          alert(response.data.mensagem.msg);
        }
      }
    });
  };

  const EntradaNao = (numres, seqenc) => {
    Swal.fire({
      title: 'Confirmação',
      html: `É para dar entrada em todos os hóspedes da acomodação que já fizeram check-in online`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false
    }).then(async (result) => {
      const response = await NewApiPost(
        '/reserva/v1/entrada',
        {
          numres: numres,
          seqenc: seqenc,
          usuario: localStorage.getItem('sessionUsuario')
        },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
        if (result.isConfirmed) {
          const response = await ApiPut(
            '/v1/evento/status',
            {
              numero_reserva: numres,
              sequencial: 0,
              status: 'presente',
              usuario: localStorage.getItem('sessionUsuario')
            }, // TODO: Todos os hospedes
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
            Swal.fire({
              title: 'Todos os hóspedes presentes',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            handleSearch();
          } else {
            alert(response.data.mensagem.msg);
          }
        } else {
          const response = await ApiPut(
            '/v1/evento/status',
            {
              numero_reserva: numres,
              sequencial: seqenc,
              status: 'presente',
              usuario: localStorage.getItem('sessionUsuario')
            }, // TODO: muda apenas um hospedes
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
            Swal.fire({
              title: 'Hóspedes presente',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            handleSearch();
          } else {
            alert(response.data.mensagem.msg);
          }
        }
      } else {
        alert(response.data.mensagem.msg);
      }
    });
  };

  const handleEntrada = (entrada, numres, seqenc, nome, status) => {
    if (status === 'PRESENTE') {
      Swal.fire({
        title: 'Esse hóspede já está presente',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Fechar',
        allowOutsideClick: false
      });
      return;
    }

    if (entrada === 'SIM') {
      EntradaSim(numres, seqenc, nome);
    } else {
      EntradaNao(numres, seqenc);
    }
  };

  const handleSaida = (numres, seqenc, chave, status) => {
    if (status === 'CONCLUIDO') {
      Swal.fire({
        title: 'Hóspede já devolveu a chave',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Fechar',
        allowOutsideClick: false
      });
      return;
    }

    if (chave === 'NAO') {
      Swal.fire({
        title: 'Deseja dar baixa nesse hóspede?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await ApiPut(
            '/v1/evento/status',
            {
              numero_reserva: numres,
              sequencial: seqenc,
              status: 'concluido',
              usuario: localStorage.getItem('sessionUsuario')
            }, // TODO: Só um hóspede chegou
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
            Swal.fire({
              title: 'Dado baixa no hóspede',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            handleSearch();
          } else {
            alert(response.data.mensagem.msg);
          }
        }
      });
    } else {
      Swal.fire({
        title: 'Devolução da Chave',
        html: `É para dar baixa em todos os hóspedes da acomodação?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await ApiPut(
            '/v1/evento/status',
            {
              numero_reserva: numres,
              sequencial: 0,
              status: 'concluido',
              usuario: localStorage.getItem('sessionUsuario')
            }, // TODO: Todos os hospedes
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
            Swal.fire({
              title: 'Dado baixa em todos os hóspedes',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            handleSearch();
          } else {
            alert(response.data.mensagem.msg);
          }
        } else {
          const response = await ApiPut(
            '/v1/evento/status',
            {
              numero_reserva: numres,
              sequencial: seqenc,
              status: 'concluido',
              usuario: localStorage.getItem('sessionUsuario')
            }, // TODO: Só um hóspede chegou
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) === '100') {
            Swal.fire({
              title: 'Dado baixa no hóspede',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            handleSearch();
          } else {
            alert(response.data.mensagem.msg);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-Eventos') {
      handleSearch();
    }
  }, [activeTab, props.isOpen]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Eventos"
      tabIndex="-1"
      aria-labelledby="lblEventos"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblEventos"
              style={{ fontSize: props.tamTitulo }}
            >
              Eventos
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-Eventos' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasPedVenda"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  {activeTab === 1 ? (
                    <Search
                      dataFiltro={dataFiltro}
                      setDataFiltro={setDataFiltro}
                      handleSearch={handleSearch}
                    />
                  ) : (
                    <SearchCadastro
                      dataFiltroCadastro={dataFiltroCadastro}
                      setDataFiltroCadastro={setDataFiltroCadastro}
                      handleSearch={handleSearchCadastro}
                    />
                  )}
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Navegação</p>
                <p style={{ display: 'none' }}>Lista de Reservas</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    handleEdit={handleEdit}
                  />
                </div>

                <div
                  className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                  id="tab2"
                >
                  <Cadastro
                    title={title}
                    formData={formData}
                    setFormData={setFormData}
                    handleSaida={handleSaida}
                    handleEntrada={handleEntrada}
                    handleChavent={handleChavent}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    dataEmpresa={props.dataEmpresa}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            <div
              className={`
                ${
                  activeTab !== 1 &&
                  'w-100 d-flex flex-column flex-md-row justify-content-between align-items-center'
                }`}
            >
              {activeTab === 2 && (
                <div className="row mt-1 mb-1">
                  <div className="col-12 text-end d-flex justify-content-end">
                    <div className="d-flex">
                      <p
                        className="bg-success-subtle"
                        style={{ width: '1.25rem', borderRadius: '5px' }}
                      ></p>
                      <p style={{ fontSize: '0.75rem' }}>Apto Ocupado</p>
                    </div>

                    <div className="d-flex ms-2">
                      <p
                        className="bg-warning-subtle"
                        style={{ width: '1.25rem', borderRadius: '5px' }}
                      ></p>
                      <p style={{ fontSize: '0.75rem' }}>CkOnline SIM</p>
                    </div>

                    <div className="d-flex ms-2">
                      <p
                        className="bg-danger-subtle"
                        style={{ width: '1.25rem', borderRadius: '5px' }}
                      ></p>
                      <p style={{ fontSize: '0.75rem' }}>CkOnline NÃO</p>
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex gap-2">
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasPedVenda"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>

                {activeTab === 2 && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setActiveTab(1)}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
