import { CadPessoa } from './CadPessoa';

export const Pessoa = (props) => {
  return (
    <CadPessoa
      formData={props.formData}
      setFormData={props.setFormData}
      tipoPessoa={props.tipoPessoa}
      setActiveTab={props.setActiveTab}
    />
  );
};
