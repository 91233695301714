import { ApiGetConsulta } from '../../../services/apiService';
import { Grid } from './Grid';

export function CtrNavegacao(props) {
  const handleEdit = async (numres) => {
    try {
      const response = await ApiGetConsulta(
        `/reserva/registro/${numres}`,
        localStorage.getItem('sessionToken')
      );
      props.setFormData(response.data.reserva[0]);
      props.setDataPagamento({
        res_val1dia: response.data.reserva[0].res_val1dia,
        res_pagcon: response.data.reserva[0].res_pagcon,
        res_pagdia: response.data.reserva[0].res_pagdia,
        res_tippag: response.data.reserva[0].res_tippag,
        res_forpag: response.data.reserva[0].res_forpag
      });
      props.setActiveTab(3);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    alert('Em atualização');
  };

  return (
    <Grid
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      data={props.data}
      setData={props.setData}
      tamTexto={props.tamTexto}
      tamSubTexto={props.tamSubTexto}
      setInsertOrUpdate={props.setInsertOrUpdate}
      setViewOrUpdate={props.setViewOrUpdate}
      dataEmpresa={props.dataEmpresa}
    />
  );
}
