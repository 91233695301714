import React from 'react';

export const TabelaDinamica = ({ title, headers, data, sumColumns = [] }) => {
  const formatValue = (value, type) => {
    let numValue = parseFloat(value);
    switch (type) {
      case 'monetary':
        if (!isNaN(numValue)) {
          return new Intl.NumberFormat('pt-BR', {
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(numValue);
        }
        break;
      case 'percentage':
        if (!isNaN(numValue)) {
          return `${numValue.toFixed(2)}%`;
        }
        break;
      case 'integer':
        if (!isNaN(numValue)) {
          return new Intl.NumberFormat('pt-BR', {
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(numValue);
        }
        break;
      case 'any':
      default:
        return value.toString();
    }
    return value.toString();
  };

  // Calculate sums for specified columns
  const sums = headers.reduce((acc, header) => {
    if (sumColumns && sumColumns.includes(header.value)) {
      acc[header.value] = data.reduce((sum, item) => {
        const value = parseFloat(item[header.value]);
        return sum + (isNaN(value) ? 0 : value);
      }, 0);
    }
    return acc;
  }, {});

  // Function to handle complex sumColumns instructions
  const calculateColumnValue = (instruction, index) => {
    if (instruction === '') return '';
    if (!isNaN(instruction)) return instruction; // Direct number
    if (!instruction.includes(' ') && sums[instruction] !== undefined) {
      return formatValue(
        sums[instruction],
        headers.find((h) => h.value === instruction)?.type
      );
    }

    // Handle calculations
    if (instruction.includes(' ')) {
      const parts = instruction.split(' ');
      if (parts.length === 3) {
        // Simple binary operations
        const [operand1, operator, operand2] = parts;
        const value1 = parseFloat(sums[operand1] || 0);
        const value2 = parseFloat(sums[operand2] || 0);
        switch (operator) {
          case '+':
            return formatValue(value1 + value2, 'monetary');
          case '-':
            return formatValue(value1 - value2, 'monetary');
          case '*':
            return formatValue(value1 * value2, 'monetary');
          case '/':
            return value2 !== 0
              ? formatValue(value1 / value2, 'monetary')
              : 'N/A';
          default:
            return 'Invalid Operation';
        }
      }
    }

    // Default to displaying the instruction as is if no other conditions met
    return instruction;
  };

  return (
    <div style={{ width: '95%', height: '40vh' }}>
      <h4 style={{ backgroundColor: 'grey', color: 'white', padding: '10px' }}>
        {title}
      </h4>
      <div style={{ height: 'calc(100% - 60px)', overflowY: 'auto' }}>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.label}
                  style={{ textAlign: header.align || 'left' }}
                >
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                {headers.map((header) => {
                  const value = item[header.value];
                  return (
                    <td
                      key={header.label}
                      style={{ textAlign: header.align || 'left' }}
                    >
                      {value ? formatValue(value, header.type) : ''}
                    </td>
                  );
                })}
              </tr>
            ))}
            <tr>
              {headers.map((header, index) => (
                <td
                  key={header.label}
                  style={{
                    textAlign: header.align || 'left',
                    fontWeight: 'bold'
                  }}
                >
                  {sumColumns.length > 0
                    ? calculateColumnValue(sumColumns[index] || '', index)
                    : ''}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
