export function getStartAndEndDates(months) {
  const date = new Date();
  const endDate = new Date(date.getFullYear(), date.getMonth(), 0); // Ultimo dia do mes anterior

  const startDate = new Date(date.getFullYear(), date.getMonth() - months, 1); // Primeiro dia do mes x meses atras

  const formattedStartDate = startDate.toISOString().slice(0, 10);
  const formattedEndDate = endDate.toISOString().slice(0, 10);

  return { startDate: formattedStartDate, endDate: formattedEndDate };
}
