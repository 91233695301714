import { NumericFormat } from 'react-number-format';

export const CadPagamento = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">For Pag</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Desc For Pag</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_forpag"
              value={props.formData?.cnt_forpag || ''}
              onChange={(e) => {
                const filter = props.options[6]?.filter(
                  (option) => option.fpg_forpag === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cnt_forpag: e.target.value,
                  fpg_desfpg: filter[0]?.fpg_desfpg
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[6]?.map((option, index) => (
                <option key={index} value={option.fpg_forpag}>
                  {option.fpg_forpag === props.formData?.cnt_forpag
                    ? option.fpg_forpag
                    : option.fpg_desfpg}
                </option>
              ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="fpg_desfpg"
              value={props.formData?.fpg_desfpg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Tip Pag</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Desc Tip Pag</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_tippag"
              value={props.formData?.cnt_tippag || ''}
              onChange={(e) => {
                const filter = props.options[7]?.filter(
                  (option) => option.tpg_tippag === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cnt_tippag: e.target.value,
                  tpg_destpg: filter[0]?.tpg_destpg
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[7]?.map((option, index) => (
                <option key={index} value={option.tpg_tippag}>
                  {option.tpg_tippag === props.formData?.cnt_tippag
                    ? option.tpg_tippag
                    : option.tpg_destpg}
                </option>
              ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="tpg_destpg"
              value={props.formData?.tpg_destpg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">C Custo</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Desc Centro Custo</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_cencus"
              value={props.formData?.cnt_cencus || ''}
              onChange={(e) => {
                const filter = props.options[3]?.filter(
                  (option) => option.tcc_codtcc === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cnt_cencus: e.target.value,
                  cnt_descct: filter[0]?.tcc_nomtcc
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[3]
                ?.filter((item) => item.tcc_tipo === 'A')
                .sort((a, b) => a.tcc_nomtcc.localeCompare(b.tcc_nomtcc))
                .map((option, index) => (
                  <option key={index} value={option.tcc_codtcc}>
                    {option.tcc_codtcc === props.formData?.cnt_cencus
                      ? option.tcc_codtcc
                      : option.tcc_nomtcc}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_descct"
              value={props.formData?.cnt_descct || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Font Rec</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Desc Fonte Recursos</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_recurs"
              value={props.formData?.cnt_recurs || ''}
              onChange={(e) => {
                const filter = props.options[5]?.filter(
                  (option) => option.tfo_codfon === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cnt_recurs: e.target.value,
                  tfo_nomfon: filter[0]?.tfo_nomfon
                });
              }}
              disabled={
                props.viewOrUpdate === 'view'
                  ? true
                  : props.formData.cnt_tipcon === 'P'
                  ? true
                  : false
              }
            >
              <option defaultValue></option>
              {props.options[5]
                ?.filter((item) => item.tfo_tipo === 'A')
                .sort((a, b) => a.tfo_nomfon.localeCompare(b.tfo_nomfon))
                .map((option, index) => (
                  <option key={index} value={option.tfo_codfon}>
                    {option.tfo_codfon === props.formData?.cnt_recurs
                      ? option.tfo_codfon
                      : option.tfo_nomfon}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="tfo_nomfon"
              value={props.formData?.tfo_nomfon || ''}
              onChange={props.handleInput}
              disabled={
                props.viewOrUpdate === 'view'
                  ? true
                  : props.formData.cnt_tipcon === 'P'
                  ? true
                  : false
              }
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Nat Desp</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Desc Nat Despesa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_natdesp"
              value={props.formData?.cnt_natdesp || ''}
              onChange={(e) => {
                const filter = props.options[4]?.filter(
                  (option) => option.tnd_codnat === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cnt_natdesp: e.target.value,
                  tnd_nomnat: filter[0]?.tnd_nomnat
                });
              }}
              disabled={
                props.viewOrUpdate === 'view'
                  ? true
                  : props.formData.cnt_tipcon === 'R'
                  ? true
                  : false
              }
            >
              <option defaultValue></option>
              {props.options[4]
                ?.filter((item) => item.tnd_tipo === 'A')
                .sort((a, b) => a.tnd_nomnat.localeCompare(b.tnd_nomnat))
                .map((option, index) => (
                  <option key={index} value={option.tnd_codnat}>
                    {option.tnd_codnat === props.formData?.cnt_natdesp
                      ? option.tnd_codnat
                      : option.tnd_nomnat}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="tnd_nomnat"
              value={props.formData?.tnd_nomnat || ''}
              onChange={props.handleInput}
              disabled={
                props.viewOrUpdate === 'view'
                  ? true
                  : props.formData.cnt_tipcon === 'R'
                  ? true
                  : false
              }
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cod Cont</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Desc Cod Contábil</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_codcont"
              value={props.formData?.cnt_codcont || ''}
              onChange={(e) => {
                const filter = props.options[10]?.filter(
                  (option) => option.tpc_codpcn === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cnt_codcont: e.target.value,
                  tpc_descri: filter[0]?.tpc_descri
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[10]
                ?.filter((item) => item.tpc_tipcnt === 'A')
                .sort((a, b) => a.tpc_descri.localeCompare(b.tpc_descri))
                .map((option, index) => (
                  <option key={index} value={option.tpc_codpcn}>
                    {option.tpc_codpcn === props.formData?.cnt_codcont
                      ? option.tpc_codpcn
                      : option.tpc_descri}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="tpc_descri"
              value={props.formData?.tpc_descri || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cod Banco</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Banco</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_codban"
              value={props.formData?.cnt_codban || ''}
              onChange={(e) => {
                const filter = props.options[9]?.filter(
                  (option) => option.ban_codban === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cnt_codban: e.target.value,
                  ban_sglban: filter[0]?.ban_sglban
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[9]?.map((option, index) => (
                <option key={index} value={option.ban_codban}>
                  {option.ban_codban === props.formData?.cnt_codban
                    ? option.ban_codban
                    : option.ban_sglban}
                </option>
              ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ban_sglban"
              value={props.formData?.ban_sglban || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">C. Projeto</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Projeto</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_codprj"
              value={props.formData?.cnt_codprj || ''}
              onChange={(e) => {
                const filter = props.options[8]?.filter(
                  (option) => option.tpj_codprj === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cnt_codprj: e.target.value,
                  tpj_descri: filter[0]?.tpj_descri
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[8]?.map((option, index) => (
                <option key={index} value={option.tpj_codprj}>
                  {option.tpj_codprj === props.formData?.cnt_codprj
                    ? option.tpj_codprj
                    : option.tpj_descri}
                </option>
              ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="tpj_descri"
              value={props.formData?.tpj_descri || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Val Dps</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Multa</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Juros</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <NumericFormat
              value={props.formData.cnt_valdps || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  cnt_valdps: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-4">
            <NumericFormat
              value={props.formData.cnt_multa || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  cnt_multa: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-4">
            <NumericFormat
              value={props.formData.cnt_juros || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  cnt_juros: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Val Pag</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Desconto</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Acréscimo</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <NumericFormat
              value={props.formData.cnt_valpag || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  cnt_valpag: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-4">
            <NumericFormat
              value={props.formData.cnt_descon || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  cnt_descon: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-4">
            <NumericFormat
              value={props.formData.cnt_despes || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  cnt_despes: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Caixa Gerou</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Caixa Liquidou</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_caxger"
              value={props.formData?.cnt_caxger || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_numcax"
              value={props.formData?.cnt_numcax || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Doc Ori</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Doc Pag</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_numdoc"
              value={props.formData?.cnt_numdoc || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_docpag"
              value={props.formData?.cnt_docpag || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Dat Mov</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Dat Venc</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Dat Pag</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="cnt_damov"
              value={props.formData?.cnt_damov?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="cnt_datven"
              value={props.formData?.cnt_datven?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              disabled
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Dat Atz</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Dat Incl</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="cnt_datatz"
              value={props.formData?.cnt_datatz?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="cnt_datincl"
              value={props.formData?.cnt_datincl?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Usuario</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_usuario"
              value={props.formData?.cnt_usuario || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};
