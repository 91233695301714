import { useEffect, useState } from 'react';

import {
  ApiGet,
  ApiPut,
  NewApiGet,
  NewApiPost
} from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';

export function Pessoa(props) {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [dataFiltro, setDataFiltro] = useState({});
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');

  const [optionsTippes, setOptionsTippes] = useState([]);
  const [optionsPais, setOptionsPais] = useState([]);
  const [optionsEstado, setOptionsEstado] = useState([]);
  const [optionsCidade, setOptionsCidade] = useState([]);

  const handleSearch = async () => {
    let datnas = null;

    const conditionDatnasInicial =
      dataFiltro.datnasInicial !== '' &&
      dataFiltro.datnasInicial !== null &&
      dataFiltro.datnasInicial !== undefined;
    const conditionDatnasFinal =
      dataFiltro.datnasFinal !== '' &&
      dataFiltro.datnasFinal !== null &&
      dataFiltro.datnasFinal !== undefined;

    if (conditionDatnasInicial && conditionDatnasFinal) {
      datnas = [dataFiltro.datnasInicial, dataFiltro.datnasFinal];
    }

    const body = {
      id: dataFiltro.tipoPessoa || '',
      codigoPessoa: dataFiltro.codpes || '',
      cpf: dataFiltro.cpf || '',
      rg: '',
      nome: dataFiltro.nome || '',
      dataNascimento: datnas,
      cnpj: dataFiltro.cgc || ''
    };

    try {
      const response = await ApiGet(
        '/pessoa/tipo',
        body,
        localStorage.getItem('sessionToken')
      );
      setData(response.data.pessoa);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    const body = {
      // pes_codpes,
      pes_nome: formData.pes_nome,
      pes_email: formData.pes_email,
      pes_sexo: formData.pes_sexo,
      pes_profis: formData.pes_profis,
      pes_nacion: formData.pes_nacion,
      pes_rg: formData.pes_rg,
      pes_cpf: formData.pes_cpf,
      pes_cgc: formData.pes_cgc,
      pes_npassprt: formData.pes_npassprt,
      pes_certnasc: formData.pes_certnasc,
      pes_orgexp: formData.pes_orgexp,

      pes_paisres: formData.pes_paisres,
      pes_cep: formData.pes_cep,
      pes_uf: formData.pes_uf,
      pes_cidade: formData.pes_cidade,
      pes_bairro: formData.pes_bairro,
      pes_endere: formData.pes_endere,
      pes_comple: formData.pes_comple,

      pes_precel: formData.pes_precel,
      pes_telcel: formData.pes_telcel,
      pes_preres: formData.pes_preres,
      pes_telres: formData.pes_telres,
      pes_precom: formData.pes_precom,
      pes_telcom: formData.pes_telcom,

      pes_sitpes: formData.pes_sitpes,
      pes_usuario: formData.pes_usuario,
      pes_observ: formData.pes_observ,
      pes_codcateg: formData.pes_codcateg,
      pes_tipativ: formData.pes_tipativ,
      pes_datalt: formData.pes_datalt,
      pes_horalt: formData.pes_horalt
    };

    const bodyPessoaTipo = {
      tpt_codpes: formData.pes_codpes,
      tpt_codtpes: formData.pessoaTipo
    };

    let codpes = null;
    let response = null;

    try {
      if (insertOrUpdate === 'insert') {
        response = await NewApiPost(
          '/pessoa',
          body,
          localStorage.getItem('sessionToken')
        );
      } else {
        response = await ApiPut(
          `/pessoa/${formData.pes_codpes}`,
          body,
          localStorage.getItem('sessionToken')
        );
      }

      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      if (insertOrUpdate === 'insert') {
        alert('Cadastro realizado com sucesso');
        codpes = response.data.pessoa;
      } else {
        alert('Atualização realizada com sucesso');
      }
    } catch (error) {
      console.log(error);
    }

    if (
      Array.isArray(bodyPessoaTipo.tpt_codtpes) &&
      bodyPessoaTipo.tpt_codtpes?.length > 0
    ) {
      await Promise.all(
        bodyPessoaTipo.tpt_codtpes.map(async (option) => {
          try {
            const response = await NewApiPost(
              '/pessoa/tipo',
              {
                codigoPessoa: formData.pes_codpes || codpes,
                codigoTipo: option
              },
              localStorage.getItem('sessionToken')
            );
          } catch (error) {
            console.log(error);
          }
        })
      );
    }

    handleReset();
  };

  const handleReset = () => {
    setFormData({});
    setActiveTab(1);
  };

  const handleCombobox = async () => {
    try {
      const response1 = await NewApiGet(
        '/tabela/tipoPessoa',
        localStorage.getItem('sessionToken')
      );
      setOptionsTippes(response1.data.tabela);

      const response2 = await NewApiGet(
        '/tabela/pais',
        localStorage.getItem('sessionToken')
      );
      setOptionsPais(response2.data.tabela);

      const response3 = await NewApiGet(
        '/tabela/estado',
        localStorage.getItem('sessionToken')
      );
      setOptionsEstado(response3.data.tabela);

      const response4 = await NewApiGet(
        '/tabela/cidade',
        localStorage.getItem('sessionToken')
      );
      setOptionsCidade(response4.data.tabela);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleCombobox();
  }, []);

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-Pessoa') {
      handleSearch();
    }
  }, [activeTab, props.isOpen]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Pessoa"
      tabIndex="-1"
      aria-labelledby="lblPessoa"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblPessoa"
              style={{ fontSize: props.tamTitulo }}
            >
              Pessoa
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-Pessoa' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasReserva"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    optionsTippes={optionsTippes}
                    handleSearch={handleSearch}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Navegação</p>
                <p style={{ display: 'none' }}>Cadastro</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                    handleSearch={handleSearch}
                  />
                </div>

                <div
                  className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                  id="tab2"
                >
                  <Cadastro
                    activeTab={activeTab}
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    tamTexto={props.tamTexto}
                    optionsTippes={optionsTippes}
                    optionsPais={optionsPais}
                    optionsEstado={optionsEstado}
                    optionsCidade={optionsCidade}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {activeTab === 1 && (
              <>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasReserva"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setInsertOrUpdate('insert');
                    setViewOrUpdate('update');
                    setActiveTab(2);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </>
            )}

            {activeTab === 2 && (
              <>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleReset}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </button>

                {viewOrUpdate !== 'view' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
