import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function RelContasCompleto(data, relContas, filtro) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const formattedTime = currentDate.toLocaleTimeString();

  const reportTitle = [
    {
      fontSize: 11,
      bold: true,
      margin: [15, 15, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Empresa: ' + relContas[0].empresa, alignment: 'left' },
        { text: 'Data: ' + formattedDate, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0],
      alignment: 'left',
      columns: [
        { text: 'Loja: ' + relContas[0].loja, alignment: 'left' },
        { text: `Hora:      ` + formattedTime, alignment: 'right', fontSize: 8 }
      ]
    },
    {
      text: [
        'CNPJ: ' + relContas[0].cnpj,
        'Endereço: ' + relContas[0].endereco,
        'Telefone: ' + relContas[0].telefone
      ].join('\n'),
      fontSize: 11,
      bold: true,
      margin: [15, 0, 15, 0]
    }
  ];

  const maxLineLength = 150;
  let currentLine = '';
  const reportContent = [];

  const filtroDetails = filtro.map((detalhe) => {
    const { campo, conteudo } = detalhe;
    if (campo.includes('Dt Mov')) {
      const [dataIni, dataEnd] = conteudo;
      return `${campo} = ${dataIni.trim()} a ${dataEnd.trim()}`;
    } else if (campo.includes('Dt Ven')) {
      const [dataIni, dataEnd] = conteudo;
      return `${campo} = ${dataIni.trim()} a ${dataEnd.trim()}`;
    } else {
      return `${campo} = ${conteudo}`;
    }
  });

  filtroDetails.forEach((filtroItem, index) => {
    const filterPrefix = index === 0 ? 'Filtrado por: ' : '';
    const newLine = `${filterPrefix}${currentLine}${filtroItem}; `;
    if (newLine.length <= maxLineLength) {
      currentLine = newLine;
    } else {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
      currentLine = `${filtroItem}; `;
    }
  });

  if (currentLine !== '') {
    if (currentLine.includes('Filtrado por: ')) {
      reportContent.push({
        text: currentLine,
        fontSize: 8
      });
    } else {
      reportContent.push({
        text: `.                     ${currentLine}`,
        fontSize: 8
      });
    }
  }

  let receitaAR = 0;
  let despesaAR = 0;

  const dados = data.map((opt) => {
    const dateDamov = new Date(opt.cnt_damov).toLocaleDateString('pt-BR');
    const dateDatven = new Date(opt.cnt_datven).toLocaleDateString('pt-BR');
    const dateDatpag = new Date(opt.cnt_datpag).toLocaleDateString('pt-BR');
    const valdps = opt.cnt_valdps.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    if (opt.cnt_tipcon === 'R') receitaAR = receitaAR + opt.cnt_valdps;

    if (opt.cnt_tipcon === 'P') despesaAR = despesaAR + opt.cnt_valdps;

    return [
      {
        text: opt.cnt_codemp ? `${opt.cnt_codemp} - ${opt.emp_fantasia}` : '',
        fontSize: 6,
        margin: [0, 0, 0, 0]
      },
      // { text: opt.cnt_codemp, fontSize: 6, margin: [0, 0, 0, 0] },
      // { text: opt.emp_fantasia, fontSize: 6, margin: [0, 0, 0, 0] },
      {
        text: opt.cnt_loja ? `${opt.cnt_loja} - ${opt.loj_denloj}` : '',
        fontSize: 6,
        margin: [0, 0, 0, 0]
      },
      // { text: opt.cnt_loja, fontSize: 6, margin: [0, 0, 0, 0] },
      // { text: opt.loj_denloj, fontSize: 6, margin: [0, 0, 0, 0] },
      {
        text: opt.cnt_natdesp ? `${opt.cnt_natdesp} - ${opt.tnd_nomnat}` : '',
        fontSize: 6,
        margin: [0, 0, 0, 0]
      },
      // { text: opt.cnt_natdesp, fontSize: 6, margin: [0, 0, 0, 0] },
      // { text: opt.tnd_nomnat, fontSize: 6, margin: [0, 0, 0, 0] },
      {
        text: opt.cnt_codcont ? `${opt.cnt_codcont} - ${opt.tpc_descri}` : '',
        fontSize: 6,
        margin: [0, 0, 0, 0]
      },
      // { text: opt.cnt_codcont, fontSize: 6, margin: [0, 0, 0, 0] },
      // { text: opt.tpc_descri, fontSize: 6, margin: [0, 0, 0, 0] },
      {
        text: opt.cnt_cencus ? `${opt.cnt_cencus} - ${opt.tcc_nomtcc}` : '',
        fontSize: 6,
        margin: [0, 0, 0, 0]
      },
      // { text: opt.cnt_cencus, fontSize: 6, margin: [0, 0, 0, 0] },
      // { text: opt.tcc_nomtcc, fontSize: 6, margin: [0, 0, 0, 0] },
      { text: opt.cnt_dscdps, fontSize: 6, margin: [0, 0, 0, 0] },
      {
        text: opt.cnt_codprj ? `${opt.cnt_codprj} - ${opt.projeto}` : '',
        fontSize: 6,
        margin: [0, 0, 0, 0]
      },
      // { text: opt.cnt_codprj, fontSize: 6, margin: [0, 0, 0, 0] },
      // { text: opt.projeto, fontSize: 6, margin: [0, 0, 0, 0] },
      {
        text: opt.cnt_codpes && `${opt.cnt_codpes} - ${opt.cnt_nompes}`,
        fontSize: 6,
        margin: [0, 0, 0, 0]
      },
      // { text: opt.cnt_codpes, fontSize: 6, margin: [0, 0, 0, 0] },
      // { text: opt.cnt_nompes, fontSize: 6, margin: [0, 0, 0, 0] },
      { text: opt.cnt_observ, fontSize: 6, margin: [0, 0, 0, 0] },
      { text: dateDamov, fontSize: 6, margin: [0, 0, 0, 0] },
      { text: dateDatven, fontSize: 6, margin: [0, 0, 0, 0] },
      { text: dateDatpag, fontSize: 6, margin: [0, 0, 0, 0] },
      // { text: '', fontSize: 6, margin: [0, 0, 0, 0] },
      // { text: '', fontSize: 6, margin: [0, 0, 0, 0] },
      { text: valdps, fontSize: 6, margin: [0, 0, 0, 0] }
    ];
  });

  let saldoAR = receitaAR - despesaAR;

  const qtdRegistros = dados.length;

  const styles = {
    tableHeader: {
      bold: true
    }
  };

  const details = [
    {
      table: {
        headerRows: 1,
        widths: [
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto'
        ],
        body: [
          [
            { text: 'Empresa', style: 'tableHeader', fontSize: 8 },
            // { text: 'Cód. empresa', style: 'tableHeader', fontSize: 8 },
            // { text: 'Nome da empresa', style: 'tableHeader', fontSize: 8 },
            { text: 'Loja', style: 'tableHeader', fontSize: 8 },
            // { text: 'Cód Loja', style: 'tableHeader', fontSize: 8 },
            // { text: 'Nome da Loja', style: 'tableHeader', fontSize: 8 },
            { text: 'Nat Despesa', style: 'tableHeader', fontSize: 8 },
            // { text: 'Cód. Nat despesa', style: 'tableHeader', fontSize: 8 },
            // { text: 'Desc. Nat. despesa', style: 'tableHeader', fontSize: 8 },
            { text: 'Ct. Contábil', style: 'tableHeader', fontSize: 8 },
            // { text: 'Cód. Ct. Contábil', style: 'tableHeader', fontSize: 8 },
            // { text: 'Desc. Ct Contábil', style: 'tableHeader', fontSize: 8 },
            { text: 'C. Custo', style: 'tableHeader', fontSize: 8 },
            // {
            //   text: 'Cód. C. Custo (Final)',
            //   style: 'tableHeader',
            //   fontSize: 8
            // },
            // {
            //   text: 'Desc. C. Custo (Final)',
            //   style: 'tableHeader',
            //   fontSize: 8
            // },
            { text: 'Desc Despesa', style: 'tableHeader', fontSize: 8 },
            { text: 'Projeto', style: 'tableHeader', fontSize: 8 },
            // { text: 'Cód. Projeto', style: 'tableHeader', fontSize: 8 },
            // { text: 'Descrição do Projeto', style: 'tableHeader', fontSize: 8 },
            { text: 'Fornecedor', style: 'tableHeader', fontSize: 8 },
            // { text: 'Código Fornecedor', style: 'tableHeader', fontSize: 8 },
            // { text: 'Nome Fornecedor', style: 'tableHeader', fontSize: 8 },
            {
              text: 'Observação',
              style: 'tableHeader',
              fontSize: 8
            },
            { text: 'Dt Movimento', style: 'tableHeader', fontSize: 8 },
            { text: 'Dt Vencimento', style: 'tableHeader', fontSize: 8 },
            { text: 'Dt Pagamento', style: 'tableHeader', fontSize: 8 },
            // { text: 'Mês', style: 'tableHeader', fontSize: 8 },
            // { text: 'Data', style: 'tableHeader', fontSize: 8 },
            { text: 'Valor', style: 'tableHeader', fontSize: 8 }
          ],
          ...dados
        ]
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
        }
      }
    },
    {
      table: {
        headerRows: 0,
        widths: ['auto', 'auto'],
        body: [
          [
            {
              text: ''
            },
            {
              text: 'A realizar',
              alignment: 'right',
              bold: true,
              fontSize: 8
            }
          ],
          [
            { text: 'Receita', bold: true, fontSize: 8 },
            {
              text: receitaAR.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            }
          ],
          [
            { text: 'Despesa', bold: true, fontSize: 8 },
            {
              text: despesaAR.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            }
          ],
          [
            { text: 'Saldo', bold: true, fontSize: 8 },
            {
              text: saldoAR.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              alignment: 'right',
              fontSize: 8
            }
          ],
          [
            { text: 'Quantidade de Registros', bold: true, fontSize: 8 },
            {
              text: qtdRegistros.toLocaleString('pt-BR'),
              alignment: 'right',
              fontSize: 8
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
        paddingTop: function () {
          return 3;
        },
        paddingBottom: function () {
          return 3;
        }
      }
    }
  ];

  function Rodape(currentPage, pageCount) {
    return [
      {
        text: currentPage + ' / ' + pageCount,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0]
      }
    ];
  }

  const docDefinitios = {
    pageSize: 'A4',
    pageMargins: [1, 90, 1, 40],

    header: [reportTitle],
    content: [
      { text: 'Relação de Contas', style: 'header' },
      reportContent,
      details
    ],
    footer: Rodape,
    styles: styles
  };

  pdfMake.createPdf(docDefinitios).download();
}

export default RelContasCompleto;
