import { ApiGet, ApiPost } from '../../services/apiService';

export const handleDashboard = async (id) => {
  try {
    const response = await ApiPost(
      '/dashboard',
      id,
      localStorage.getItem('sessionToken')
    );
    return response.data.dashboard[0]?.url;
  } catch (err) {
    console.log(err);
  }
};

export const DataCards = async (area, nivel, datini, datend, id) => {
  const cardInfo = {
    area: area,
    nivel: nivel,
    datini: datini,
    datend: datend
  };

  try {
    const response = await ApiGet(
      `/card/${id}/`,
      { [id]: cardInfo },
      localStorage.getItem('sessionToken')
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
