import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../services/i18n/i18n';
import Banco from './Banco';

function Config(props) {
  const { t } = useTranslation();

  const [newBgNavbar, setNewBgNavbar] = useState(props.bgNavbar);
  const [newFontNavbar, setNewFontNavbar] = useState(props.fontNavbar);
  const [newBgDashboard1, setNewBgDashboard1] = useState(props.bgDashboard1);
  const [newFontDashboard1, setNewFontDashboard1] = useState(
    props.fontDashboard1
  );
  const [newBgDashboard2, setNewBgDashboard2] = useState(props.bgDashboard2);
  const [newFontDashboard2, setNewFontDashboard2] = useState(
    props.fontDashboard2
  );
  const [newBgDashboard3, setNewBgDashboard3] = useState(props.bgDashboard3);
  const [newFontDashboard3, setNewFontDashboard3] = useState(
    props.fontDashboard3
  );
  const [newBgMenu, setNewBgMenu] = useState(props.bgMenu);
  const [newFontMenu, setNewFontMenu] = useState(props.fontMenu);

  useEffect(() => {
    setNewBgNavbar(props.bgNavbar);
    setNewFontNavbar(props.fontNavbar);
    setNewBgDashboard1(props.bgDashboard1);
    setNewFontDashboard1(props.fontDashboard1);
    setNewBgDashboard2(props.bgDashboard2);
    setNewFontDashboard2(props.fontDashboard2);
    setNewBgDashboard3(props.bgDashboard3);
    setNewFontDashboard3(props.fontDashboard3);
    setNewBgMenu(props.bgMenu);
    setNewFontMenu(props.fontMenu);
  }, [
    props.bgNavbar,
    props.fontNavbar,
    props.bgDashboard1,
    props.fontDashboard1,
    props.bgDashboard2,
    props.fontDashboard2,
    props.bgDashboard3,
    props.fontDashboard3,
    props.bgMenu,
    props.fontMenu
  ]);

  const [msgBanco, setMsgBanco] = useState(false);

  const config = {
    bgNavbar: newBgNavbar,
    fontNavbar: newFontNavbar,
    bgDashboard1: newBgDashboard1,
    fontDashboard1: newFontDashboard1,
    bgDashboard2: newBgDashboard2,
    fontDashboard2: newFontDashboard2,
    bgDashboard3: newBgDashboard3,
    fontDashboard3: newFontDashboard3,
    bgMenu: newBgMenu,
    fontMenu: newFontMenu
  };
  const configString = JSON.stringify(config);

  const saveChanges = () => {
    props.setBgNavbar(newBgNavbar);
    props.setFontNavbar(newFontNavbar);
    props.setBgDashboard1(newBgDashboard1);
    props.setFontDashboard1(newFontDashboard1);
    props.setBgDashboard2(newBgDashboard2);
    props.setFontDashboard2(newFontDashboard2);
    props.setBgDashboard3(newBgDashboard3);
    props.setFontDashboard3(newFontDashboard3);
    props.setBgMenu(newBgMenu);
    props.setFontMenu(newFontMenu);

    localStorage.setItem('config', configString);
  };

  const saveLanguage = (language) => {
    props.setSelectedLanguage(language);
    i18n.changeLanguage(language);
  };

  const blcConfig = (props) => {
    return (
      <div className="form-group d-flex ms-3 mb-1 align-items-center border-bottom row">
        <label
          htmlFor={props.id}
          className="form-label col-md-3 col-sm-12"
          style={{ fontSize: props.tamTexto }}
        >
          {props.title}
        </label>
        <div className="col-4 mt-1">
          <input
            type="color"
            className="p-0 align-self-center form-control-color border border-dark"
            id={props.id}
            style={{ width: '100%' }}
            value={props.value1}
            onChange={(e) => props.value2(e.target.value)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="modal fade"
        id="Config"
        tabIndex="-1"
        aria-labelledby="lblConfig"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title"
                id="lblConfig"
                style={{ fontSize: props.tamTitulo }}
              >
                {t('Configuracao')}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <h5
                className="border-bottom"
                style={{ fontSize: props.tamSubTitulo }}
              >
                {t('Navbar')}
              </h5>

              {blcConfig({
                id: 'bgNavbar',
                title: t('Background'),
                value1: newBgNavbar,
                value2: setNewBgNavbar,
                tamInput: props.tamInput,
                tamTexto: props.tamTexto
              })}

              {blcConfig({
                id: 'fontNavbar',
                title: t('Fonte'),
                value1: newFontNavbar,
                value2: setNewFontNavbar,
                tamInput: props.tamInput,
                tamTexto: props.tamTexto
              })}

              {props.dataEmpresa.nivel.includes('GESTAO') && (
                <>
                  <hr></hr>

                  <h5
                    className="border-bottom"
                    style={{ fontSize: props.tamSubTitulo }}
                  >
                    {t('CardDashboard')}
                  </h5>

                  <h5 className="ms-3">Card 1</h5>
                  {blcConfig({
                    id: 'bgDashboard1',
                    title: t('Background'),
                    value1: newBgDashboard1,
                    value2: setNewBgDashboard1,
                    tamInput: props.tamInput,
                    tamTexto: props.tamTexto
                  })}

                  {blcConfig({
                    id: 'fontDashboard1',
                    title: t('Fonte'),
                    value1: newFontDashboard1,
                    value2: setNewFontDashboard1,
                    tamInput: props.tamInput,
                    tamTexto: props.tamTexto
                  })}

                  <h5 className="ms-3">Card 2</h5>
                  {blcConfig({
                    id: 'bgDashboard2',
                    title: t('Background'),
                    value1: newBgDashboard2,
                    value2: setNewBgDashboard2,
                    tamInput: props.tamInput,
                    tamTexto: props.tamTexto
                  })}

                  {blcConfig({
                    id: 'fontDashboard2',
                    title: t('Fonte'),
                    value1: newFontDashboard2,
                    value2: setNewFontDashboard2,
                    tamInput: props.tamInput,
                    tamTexto: props.tamTexto
                  })}

                  <h5 className="ms-3">Card 3</h5>
                  {blcConfig({
                    id: 'bgDashboard3',
                    title: t('Background'),
                    value1: newBgDashboard3,
                    value2: setNewBgDashboard3,
                    tamInput: props.tamInput,
                    tamTexto: props.tamTexto
                  })}

                  {blcConfig({
                    id: 'fontDashboard3',
                    title: t('Fonte'),
                    value1: newFontDashboard3,
                    value2: setNewFontDashboard3,
                    tamInput: props.tamInput,
                    tamTexto: props.tamTexto
                  })}
                </>
              )}

              <hr></hr>

              <h5
                className="border-bottom"
                style={{ fontSize: props.tamSubTitulo }}
              >
                {t('Menu')}
              </h5>

              {blcConfig({
                id: 'bgMenu',
                title: t('Background'),
                value1: newBgMenu,
                value2: setNewBgMenu,
                tamInput: props.tamInput,
                tamTexto: props.tamTexto
              })}

              {blcConfig({
                id: 'fontMenu',
                title: t('Fonte'),
                value1: newFontMenu,
                value2: setNewFontMenu,
                tamInput: props.tamInput,
                tamTexto: props.tamTexto
              })}

              <hr></hr>

              <h5
                className="border-bottom"
                style={{ fontSize: props.tamSubTitulo }}
              >
                Layout
              </h5>
              <div className="form-group d-flex ms-3 mb-1 align-items-center border-bottom row">
                <label
                  htmlFor="tamInput"
                  className="form-label col-md-3 col-sm-12"
                  style={{ fontSize: props.tamTexto }}
                >
                  Tamanho
                </label>
                <select
                  id="tamInput"
                  className="col ms-2 select border border-dark"
                  value={props.changeState}
                  style={{ height: props.tamInput, fontSize: props.tamTexto }}
                  onChange={(e) => props.Templates(e.target.value)}
                >
                  <option value="pequeno">Pequeno</option>
                  <option value="medio">Médio</option>
                  <option value="grande">Grande</option>
                </select>
              </div>
            </div>

            <div className="modal-footer d-flex justify-content-between">
              <div className="d-flex justify-content-start align-items-center">
                <button
                  type="button"
                  className={`btn button ${
                    props.selectedLanguage === 'ptBR' ? 'active' : ''
                  }`}
                  onClick={() => saveLanguage('ptBR')}
                >
                  <img
                    src="imagens/brasil.png"
                    alt="Logo Brasil"
                    className="img"
                  />
                </button>

                <button
                  type="button"
                  className={`btn button ${
                    props.selectedLanguage === 'en' ? 'active' : ''
                  }`}
                  onClick={() => saveLanguage('en')}
                >
                  <img src="imagens/usa.jpg" alt="Logo USA" className="img" />
                </button>

                <button
                  type="button"
                  className={`btn button ${
                    props.selectedLanguage === 'es' ? 'active' : ''
                  }`}
                  onClick={() => saveLanguage('es')}
                >
                  <img
                    src="imagens/espanha.png"
                    alt="Logo Espanha"
                    className="img"
                  />
                </button>
              </div>

              <div>
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-target="#Banco"
                  data-bs-toggle="modal"
                  // onClick={() => (props.login ? null : setMsgBanco(true))}
                  style={{ fontSize: props.tamTexto }}
                >
                  Ambiente
                </button> */}

                <button
                  type="button"
                  className="btn btn-primary ms-3"
                  onClick={saveChanges}
                  data-bs-dismiss="modal"
                  style={{ fontSize: props.tamTexto }}
                >
                  {t('Salvar')}
                </button>
              </div>

              {msgBanco && (
                <div
                  className="alert alert-danger mt-2 alert-dismissible col-12"
                  role="alert"
                >
                  <div style={{ fontSize: props.tamTexto }}>
                    {t('NaoLogado')}
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setMsgBanco(false)}
                    aria-label="Close"
                  ></button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Banco
        tamInput={props.tamInput}
        tamTitulo={props.tamTitulo}
        tamTexto={props.tamTexto}
        area={props.area}
        setArea={props.setArea}
        campoArea={props.campoArea}
        setCampoArea={props.setCampoArea}
        area_verificar={props.area_verificar}
        abr_area={props.abr_area}
        dataBanco={props.dataBanco}
        setDataBanco={props.setDataBanco}
      />
    </>
  );
}

export default Config;
