import { useState, useEffect } from 'react';

import RelEstoque from '../../../relatorio/RelEstoque';

export const Relatorio = (props) => {
  const [relEstoque, setRelEstoque] = useState([]);
  const [isLoadingBas, setIsLoadingBas] = useState(false);
  const [isLoadingDet, setIsLoadingDet] = useState(false);
  const handleClick = (tipo) => {
    if (tipo === 'bas') setIsLoadingBas(true);
    else setIsLoadingDet(true);

    setTimeout(() => {
      RelEstoque(props.data, relEstoque, props.filtroPesquisa);

      if (tipo === 'bas') setIsLoadingBas(false);
      else setIsLoadingDet(false);
    }, 1000);
  };

  useEffect(() => {
    const item1 = localStorage.getItem('sessionFantasia');
    const item2 = localStorage.getItem('sessionLoja');
    const item3 = localStorage.getItem('sessionCnpj');
    const item4 = localStorage.getItem('sessionEndereco');
    const item5 = localStorage.getItem('sessionTelefone');

    const newData = {
      empresa: item1,
      loja: item2,
      cnpj: item3,
      endereco: item4,
      telefone: item5
    };

    setRelEstoque([...relEstoque, newData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container mt-2">
        <button
          type="button"
          className="btn btn-primary mx-2"
          onClick={() => handleClick('bas')}
          disabled={isLoadingBas}
          style={{ width: '150px' }}
        >
          {isLoadingBas ? (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            'Relatório Básico'
          )}
        </button>
        <button
          type="button"
          className="btn btn-primary mx-2"
          onClick={() => handleClick('det')}
          disabled={isLoadingDet}
          style={{ width: '180px' }}
        >
          {isLoadingDet ? (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            'Relatório Detalhado'
          )}
        </button>
      </div>
    </>
  );
};
