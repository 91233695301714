import { useEffect, useState } from 'react';

import {
  ApiGet,
  ApiPut,
  NewApiGet,
  NewApiPost
} from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { Contas } from './contas/Contas';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';
import { Pessoa } from './pessoa/Pessoa';
import { Relatorio } from './relatorio/Relatorio';

export function ReservaRecep(props) {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [dataContas, setDataContas] = useState({});
  const [dataFiltro, setDataFiltro] = useState({ sitres: 'N' });
  const [activeTab, setActiveTab] = useState(1);
  const [filtroPesquisa, setFiltroPesquisa] = useState([]);
  const [dataPagamento, setDataPagamento] = useState({});
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');
  const [optionsContas, setOptionsContas] = useState([]);
  const [options, setOptions] = useState([]);
  const [tipoPessoa, setTipoPessoa] = useState('');

  const handleSearch = async () => {
    const tamNumres = dataFiltro.numres?.padStart(10, '0') || '';
    const tamNumapa = dataFiltro.numapa?.padStart(5, ' ') || '';
    const tamGrupo = dataFiltro.grupo?.padStart(10, '0') || '';
    const nome = dataFiltro.nomhos?.toUpperCase();

    let datent = null;

    const conditionDatentInicial =
      dataFiltro.datentInicial !== '' &&
      dataFiltro.datentInicial !== null &&
      dataFiltro.datentInicial !== undefined;
    const conditionDatentFinal =
      dataFiltro.datentFinal !== '' &&
      dataFiltro.datentFinal !== null &&
      dataFiltro.datentFinal !== undefined;

    if (conditionDatentInicial && conditionDatentFinal) {
      datent = [dataFiltro.datentInicial, dataFiltro.datentFinal];
    }

    const body = {
      empresa: dataFiltro.empresa || '',
      loja: dataFiltro.loja || '',
      undadm: dataFiltro.undadm || '',
      numres: tamNumres || '',
      sitres: dataFiltro.sitres || '',
      numapa: tamNumapa || '',
      nomhos: nome || '',
      datent: datent || '',
      datsai: '',
      val1dia: '',
      email: '',
      precel: '',
      telcel: '',
      grupo: tamGrupo || '',
      tipapa: dataFiltro.tipapt || '',
      codevn: dataFiltro.evento || '',
      codhos: dataFiltro.tippes || ''
    };

    const conditions = [
      {
        condition: dataFiltro.empresa,
        campo: 'Empresa',
        conteudo: dataFiltro.empresa
      },
      { condition: dataFiltro.loja, campo: 'Loja', conteudo: dataFiltro.loja },
      {
        condition: dataFiltro.undadm,
        campo: 'Und Adm',
        conteudo: dataFiltro.undadm
      },
      { condition: tamNumres, campo: 'Num Res', conteudo: tamNumres },
      {
        condition: dataFiltro.sitres,
        campo: 'Sit Res',
        conteudo: dataFiltro.sitres
      },
      {
        condition: dataFiltro.numapa,
        campo: 'Num Apa',
        conteudo: dataFiltro.numapa
      },
      {
        condition: nome,
        campo: 'Nome',
        conteudo: nome
      },
      {
        condition: dataFiltro.grupo,
        campo: 'Grupo',
        conteudo: dataFiltro.grupo
      },
      { condition: datent, campo: 'Dt Ent', conteudo: datent }
    ];

    let pesquisasDetalhes = [];

    conditions.forEach(({ condition, campo, conteudo }) => {
      if (condition) pesquisasDetalhes.push({ campo, conteudo });
    });

    setFiltroPesquisa(pesquisasDetalhes);

    try {
      const response = await ApiGet(
        '/reserva',
        { reserva: body },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      const sortedData = response.data.reserva.sort(
        (a, b) => new Date(a.res_datent) - new Date(b.res_datent)
      );

      setData(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = () => {
    setFormData({});
    setDataPagamento({});
    setActiveTab(1);
  };

  /**
   * Formata o valor para float
   * @param {string | number} value
   * @returns {number}
   */
  function FormatNumber(value) {
    if (typeof value === 'number') {
      return value;
    }
    return parseFloat(String(value).replace(/\./g, '').replace(',', '.'));
  }

  const handleSave = async () => {
    const dateAtual = new Date();
    const diaAtual = dateAtual.getDate().toString().padStart(2, '0');
    const mesAtual = String(dateAtual.getMonth() + 1).padStart(2, '0');
    const anoAtual = dateAtual.getFullYear().toString();
    const formatDataAtual = `${anoAtual}/${mesAtual}/${diaAtual}`;

    const horas = String(dateAtual.getHours()).padStart(2, '0');
    const minutos = String(dateAtual.getMinutes()).padStart(2, '0');
    const segundos = String(dateAtual.getSeconds()).padStart(2, '0');
    const formatHoraAtual = `${horas}:${minutos}:${segundos}`;

    const body = {
      acompa: formData.res_acompa,
      cafe: formData.res_cafe,
      cambio: formData.res_cambio || '',
      camcas: formData.res_camcas,
      camsol: formData.res_camsol,
      cidprodes: formData.res_cidprodes,
      cidprodesibge: formData.res_cidprodesibge,
      cidultpro: formData.res_cidultpro,
      cidultproibge: formData.res_cidultproibge,
      codcon: formData.cod_convenio /*formData.res_codcon*/,
      codemp: formData.res_codemp,
      codevn: String(formData.res_codevn).padStart(10, '0'),
      codfil: formData.res_codfil,
      codfun: formData.res_codfun,
      codhos: formData.res_codhos,
      codmco: formData.origem_do_contato || '' /*formData.res_codmco*/,
      codusu: formData.res_codusu,
      cofre: formData.res_cofre,
      criliv: formData.res_criliv,
      datatz: formatDataAtual,
      datavi: formData.res_datavi,
      datent: formData.res_datent,
      datincl: formData.res_datincl,
      datlimite: formData.res_datlimite,
      datsai: formData.res_datsai,
      diaaut: formData.res_diaaut,
      dtcnfres: formData.res_dtcnfres || '',
      email: formData.res_email?.toLowerCase(),
      empresa: formData.res_empresa,
      envcheckin: formData.res_envcheckin,
      envcheckout: formData.res_envcheckout,
      escolha: formData.res_escolha || '',
      estprodes: formData.res_estprodes,
      estultpro: formData.res_estultpro,
      evento: formData.res_evento,
      forpag: formData.res_forpag,
      grupo: formData.res_grupo,
      horatz: formatHoraAtual,
      horavi: formData.res_horavi,
      horent: formData.res_horent,
      horincl: formData.res_horincl,
      horlimite: formData.res_horlimite,
      horsai: formData.res_horsai,
      impfichos: formData.res_impfichos || '',
      loja: formData.res_loja,
      lojcon: formData.res_lojcon,
      lojdia: formData.res_lojdia,
      motvia: formData.res_motvia,
      nacion: formData.res_nacion,
      nomcon: formData.nome_convenio /*formData.res_nomcon*/,
      nomemp: formData.res_nomemp,
      nomfun: formData.res_nomfun,
      nomhos: formData.res_nomhos,
      numaca: formData.res_numaca,
      numapa: formData.res_numapa,
      numcri: formData.res_numcri,
      numdoc: formData.res_numdoc,
      numocu: formData.res_numocu,
      numpet: formData.res_numpet,
      numres: formData.res_numres,
      obscre: formData.res_obscre,
      obsdeb: formData.res_obsdeb,
      observ: formData.res_observ,
      obsfnrh: formData.res_obsfnrh,
      obsgov: formData.res_obsgov,
      oculiv: formData.res_oculiv,
      origem: formData.res_origem,
      pagcon: FormatNumber(formData.res_pagcon),
      pagdia: FormatNumber(formData.res_pagdia),
      placar: formData.res_placar,
      pprodes: formData.res_pprodes,
      precel: formData.res_precel,
      precom: formData.res_precom,
      preres: formData.res_preres,
      pultpro: formData.res_pultpro,
      serieac: formData.res_serieac,
      sitres: formData.res_sitres,
      snnum: formData.res_snnum,
      tagchat: formData.tag_crm || '' /*formData.res_tagchat*/,
      telcel: formData.res_telcel,
      telcom: formData.res_telcom,
      telres: formData.res_telres,
      tipapa: formData.res_tipapa,
      tipcons: formData.res_tipcons,
      tipdia: formData.res_tipdia,
      tipdoc: formData.res_tipdoc,
      tiphos: formData.res_tiphos,
      tiphosaux: formData.res_tiphosaux,
      tippag: formData.res_tippag,
      tipqua: formData.res_tipqua,
      tipres: formData.res_tipres,
      tiptran: formData.res_tiptran,
      undadm: formData.res_undadm,
      val1dia: FormatNumber(formData.res_val1dia),
      valcafe: formData.res_valcafe || 0,
      valcre: formData.res_valcre || 0,
      valdeb: formData.res_valdeb || 0
    };

    try {
      if (insertOrUpdate === 'insert') {
        alert('Em atualização');
      } else {
        const response = await ApiPut(
          '/reserva/v1',
          body,
          localStorage.getItem('sessionToken')
        );
        if (response.data.mensagem?.msg.slice(0, 3) !== '100') {
          alert(response.data.mensagem?.msg);
          return;
        }

        if (
          dataPagamento.res_val1dia !== formData.res_val1dia ||
          dataPagamento.res_pagcon !== formData.res_pagcon ||
          dataPagamento.res_pagdia !== formData.res_pagdia ||
          dataPagamento.res_tippag !== formData.res_tippag ||
          dataPagamento.res_forpag !== formData.res_forpag
        ) {
          alert('Reserva atualizada com sucesso');
          await initialValueConta();
          setActiveTab(4);
        } else {
          alert('Reserva atualizada com sucesso');
          handleReset();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveContas = async () => {
    const body = {
      empresa: localStorage.getItem('sessionEmpresa') || '',
      loja: localStorage.getItem('sessionLoja') || '',
      formaPagamento: dataContas?.forpag || '',
      tipoPagamento: dataContas?.tippag || '',
      codigoBanco: dataContas?.banco || '',
      numeroParcela: dataContas?.parcela || '',
      cnt_nompes: dataContas?.nome || '',
      codigoContrato: dataContas?.contrato || '',
      codigoProjeto: dataContas?.projeto || '',
      numeroAprovacao: dataContas?.aprov || '',
      numeroNSU: dataContas?.nsu || '',
      tipoCartao: dataContas?.tipcar || '',
      numeroDocumentoPagamento: dataContas?.docpag || '',
      naturezaDespesa: dataContas?.natdes || '',
      fonteRecurso: dataContas?.fontrec || '',
      dataMovimento: dataContas?.datmov || '',
      dataVencimento: dataContas?.datbas || '',
      cambio: dataContas?.cambio || '',
      numeroReserva: dataContas?.numres
    };

    try {
      // eslint-disable-next-line no-unused-vars
      const response = await NewApiPost(
        '/contas',
        body,
        localStorage.getItem('sessionToken')
      );
      if (response.data.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        handleReset();
        return;
      }
      alert('Contas realizadas com sucesso');
      handleReset();
    } catch (err) {
      console.log(err);
    }
  };

  const initialValueConta = async () => {
    const date = new Date();

    try {
      const response = await ApiGet(
        '/contas',
        {
          contas: {
            numser: formData?.res_numres,
            datpag: [''],
            damov: [''],
            datven: [''],
            nompes: '',
            valdps: ''
          }
        },
        localStorage.getItem('sessionToken')
      );
      const dataContas = response?.data?.contas;

      const response1 = await ApiGet(
        '/contaspaga',
        {
          contas: {
            numser: formData?.res_numres,
            datpag: [''],
            damov: [''],
            datven: [''],
            nompes: '',
            valdps: ''
          }
        },
        localStorage.getItem('sessionToken')
      );
      const dataContasPag = response1?.data?.contas;

      setOptionsContas([dataContas, dataContasPag]);

      const valorTotalContas = dataContas?.reduce(
        (total, conta) => total + (conta?.cnt_valdps || 0),
        0
      );

      const valorTotalContasPag = dataContasPag?.reduce(
        (total, conta) => total + (conta?.cnt_valdps || 0),
        0
      );

      const valorTotal = valorTotalContas + valorTotalContasPag;

      const qtd_pacerla = options[5].filter(
        (value) => value.fpg_forpag === dataContas.forpag
      );

      setDataContas({
        codpes: formData.res_codhos,
        nome: formData.res_nomhos,
        forpag: formData.res_forpag,
        tippag: formData.res_tippag,
        datmov: formatDate(date),
        datbas: formatDate(date),
        valtot: valorTotal,
        valcon: formData.res_pagdia,
        cencus: '1100',
        fontrec: '160',
        projeto: '00003',
        numres: formData.res_numres,
        parcela: qtd_pacerla[0]?.fpg_vezfpg,
        valpar:
          FormatNumber(dataContas.valcon) / parseInt(qtd_pacerla[0]?.fpg_vezfpg)
      });
    } catch (err) {
      console.log(err);
    }
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const valueForpag = () => {
    const qtd_pacerla = options[5].filter(
      (value) => value.fpg_forpag === dataContas.forpag
    );

    setDataContas({
      ...dataContas,
      parcela: qtd_pacerla[0]?.fpg_vezfpg,
      valpar:
        FormatNumber(dataContas.valcon) / parseInt(qtd_pacerla[0]?.fpg_vezfpg)
    });
  };

  const handleCombobox = async () => {
    try {
      const response1 = await NewApiGet(
        '/tabela/empresa',
        localStorage.getItem('sessionToken')
      );
      const res1 = response1.data.tabela;

      const response2 = await NewApiGet(
        '/tabela/loja',
        localStorage.getItem('sessionToken')
      );
      const res2 = response2.data.tabela;

      const response3 = await NewApiGet(
        '/tabela/unidadeAdm',
        localStorage.getItem('sessionToken')
      );
      const res3 = response3.data.tabela;

      const dateAtual = new Date();
      const diaAtual = dateAtual.getDate().toString().padStart(2, '0');
      const mesAtual = String(dateAtual.getMonth() + 1).padStart(2, '0');
      const anoAtual = dateAtual.getFullYear().toString();
      const formatDataAtual = `${anoAtual}/${mesAtual}/${diaAtual}`;

      const date = new Date(dateAtual);
      date.setFullYear(parseInt(anoAtual) + 1);
      const dia = String(date.getDate()).padStart(2, '0');
      const mes = String(date.getMonth() + 1).padStart(2, '0');
      const ano = date.getFullYear();
      const formatData = `${ano}/${mes}/${dia}`;

      const response4 = await ApiGet(
        '/v1/evento/',
        {
          evento: {
            codevn: '',
            evento: '',
            datini: [],
            datfim: [formatDataAtual, formatData]
          }
        },
        localStorage.getItem('sessionToken')
      );
      const res4 = response4.data.evento;

      const response5 = await ApiGet(
        '/apartamento/tipo',
        {},
        localStorage.getItem('sessionToken')
      );
      const res5 = response5.data.apartamento;

      const response6 = await NewApiGet(
        '/tabela/formaPag',
        localStorage.getItem('sessionToken')
      );
      const res6 = response6.data.tabela;

      const response7 = await NewApiGet(
        '/tabela/tipoPag',
        localStorage.getItem('sessionToken')
      );
      const res7 = response7.data.tabela;

      const response8 = await NewApiGet(
        'tabela/cartaoCredito/unique',
        localStorage.getItem('sessionToken')
      );
      const res8 = response8.data.tabela;

      const response9 = await NewApiGet(
        '/tabela/bandeiraCartao',
        localStorage.getItem('sessionToken')
      );
      const res9 = response9.data.tabela;

      const response10 = await NewApiGet(
        '/tabela/banco',
        localStorage.getItem('sessionToken')
      );
      const res10 = response10.data.tabela;

      const response11 = await NewApiGet(
        '/tabela/centroCusto',
        localStorage.getItem('sessionToken')
      );
      const res11 = response11.data.tabela;

      const response12 = await NewApiGet(
        '/tabela/dolar',
        localStorage.getItem('sessionToken')
      );
      const res12 = response12.data.tabela;

      const response13 = await NewApiGet(
        '/tabela/naturezaDespesa',
        localStorage.getItem('sessionToken')
      );
      const res13 = response13.data.tabela;

      const response14 = await NewApiGet(
        '/tabela/fonteRecurso',
        localStorage.getItem('sessionToken')
      );
      const res14 = response14.data.tabela;

      const response15 = await NewApiGet(
        '/tabela/projeto',
        localStorage.getItem('sessionToken')
      );
      const res15 = response15.data.tabela;

      const response16 = await NewApiGet(
        '/tabela/contrato',
        localStorage.getItem('sessionToken')
      );
      const res16 = response16.data.tabela;

      const response17 = await NewApiGet(
        '/tabela/tipoHospede',
        localStorage.getItem('sessionToken')
      );
      const res17 = response17.data.tabela;

      const response18 = await NewApiGet(
        '/tabela/tipoApartamento',
        localStorage.getItem('sessionToken')
      );
      const res18 = response18.data.tabela;

      const response19 = await NewApiGet(
        '/tabela/tipoDiariaConsumo',
        localStorage.getItem('sessionToken')
      );
      const res19 = response19.data.tabela;

      const response20 = await NewApiGet(
        '/apartamento/listar',
        localStorage.getItem('sessionToken')
      );
      const res20 = response20.data.objeto;

      const response21 = await NewApiGet(
        '/tabela/convenio',
        localStorage.getItem('sessionToken')
      );
      const res21 = response21.data.tabela;

      const response22 = await NewApiGet(
        '/tabela/meioComunicacao',
        localStorage.getItem('sessionToken')
      );
      const res22 = response22.data.tabela;

      const response23 = await NewApiGet(
        '/tabela/tipoAssunto',
        localStorage.getItem('sessionToken')
      );
      const res23 = response23.data.tabela;

      const response24 = await NewApiGet(
        '/tabela/pais',
        localStorage.getItem('sessionToken')
      );
      const res24 = response24.data.tabela;

      const response25 = await NewApiGet(
        '/tabela/estado',
        localStorage.getItem('sessionToken')
      );
      const res25 = response25.data.tabela;

      const response26 = await NewApiGet(
        '/tabela/cidade',
        localStorage.getItem('sessionToken')
      );
      const res26 = response26.data.tabela;

      setOptions([
        res1,
        res2,
        res3,
        res4,
        res5,
        res6,
        res7,
        res8,
        res9,
        res10,
        res11,
        res12,
        res13,
        res14,
        res15,
        res16,
        res17,
        res18,
        res19,
        res20,
        res21,
        res22,
        res23,
        res24,
        res25,
        res26
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      dataContas?.forpag !== null &&
      dataContas?.forpag !== undefined &&
      dataContas?.forpag !== ''
    ) {
      valueForpag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContas.forpag]);

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-Reservas') {
      handleSearch();
    }
  }, [activeTab, props.isOpen]);

  useEffect(() => {
    handleCombobox();
  }, []);

  const isActiveTabNavOrRel = activeTab === 1 || activeTab === 2;

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Reservas"
      tabIndex="-1"
      aria-labelledby="lblReserva"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblReserva"
              style={{ fontSize: props.tamTitulo }}
            >
              Reserva
            </h1>
            {activeTab === 1 && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>

          {props.isOpen === 'Menu-Reservas' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasPedVenda"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    options={options}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p
                  className={`nav-link ${
                    activeTab === 1 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabNavOrRel) {
                      setActiveTab(1);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabNavOrRel && 'none'
                  }}
                >
                  Navegação
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 2 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabNavOrRel) {
                      setActiveTab(2);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabNavOrRel && 'none'
                  }}
                >
                  Relatorio
                </p>
                <p style={{ display: 'none' }}>Cadastro</p>
                <p style={{ display: 'none' }}>Contas</p>
                <p style={{ display: 'none' }}>Pessoa</p>
                <p style={{ display: 'none' }}>Caixa</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                    setDataPagamento={setDataPagamento}
                  />
                </div>

                <div
                  className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                  id="tab2"
                >
                  {data?.length >= 1 && (
                    <Relatorio data={data} filtroPesquisa={filtroPesquisa} />
                  )}
                </div>

                <div
                  className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
                  id="tab3"
                >
                  <Cadastro
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    options={options}
                    tamTexto={props.tamTexto}
                    dataEmpresa={props.dataEmpresa}
                    setTipoPessoa={setTipoPessoa}
                    setActiveTab={setActiveTab}
                  />
                </div>

                <div
                  className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}
                  id="tab4"
                >
                  <Contas
                    dataContas={dataContas}
                    setDataContas={setDataContas}
                    options={options}
                    optionsContas={optionsContas}
                  />
                </div>

                <div
                  className={`tab-pane ${activeTab === 5 ? 'active' : ''}`}
                  id="tab5"
                >
                  <Pessoa
                    formData={formData}
                    setFormData={setFormData}
                    tipoPessoa={tipoPessoa}
                    setActiveTab={setActiveTab}
                  />
                </div>

                <div
                  className={`tab-pane ${activeTab === 6 ? 'active' : ''}`}
                  id="tab6"
                >
                  {/* <Caixa
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    tamTexto={props.tamTexto}
                    dataEmpresa={props.dataEmpresa}
                  /> */}
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {activeTab === 1 && (
              <>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasPedVenda"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    // setInsertOrUpdate('insert');
                    // setViewOrUpdate('update');
                    // setActiveTab(3);
                    alert('Em atualização');
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </>
            )}

            {activeTab === 3 || activeTab === 4 ? (
              <>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleReset}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </button>

                {viewOrUpdate !== 'view' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      if (activeTab === 3) handleSave();
                      else if (activeTab === 4) handleSaveContas();
                    }}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
