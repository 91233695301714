export function CadGeral(props) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Cód. Empresa e Empresa */}
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Empresa</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Empresa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="empresa"
              value={props.formDataItems?.empresa || ''}
              onChange={(e) => {
                const filter = props.options[0]?.filter(
                  (option) => option.emp_codemp === e.target.value
                );

                props.setFormDataItems({
                  ...props.formDataItems,
                  empresa: e.target.value,
                  razaoSocial: filter[0]?.emp_fantasia
                });
              }}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[0]?.map((option, index) => (
                <option key={index} value={option.emp_codemp}>
                  {option.emp_codemp === props.formDataItems?.empresa
                    ? option.emp_codemp
                    : option.emp_fantasia}
                </option>
              ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="razaoSocial"
              value={props.formDataItems?.razaoSocial || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
            />
          </div>
        </div>

        {/* Cód. Loja e Loja */}
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Loja</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Loja</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="loja"
              value={props.formDataItems?.loja || ''}
              onChange={(e) => {
                const filter = props.options[1]?.filter(
                  (option) => option.loj_codloj === e.target.value
                );

                props.setFormDataItems({
                  ...props.formDataItems,
                  loja: e.target.value,
                  nomeLoja: filter[0]?.loj_denloj
                });
              }}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[1]?.map((option, index) => (
                <option key={index} value={option.loj_codloj}>
                  {option.loj_codloj === props.formDataItems?.loja
                    ? option.loj_codloj
                    : option.loj_denloj}
                </option>
              ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="nomeLoja"
              value={props.formDataItems?.nomeLoja || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdateItems === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
