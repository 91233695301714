export function CadEndereco(props) {
  return (
    <div className="col-12 row">
      {/* País */}
      <div className="col-sm-12 col-md 4 col-lg-2">
        <div className="col-12">
          <span className="col-form-label">País</span>
        </div>
        <div className="col-12">
          <select
            className="form-select form-select-sm border border-dark"
            name="pes_paisres"
            style={{ textTransform: 'uppercase' }}
            value={props.formData?.pes_paisres || ''}
            onChange={(e) => {
              const filter = props.optionsPais.filter(
                (option) => option.tps_despais === e.target.value
              );

              props.setFormData({
                ...props.formData,
                pes_paisres: filter[0].tps_despais,
                id_pais: filter[0].tps_id_pais
              });
            }}
            disabled={props.viewOrUpdate === 'view' ? true : false}
          >
            <option defaultValue></option>
            {props.optionsPais?.map((option, index) => (
              <option key={index} value={option.tps_despais}>
                {option.tps_despais}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Cep */}
      <div className="col-sm-12 col-md 4 col-lg-2">
        <div className="col-12">
          <span className="col-form-label">Cep</span>
        </div>
        <div className="col-12">
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            name="pes_cep"
            value={props.formData?.pes_cep || ''}
            onChange={props.handleInput}
            onBlur={props.handleCep}
            disabled={props.viewOrUpdate === 'view' ? true : false}
          />
        </div>
      </div>

      {/* Estado */}
      <div className="col-sm-12 col-md 4 col-lg-2">
        <div className="col-12">
          <span className="col-form-label">Estado</span>
        </div>
        <div className="col-12">
          <select
            className="form-select form-select-sm border border-dark"
            name="pes_uf"
            style={{ textTransform: 'uppercase' }}
            value={props.formData?.pes_uf || ''}
            onChange={(e) => {
              const filter = props.optionsEstado.filter(
                (option) => option.tuf_coduf === e.target.value
              );

              props.setFormData({
                ...props.formData,
                pes_uf: filter[0].tuf_coduf,
                id_estado: filter[0].tuf_id
              });
            }}
            disabled={props.viewOrUpdate === 'view' ? true : false}
          >
            <option defaultValue></option>
            {props.optionsEstado
              ?.filter(
                (option) =>
                  parseInt(option.tuf_id_pais) ===
                  parseInt(props.formData?.id_pais)
              )
              .map((option, index) => (
                <option key={index} value={option.tuf_coduf}>
                  {option.tuf_descuf}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* Cidade */}
      <div className="col-sm-12 col-md 4 col-lg-2">
        <div className="col-12">
          <span className="col-form-label">Cidade</span>
        </div>
        <div className="col-12">
          <select
            className="form-select form-select-sm border border-dark"
            name="pes_cidade"
            value={props.formData?.pes_cidade || ''}
            onChange={props.handleInput}
            style={{ textTransform: 'uppercase' }}
            disabled={props.viewOrUpdate === 'view' ? true : false}
          >
            <option defaultValue></option>
            {props.optionsCidade
              ?.filter(
                (option) =>
                  parseInt(option.tci_coduf) ===
                  parseInt(props.formData?.id_estado)
              )
              .map((option, index) => (
                <option key={index} value={option.tci_codcid}>
                  {option.tci_nomcid}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* Bairro */}
      <div className="col-sm-12 col-md 4 col-lg-2">
        <div className="col-12">
          <span className="col-form-label">Bairro</span>
        </div>
        <div className="col-12">
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            name="pes_bairro"
            value={props.formData?.pes_bairro || ''}
            onChange={props.handleInput}
            style={{ textTransform: 'uppercase' }}
            disabled={props.viewOrUpdate === 'view' ? true : false}
          />
        </div>
      </div>

      {/* Endereço */}
      <div className="col-sm-12 col-md 4 col-lg-2">
        <div className="col-12">
          <span className="col-form-label">Endereço</span>
        </div>
        <div className="col-12">
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            name="pes_endere"
            value={props.formData?.pes_endere || ''}
            onChange={props.handleInput}
            style={{ textTransform: 'uppercase' }}
            disabled={props.viewOrUpdate === 'view' ? true : false}
          />
        </div>
      </div>

      {/* Complemento */}
      <div className="col-sm-12 col-md 4 col-lg-2">
        <div className="col-12">
          <span className="col-form-label">Complemento</span>
        </div>
        <div className="col-12">
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            name="pes_comple"
            value={props.formData?.pes_comple || ''}
            onChange={props.handleInput}
            style={{ textTransform: 'uppercase' }}
            disabled={props.viewOrUpdate === 'view' ? true : false}
          />
        </div>
      </div>
    </div>
  );
}
