import { Grid } from './Grid';

export function CtrNavegacao(props) {
  return (
    <Grid
      data={props.data}
      setData={props.setData}
      tamTexto={props.tamTexto}
      tamSubTexto={props.tamSubTexto}
      dataFiltro={props.dataFiltro}
    />
  );
}
