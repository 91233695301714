/** Transforma um array de objetos em um array de valores
 *
 * @param {array[Object]} array de objetos e labels
 * @returns {array[string]} novo array de valores
 */
export function formatRequestArray(array) {
  let newArray = [];
  if (
    !array ||
    array.length === 0 ||
    array[0].value === 'Todos' ||
    array === ''
  ) {
    return [];
  }
  array.map((item) => {
    newArray.push(item.value);
  });
  return newArray;
}
