import { useState } from 'react';

import { CheckinRecep } from '../modalRecep/checkin/Checkin';
import { EventosRecep } from '../modalRecep/eventos/Eventos';
import { Portaria } from '../modalRecep/portaria/Portaria';

const Card = (props) => {
  return (
    <div
      className="card cursor"
      tabIndex="-1"
      data-bs-toggle="modal"
      data-bs-target={props.modal}
      style={{ backgroundColor: props.bgMenu, color: props.fontMenu }}
      onClick={() => props.onClick()}
    >
      <div className="card-body d-flex flex-column justify-content-center align-items-center">
        <h4 className="card-title">
          <i
            className={props.icon}
            style={{ fontSize: props.tamSubTitulo }}
          ></i>
        </h4>
        <h5
          className="card-text text-center fw-semibold"
          style={{ fontSize: props.tamSubTitulo }}
        >
          {props.texto}
        </h5>
      </div>
    </div>
  );
};

function MenuRecep(props) {
  const [isOpen, setIsOpen] = useState('');

  const optionsRecepVirtual = [
    {
      option: (
        <Card
          texto="Check-in Avulso"
          modal={props.login ? '#Menu-CheckinOnline' : '#msgError'}
          icon="fa-solid fa-magnifying-glass"
          bgMenu={props.bgMenu}
          fontMenu={props.fontMenu}
          tamSubTitulo={props.tamSubTitulo}
          onClick={() => setIsOpen('Menu-CheckinOnline')}
        />
      )
    },
    {
      option: (
        <Card
          texto="Eventos"
          modal={props.login ? '#Menu-Eventos' : '#msgError'}
          icon="fa-solid fa-magnifying-glass"
          bgMenu={props.bgMenu}
          fontMenu={props.fontMenu}
          tamSubTitulo={props.tamSubTitulo}
          onClick={() => setIsOpen('Menu-Eventos')}
        />
      )
    },
    {
      option: (
        <Card
          texto="Controle de Veículos/Portaria"
          modal={props.login ? '#Menu-ControlePortaria' : '#msgError'}
          icon="fa-solid fa-magnifying-glass"
          bgMenu={props.bgMenu}
          fontMenu={props.fontMenu}
          tamSubTitulo={props.tamSubTitulo}
          onClick={() => setIsOpen('Menu-ControlePortaria')}
        />
      )
    }
  ];

  return (
    <div className="mt-1" style={{ width: '95vw', marginLeft: '2.5vw' }}>
      <div className="row">
        {optionsRecepVirtual.map((opt, index) => (
          <div key={index} className="col-lg-4 col-md-4 col-sm-12 mb-2">
            {opt.option}
          </div>
        ))}
      </div>

      {props.login ? (
        <>
          <EventosRecep
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
          <CheckinRecep
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
          <Portaria
            isOpen={isOpen}
            login={props.login}
            token={props.token}
            tamInput={props.tamInput}
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
            setDataBanco={props.setDataBanco}
            dataEmpresa={props.dataEmpresa}
          />
        </>
      ) : (
        <div
          className="modal fade"
          id="msgError"
          tabIndex="-1"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div
                  className="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  <div>Não Logado</div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MenuRecep;
