import { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

import { ApiGetConsulta } from '../../../services/apiService';

export const Grafico = () => {
  const [options, setOptions] = useState([]);
  const [series, setSeries] = useState([]);

  const handleSearch = async () => {
    try {
      const response = await ApiGetConsulta(
        '/estoque/relatorio',
        localStorage.getItem('sessionToken')
      );
      let values = [];

      response.data.estoque.map((item) => {
        values.push({
          categories: item.etq_item,
          descri: item.itm_descri,
          qtdest: item.estoque,
          minimo: item.est_minimo,
          data: parseInt(item.resuprimento)
        });
      });

      const categories = values.map((item) => item.categories);
      const data = values.map((item) => item.data);
      const descri = values.map((item) => item.descri);
      const qtdest = values.map((item) => item.qtdest);
      const minimo = values.map((item) => item.minimo);

      setOptions({
        chart: {
          type: 'bar'
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories,
          title: {
            text: 'Itens'
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return parseInt(value).toLocaleString('pt-BR', {
                style: 'decimal',
                maximumFractionDigits: 0
              });
            }
          },
          title: {
            text: 'Quantidade de Resuprimento'
          }
        },
        title: {
          text: 'Itens com quantidade de estoque menor do que o minimo',
          align: 'center'
        },
        tooltip: {
          // eslint-disable-next-line no-unused-vars
          custom: function ({ seriesIndex, dataPointIndex, w }) {
            const categoria = categories[dataPointIndex];
            const item_desc = `${categoria}: ${descri[dataPointIndex]}`;
            const item_qtdest = `Quantidade de Estoque: ${parseInt(
              qtdest[dataPointIndex]
            )}`;
            const item_minimo = `Quantidade Minimo: ${parseInt(
              minimo[dataPointIndex]
            )}`;
            const item_resup = `Quantidade de Resuprimento: ${parseInt(
              data[dataPointIndex]
            )}`;

            return `
              <div>
                <div class='bg-secondary text-white p-2'>
                  <span class='d-block'>${item_desc}</span>
                </div>
                <div class='bg-secondary-subtle p-2'>
                  <span class='d-block'>${item_qtdest}</span>
                  <span class='d-block'>${item_minimo}</span>
                  <span class='d-block'>${item_resup}</span>
                </div>
              </div>
            `;
          }
        }
      });

      setSeries([
        {
          name: 'Quantidade de Resuprimento',
          data: data
        }
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-2">
      <div className="container">
        {series.length >= 1 && (
          <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height={500}
          />
        )}
      </div>
    </div>
  );
};
