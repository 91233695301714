export const Search = (props) => {
  return (
    <>
      {/* emp */}
      <div>
        <span className="col-form-label">Empresa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.empresa || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                empresa: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[0]?.map((option, index) => (
              <option key={index} value={option.emp_codemp}>
                {option.emp_codemp} - {option.emp_fantasia}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* loja */}
      <div>
        <span className="col-form-label">Loja</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.loja || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                loja: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[1]?.map((option, index) => (
              <option key={index} value={option.loj_codloj}>
                {option.loj_codloj} - {option.loj_denloj}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* undadm */}
      <div>
        <span className="col-form-label">Unidade Administrativa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.undadm || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                undadm: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[2]
              ?.slice()
              .sort((a, b) =>
                a.nome_unidade_adm.localeCompare(b.nome_unidade_adm)
              )
              .map((option, index) => (
                <option key={index} value={option.codigo_unidade_adm}>
                  {option.codigo_unidade_adm} - {option.nome_unidade_adm}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* tipo */}
      <div>
        <span className="col-form-label">Tipo</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.tipo || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                tipo: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[3]
              ?.sort((a, b) => a.tip_dsctipo.localeCompare(b.tip_dsctipo))
              .map((option, index) => (
                <option key={index} value={option.tip_codtip}>
                  {option.tip_dsctipo}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* grupo */}
      <div>
        <span className="col-form-label">Grupo</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.grupo || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                grupo: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[4]
              ?.sort((a, b) => a.grp_dscgrp.localeCompare(b.grp_dscgrp))
              .map((option, index) => (
                <option key={index} value={option.grp_codgrp}>
                  {option.grp_dscgrp}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* subgrupo */}
      <div>
        <span className="col-form-label">Sub Grupo</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.subGrupo || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                subGrupo: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[5]
              .filter(
                (option) => option.sgr_codgrp === props.dataFiltro?.grupo || ''
              )
              ?.sort((a, b) => a.sgr_dscsgr.localeCompare(b.sgr_dscsgr))
              .map((option, index) => (
                <option key={index} value={option.sgr_codsgr}>
                  {option.sgr_dscsgr}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div>
        <hr />
      </div>

      <div>
        <span className="col-form-label">Item</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.item || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                item: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* qtdest */}
      <div>
        <span className="col-form-label">Quantidade de Estoque</span>
        <div className="row">
          <div className="col">
            <select
              className="form-select form-select-sm border border-dark"
              value={props.dataFiltro?.operacaoQtdest || ''}
              onChange={(e) =>
                props.setDataFiltro({
                  ...props.dataFiltro,
                  operacaoQtdest: e.target.value
                })
              }
            >
              <option defaultValue></option>
              <option value="=">Igual</option>
              <option value=">">Maior</option>
              <option value=">=">Maior ou Igual</option>
              <option value="<">Menor</option>
              <option value="<=">Menor ou Igual</option>
            </select>
          </div>

          <div className="col">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.dataFiltro?.qtdest || ''}
              onChange={(e) =>
                props.setDataFiltro({
                  ...props.dataFiltro,
                  qtdest: e.target.value
                })
              }
            />
          </div>
        </div>
      </div>

      {/* minimo */}
      <div>
        <span className="col-form-label">Minimo</span>
        <div className="row">
          <div className="col">
            <select
              className="form-select form-select-sm border border-dark"
              value={props.dataFiltro?.operacaoMinimo || ''}
              onChange={(e) =>
                props.setDataFiltro({
                  ...props.dataFiltro,
                  operacaoMinimo: e.target.value
                })
              }
            >
              <option defaultValue></option>
              <option value="=">Igual</option>
              <option value=">">Maior</option>
              <option value=">=">Maior ou Igual</option>
              <option value="<">Menor</option>
              <option value="<=">Menor ou Igual</option>
            </select>
          </div>

          <div className="col">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.dataFiltro?.minimo || ''}
              onChange={(e) =>
                props.setDataFiltro({
                  ...props.dataFiltro,
                  minimo: e.target.value
                })
              }
            />
          </div>
        </div>
      </div>

      {/* resup */}
      <div>
        <span className="col-form-label">Resuprimento</span>
        <div className="row">
          <div className="col">
            <select
              className="form-select form-select-sm border border-dark"
              value={props.dataFiltro?.operacaoResup || ''}
              onChange={(e) =>
                props.setDataFiltro({
                  ...props.dataFiltro,
                  operacaoResup: e.target.value
                })
              }
            >
              <option defaultValue></option>
              <option value="=">Igual</option>
              <option value=">">Maior</option>
              <option value=">=">Maior ou Igual</option>
              <option value="<">Menor</option>
              <option value="<=">Menor ou Igual</option>
            </select>
          </div>

          <div className="col">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.dataFiltro?.resup || ''}
              onChange={(e) =>
                props.setDataFiltro({
                  ...props.dataFiltro,
                  resup: e.target.value
                })
              }
            />
          </div>
        </div>
      </div>

      {/* button */}
      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={props.handleSearch}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
};
