export function CadFNRH(props) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* País Última Procedência */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">País Últ. Proced.</span>
          </div>

          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_pultpro"
              value={props.formData?.res_pultpro || ''}
              onChange={(e) => {
                const filter = props.options[23].filter(
                  (option) => option.tps_despais === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  res_pultpro: filter[0].tps_despais,
                  id_pais_ultpro: filter[0].tps_id_pais
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[23]?.map((option, index) => (
                <option key={index} value={option.tps_despais}>
                  {option.tps_despais}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Estado Última Procedência */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Estado Últ. Proced.</span>
          </div>

          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_estultpro"
              value={props.formData?.res_estultpro || ''}
              onChange={(e) => {
                const filter = props.options[24].filter(
                  (option) => option.tuf_coduf === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  res_estultpro: filter[0].tuf_coduf,
                  id_estado_ultpro: filter[0].tuf_id
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[24]
                ?.filter(
                  (option) =>
                    parseInt(option.tuf_id_pais) ===
                    parseInt(props.formData?.id_pais_ultpro)
                )
                .map((option, index) => (
                  <option key={index} value={option.tuf_coduf}>
                    {option.tuf_descuf}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {/* Cidade Última Procedência */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Cidade Últ. Proced.</span>
          </div>

          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_cidultpro"
              value={props.formData?.res_cidultpro || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[25]
                ?.filter(
                  (option) =>
                    parseInt(option.tci_coduf) ===
                    parseInt(props.formData?.id_estado_ultpro)
                )
                .map((option, index) => (
                  <option key={index} value={option.tci_codcid}>
                    {option.tci_nomcid}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* País Próximo Destino */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">País Próx. Dest.</span>
          </div>

          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_pprodes"
              value={props.formData?.res_pprodes || ''}
              onChange={(e) => {
                const filter = props.options[23].filter(
                  (option) => option.tps_despais === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  res_pprodes: filter[0].tps_despais,
                  id_pais_prodes: filter[0].tps_id_pais
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[23]?.map((option, index) => (
                <option key={index} value={option.tps_despais}>
                  {option.tps_despais}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Estado Próximo Destino */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Estado Próx. Dest.</span>
          </div>

          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_estprodes"
              value={props.formData?.res_estprodes || ''}
              onChange={(e) => {
                const filter = props.options[24].filter(
                  (option) => option.tuf_coduf === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  res_estprodes: filter[0].tuf_coduf,
                  id_estado_prodes: filter[0].tuf_id
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[24]
                ?.filter(
                  (option) =>
                    parseInt(option.tuf_id_pais) ===
                    parseInt(props.formData?.id_pais_prodes)
                )
                .map((option, index) => (
                  <option key={index} value={option.tuf_coduf}>
                    {option.tuf_descuf}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {/* Cidade Próximo Destino */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Cidade Próx. Dest.</span>
          </div>

          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_cidprodes"
              value={props.formData?.res_cidprodes || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[25]
                ?.filter(
                  (option) =>
                    parseInt(option.tci_coduf) ===
                    parseInt(props.formData?.id_estado_prodes)
                )
                .map((option, index) => (
                  <option key={index} value={option.tci_codcid}>
                    {option.tci_nomcid}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Motivo da Viagem */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Motivo da Viagem</span>
          </div>

          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_motvia"
              value={props.formData?.res_motvia || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              <option value="01">Lazer/Férias - Leisure/Vacation</option>
              <option value="02">Negócios - Business</option>
              <option value="03">Congresso/Feira - Convention/Fair</option>
              <option value="04">Parentes/Amigos - Relatives/Friends</option>
              <option value="05">Estudos/Cursos - Studies/Courses</option>
              <option value="06">Religião - Religion</option>
              <option value="07">Sáude - Health</option>
              <option value="08">Compras - Shopping</option>
              <option value="09">Outro - Other</option>
            </select>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* Tipo de Transp. */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Tipo de Transp.</span>
          </div>

          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_tiptran"
              value={props.formData?.res_tiptran || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              <option value="01">Avião - Plane</option>
              <option value="02">Automóvel - Car</option>
              <option value="03">Ônibus - Bus</option>
              <option value="04">Moto - Motorcycle</option>
              <option value="05">Navio/Barco - Ship/Ferry Boat</option>
              <option value="06">Trem - Train</option>
              <option value="07">Outro - Other</option>
            </select>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        {/* Observação */}
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Observação</span>
          </div>

          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="res_obsfnrh"
              value={props.formData?.res_obsfnrh || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
