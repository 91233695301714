import { useState, useEffect } from 'react';

import RelContas from '../../../relatorio/RelContas';

export const Relatorio = (props) => {
  const [relConta, setRelConta] = useState([]);

  const [isLoadingBas, setIsLoadingBas] = useState(false);

  const handleClick = () => {
    setIsLoadingBas(true);
    setTimeout(() => {
      RelContas(props.data, relConta, props.filtroPesquisa);
      setIsLoadingBas(false);
    }, 1000);
  };

  useEffect(() => {
    const item1 = localStorage.getItem('sessionFantasia');
    const item2 = localStorage.getItem('sessionLoja');
    const item3 = localStorage.getItem('sessionCnpj');
    const item4 = localStorage.getItem('sessionEndereco');
    const item5 = localStorage.getItem('sessionTelefone');

    const newData = {
      empresa: item1,
      loja: item2,
      cnpj: item3,
      endereco: item4,
      telefone: item5
    };

    setRelConta([...relConta, newData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-2">
      <button
        type="button"
        className="btn btn-primary mx-2"
        onClick={() => handleClick()}
        disabled={isLoadingBas}
        style={{ width: '150px' }}
      >
        {isLoadingBas ? (
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          'Relatório Básico'
        )}
      </button>
    </div>
  );
};
