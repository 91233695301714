import { useEffect, useState } from 'react';

import { NewApiGet } from '../../../services/apiService';
import { CadGeral } from './CadGeral';

export function Cadastro({ data, setData, insertOrUpdate }) {
  const [optionsEmpresa, setOptionsEmpresa] = useState([]);
  const [optionsLoja, setOptionsLoja] = useState([]);
  const [optionsCencus, setOptionsCencus] = useState([]);
  const [optionsNatdes, setOptionsNatdes] = useState([]);
  const [optionsFonrec, setOptionsFonrec] = useState([]);

  const handleInput = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleCombobox = async () => {
    try {
      const response1 = await NewApiGet(
        '/tabela/centroCusto',
        localStorage.getItem('sessionToken')
      );
      setOptionsCencus(response1.data.tabela);

      const response2 = await NewApiGet(
        '/tabela/naturezaDespesa',
        localStorage.getItem('sessionToken')
      );
      setOptionsNatdes(response2.data.tabela);

      const response3 = await NewApiGet(
        '/tabela/fonteRecurso',
        localStorage.getItem('sessionToken')
      );
      setOptionsFonrec(response3.data.tabela);

      const response4 = await NewApiGet(
        '/tabela/empresa',
        localStorage.getItem('sessionToken')
      );
      setOptionsEmpresa(response4.data.tabela);

      const response5 = await NewApiGet(
        '/tabela/loja',
        localStorage.getItem('sessionToken')
      );
      setOptionsLoja(response5.data.tabela);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleCombobox();
  }, []);

  return (
    <>
      <div className="nav nav-tabs">
        <p
          className={`nav-link text-primary active`}
          style={{ display: 'none' }}
        >
          Geral
        </p>
      </div>

      <div className="tab-content">
        <div className={`tab-pane active`} id="tabCadastro1">
          <CadGeral
            handleInput={handleInput}
            formData={data}
            viewOrUpdate={insertOrUpdate}
            insertOrUpdate={insertOrUpdate}
            optionsEmpresa={optionsEmpresa}
            optionsCencus={optionsCencus}
            optionsNatdes={optionsNatdes}
            optionsFonrec={optionsFonrec}
            optionsLoja={optionsLoja}
          />
        </div>
      </div>
    </>
  );
}
