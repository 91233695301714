import { useEffect, useState } from 'react';

import { ApiGet, NewApiGet } from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { GridItems } from './GridItems/GridItems';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';
import { Relatorio } from './relatorio/Relatorio';

export const PedidoVenda = (props) => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [dataFiltro, setDataFiltro] = useState({
    sitped: 'N',
    tipped: 'V'
  });
  const [dataItems, setDataItems] = useState([]);
  const [filtroPesquisa, setFiltroPesquisa] = useState([]);
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');
  const [optionsUndadm, setOptionsUndadm] = useState([]);
  const [optionsEmpresa, setOptionsEmpresa] = useState([]);
  const [optionsLoja, setOptionsLoja] = useState([]);

  const handleSearch = async () => {
    const tamNumped = dataFiltro.numped
      ? dataFiltro.numped.padStart(6, '0')
      : '';
    const tamCodpes = dataFiltro.codpes
      ? dataFiltro.codpes.padStart(5, '0')
      : '';

    let datmov = null;

    const conditionDatmovInicial =
      dataFiltro.datmovInicial !== '' &&
      dataFiltro.datmovInicial !== null &&
      dataFiltro.datmovInicial !== undefined;
    const conditionDatmovFinal =
      dataFiltro.datmovFinal !== '' &&
      dataFiltro.datmovFinal !== null &&
      dataFiltro.datmovFinal !== undefined;

    if (conditionDatmovInicial && conditionDatmovFinal) {
      datmov = [dataFiltro.datmovInicial, dataFiltro.datmovFinal];
    }

    const body = {
      empresa: dataFiltro.empresa || '',
      loja: dataFiltro.loja || '',
      undadm: dataFiltro.undadm || '',
      pedcli: '',
      numped: tamNumped || '',
      sitped: dataFiltro.sitped || '',
      tipped: dataFiltro.tipped || '',
      codpes: tamCodpes || '',
      nomcli: dataFiltro.nomcli || '',
      locped: dataFiltro.locped || '',
      datmov: datmov || '',
      valor: dataFiltro.valor || '',
      fatrdo: dataFiltro.fatrdo || ''
    };

    const conditions = [
      {
        condition: dataFiltro.empresa,
        campo: 'Empresa',
        conteudo: dataFiltro.empresa
      },
      { condition: dataFiltro.loja, campo: 'Loja', conteudo: dataFiltro.loja },
      {
        condition: dataFiltro.undadm,
        campo: 'Und Adm',
        conteudo: dataFiltro.undadm
      },
      { condition: tamNumped, campo: 'Num Ped', conteudo: tamNumped },
      {
        condition: dataFiltro.sitped,
        campo: 'Sit Ped',
        conteudo: dataFiltro.sitped
      },
      {
        condition: dataFiltro.tipped,
        campo: 'Tp Ped',
        conteudo: dataFiltro.tipped
      },
      { condition: tamCodpes, campo: 'Cod Pes', conteudo: tamCodpes },
      {
        condition: dataFiltro.nomcli,
        campo: 'Nom Pes',
        conteudo: dataFiltro.nomcli
      },
      {
        condition: dataFiltro.locped,
        campo: 'Loc Ped',
        conteudo: dataFiltro.locped
      },
      { condition: datmov, campo: 'Dt Mov', conteudo: datmov },
      {
        condition: dataFiltro.valor,
        campo: 'Valor',
        conteudo: dataFiltro.valor
      },
      {
        condition: dataFiltro.fatrdo,
        campo: 'Fatur',
        conteudo: dataFiltro.fatrdo
      }
    ];

    let pesquisasDetalhes = [];

    conditions.forEach(({ condition, campo, conteudo }) => {
      if (condition) pesquisasDetalhes.push({ campo, conteudo });
    });

    setFiltroPesquisa(pesquisasDetalhes);

    try {
      const response = await ApiGet(
        '/pedvenda',
        { pedvenda: body },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      setData(response.data.pedidoVenda[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    alert('Em atualização');
  };

  const handleReset = () => {
    setFormData({});
    setActiveTab(1);
  };

  const handleCombobox = async () => {
    try {
      const response1 = await NewApiGet(
        '/tabela/unidadeAdm',
        localStorage.getItem('sessionToken')
      );
      setOptionsUndadm(response1.data.tabela);

      const response2 = await NewApiGet(
        '/tabela/empresa',
        localStorage.getItem('sessionToken')
      );
      setOptionsEmpresa(response2.data.tabela);

      const response3 = await NewApiGet(
        '/tabela/loja',
        localStorage.getItem('sessionToken')
      );
      setOptionsLoja(response3.data.tabela);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-PedidoVenda') {
      handleSearch();
    }
  }, [activeTab, props.isOpen]);

  useEffect(() => {
    handleCombobox();
  }, []);

  const isActiveTabNavOrRel = activeTab === 1 || activeTab === 2;
  const isActiveTabCadOrItems = activeTab === 3 || activeTab === 4;

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-PedidoVenda"
      tabIndex="-1"
      aria-labelledby="lblPedidoVenda"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblPedidoVenda"
              style={{ fontSize: props.tamTitulo }}
            >
              Pedido de Vendas
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-PedidoVenda' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasPedVenda"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    optionsEmpresa={optionsEmpresa}
                    optionsLoja={optionsLoja}
                    optionsUndadm={optionsUndadm}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p
                  className={`nav-link ${
                    activeTab === 1 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabNavOrRel) {
                      setActiveTab(1);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabNavOrRel && 'none'
                  }}
                >
                  Navegação
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 2 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabNavOrRel) {
                      setActiveTab(2);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabNavOrRel && 'none'
                  }}
                >
                  Relatorio
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 3 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabCadOrItems) {
                      setActiveTab(3);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabCadOrItems && 'none'
                  }}
                >
                  Cadastro
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 4 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabCadOrItems) {
                      setActiveTab(4);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabCadOrItems && 'none'
                  }}
                >
                  Items
                </p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    setDataItems={setDataItems}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                  id="tab2"
                >
                  {data?.length >= 1 && (
                    <Relatorio data={data} filtroPesquisa={filtroPesquisa} />
                  )}
                </div>
                <div
                  className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
                  id="tab3"
                >
                  <Cadastro
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    tamTexto={props.tamTexto}
                    dataEmpresa={props.dataEmpresa}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}
                  id="tab4"
                >
                  <GridItems
                    dataItems={dataItems}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {activeTab === 1 && (
              <>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasPedVenda"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setInsertOrUpdate('insert');
                    setViewOrUpdate('update');
                    setActiveTab(3);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </>
            )}

            {activeTab === 3 && (
              <>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleReset}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </button>

                {viewOrUpdate !== 'view' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
