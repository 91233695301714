import { useEffect, useState } from 'react';

import { getStartAndEndDates } from '../../../../functions/getStartAndEndDates';
import { ApiGet } from '../../../../services/apiService';
import { ChartPieQt } from '../../graficos/ChartPieQt';
import { TabelaDinamica } from '../../graficos/TabelaDinamica';
import { FiltroDropdown } from '../FiltroDropdown';
import { FiltrosDate } from '../FiltrosDate';

export const ModeloDashboard = () => {
  const [viewData, setViewData] = useState([]);
  const [empresa, setEmpresa] = useState([]);
  const [cliente, setCliente] = useState([]);
  const { startDate: sdate, endDate: edate } = getStartAndEndDates(3);

  const [startDate, setStartDate] = useState(sdate);
  const [endDate, setEndDate] = useState(edate);

  useEffect(() => {
    const listaCliente = cliente.map((item) => item.codigo);
    const requests = [
      ApiGet(
        `/view`,
        {
          view: 'vi_hotel',
          select: [
            `TO_CHAR(hos_datent, 'DD/MM/YYYY') AS Data`,
            'SUM(valdia) as sum',
            'AVG(val1dia) as avg',
            'SUM(CAST(qtddia AS INT)) as dias'
          ],
          groupBy: 'hos_datent',
          where: {
            hos_datent: [startDate, endDate],
            hos_codemp: { in: listaCliente }
          },
          order: { column: 'hos_datent', isAscending: true }
        },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        `/view`,
        {
          view: 'vi_hotel',
          select: [
            'hos_nomemp as Empresa',
            'segmento as Segmento',
            `TO_CHAR(hos_datent, 'DD/MM/YYYY') AS Data`,
            'SUM(valdia) AS sum',
            'AVG(val1dia) AS avg',
            'SUM(qtddia) AS dias'
          ],
          groupBy: 'hos_nomemp, segmento, hos_datent',
          where: {
            hos_datent: [startDate, endDate],
            hos_codemp: { in: listaCliente }
          },
          order: { column: 'hos_datent', isAscending: true }
        },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        `/view`,
        {
          view: 'vi_hotel',
          select: [
            'hos_nomemp as Empresa',
            'segmento as Segmento',
            'SUM(valdia) AS sum',
            'SUM(valdia) / SUM(SUM(valdia)) OVER () * 100 AS percentage',
            'AVG(val1dia) AS avg',
            'SUM(qtddia) AS dias'
          ],
          groupBy: 'hos_nomemp, segmento',
          where: {
            hos_datent: [startDate, endDate],
            hos_codemp: { in: listaCliente }
          },
          order: { column: 'SUM(valdia)', isAscending: '' }
        },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        `/view`,
        {
          view: 'vi_hotel',
          select: ['hos_nomemp as nome', 'SUM(valdia) AS sum'],
          groupBy: 'hos_nomemp',
          where: {
            hos_datent: [startDate, endDate],
            hos_codemp: { in: listaCliente }
          }
        },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        `/view`,
        {
          view: 'vi_hotel',
          select: ['segmento as nome', 'SUM(valdia) AS sum'],
          groupBy: 'segmento',
          where: {
            hos_datent: [startDate, endDate],
            hos_codemp: { in: listaCliente }
          }
        },
        localStorage.getItem('sessionToken')
      )
    ];

    Promise.all(requests)
      .then((responses) => {
        const dados = responses.map((response) => {
          return response.data.dados.map((item) => {
            return {
              ...item,
              sum: item.sum ? parseFloat(item.sum).toFixed(2) : null,
              avg: item.avg ? parseFloat(item.avg).toFixed(2) : null,
              dias: item.dias ? parseInt(item.dias) : null
            };
          });
        });
        setViewData(dados);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [startDate, endDate, cliente]);

  useEffect(() => {
    ApiGet(
      `/view`,
      {
        view: 'vi_hotel',
        select: [
          '(select pes_nome from pessoa where pes_codpes = hos_codemp) as nome',
          'hos_codemp as codigo'
        ],
        where: { hos_datent: [startDate, endDate] },
        groupBy: 'hos_codemp'
      },
      localStorage.getItem('sessionToken')
    )
      .then((response) => {
        const dados = response.data.dados.map((item) => {
          return { item: item.nome, codigo: item.codigo };
        });
        setEmpresa(dados);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [startDate, endDate]);

  return (
    <div className="w-100" style={{ backgroundColor: '#eeeeee' }}>
      <h4>Dashboard Análise de Receita de Hotelaria</h4>
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 mb-2 p-1">
        <FiltrosDate
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <FiltroDropdown
          title="Empresa"
          listaItems={empresa.sort((a, b) => a.item.localeCompare(b.item))}
          value={cliente}
          setValue={setCliente}
        />
      </div>
      {viewData.length > 0 && (
        <div className="w-100 h-100">
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start text-center w-100 h-100">
            {viewData[2] && (
              <TabelaDinamica
                data={viewData[2]}
                headers={[
                  { label: 'Empresa', value: 'empresa' },
                  { label: 'Segmento', value: 'segmento' },
                  {
                    label: 'Porc.%',
                    value: 'percentage',
                    align: 'left',
                    type: 'percentage'
                  },
                  {
                    label: 'Receita de Hospedagem',
                    value: 'sum',
                    align: 'right',
                    type: 'monetary'
                  },
                  {
                    label: 'Diária Média',
                    value: 'avg',
                    align: 'right',
                    type: 'monetary'
                  },
                  {
                    label: 'Rooming Night',
                    value: 'dias',
                    align: 'right',
                    type: 'integer'
                  }
                ]}
                title="Valor Diário"
                sumColumns={[
                  'VALOR TOTAL',
                  '',
                  '',
                  'sum',
                  'sum / dias',
                  'dias'
                ]}
              />
            )}
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start text-center w-100 h-100">
            <div className="w-75 h-100 d-flex justify-content-center align-items-center align-items-md-start">
              <ChartPieQt
                data={viewData[3]}
                titulo="Valor por Empresa"
                isMonetary={true}
              />
            </div>

            <div className="w-75 h-100 d-flex justify-content-center align-items-center align-items-md-start">
              {/* Não fiz os endpoints só mudei o nome, os dados continua igual o do outro */}
              <ChartPieQt
                data={viewData[4]}
                titulo="Valor por Segmento"
                isMonetary={true}
              />
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start text-center w-100 h-100">
            <div className="w-75 h-100 d-flex justify-content-center align-items-center align-items-md-start">
              {viewData[0] && (
                <TabelaDinamica
                  data={viewData[0]}
                  headers={[
                    { label: 'Data', value: 'data' },
                    {
                      label: 'Receita de Hospedagem',
                      value: 'sum',
                      align: 'right',
                      type: 'monetary'
                    },

                    {
                      label: 'Diária Média',
                      value: 'avg',
                      align: 'right',
                      type: 'monetary'
                    },
                    { label: 'Rooming Night', value: 'dias', align: 'right' }
                  ]}
                  title="Valor Diário por Data"
                />
              )}
            </div>

            <div className="w-75 h-100 d-flex justify-content-center align-items-center align-items-md-start">
              {viewData[1] && (
                <TabelaDinamica
                  data={viewData[1]}
                  headers={[
                    { label: 'Empresa', value: 'empresa' },
                    { label: 'Segmento', value: 'segmento' },
                    { label: 'Data', value: 'data', align: 'right' },
                    {
                      label: 'Receita de Hospedagem',
                      value: 'sum',
                      align: 'right',
                      type: 'monetary'
                    },
                    {
                      label: 'Diária Média',
                      value: 'avg',
                      align: 'right',
                      type: 'monetary'
                    },
                    { label: 'Rooming Night', value: 'dias', align: 'right' }
                  ]}
                  title="Valor Diário por Empresa"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
