export const MetabaseDashboard = (props) => {
  return (
    <div
      className="modal fade"
      id={props.id}
      tabIndex="-1"
      aria-labelledby="lblDashboard"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h1
              className="modal-title"
              id="lblDashboard"
              style={{ fontSize: props.tamTitulo }}
            >
              {props.title}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <iframe
              title=" "
              src={props.src}
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
