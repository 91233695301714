import { NumericFormat } from 'react-number-format';

export const CadPedidos = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Empresa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_empresa"
              value={props.formData?.ped_empresa || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Loja</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_loja"
              value={props.formData?.ped_loja || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Und. Aministrativa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_undadm"
              value={props.formData?.ped_undadm || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Núm. Pedido</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Tp. Pedido</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Sit. Pedido</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_numped"
              value={props.formData?.ped_numped || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_tipped"
              value={props.formData?.ped_tipped || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <select
              className="form-select form-select-sm border border-dark"
              name="ped_sitped"
              value={props.formData?.ped_sitped || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              <option value="C">C</option>
              <option value="N">N</option>
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Ped Ref</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Loc Ped</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Priori</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_pedref"
              value={props.formData?.ped_pedref || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <select
              className="form-select form-select-sm border border-dark"
              name="ped_locped"
              value={props.formData?.ped_locped || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.dataEmpresa.area === 'PCP' ? (
                <>
                  <option value="ABERT">
                    ABERT - Em aberto, PEDIDO DE ATACADO AINDA NÃO FOI PAGO, SÓ
                    É PAGO DEPOIS DE PRODUZIDO
                  </option>
                  <option value="ANDAM">ANDAM - Em andamento</option>
                  <option value="APROV">APROV - Aprovado</option>
                  <option value="CANCE">CANCE - Cancelado</option>
                  <option value="FATET">
                    FATET - Faturado e entregue, Entregueo
                  </option>
                  <option value="FATEV">FATEV - Faturado e enviado</option>
                  <option value="FATPE">
                    FATPE - Faturado / Preparando envio / Pronto para envio
                  </option>
                  <option value="FATUR">FATUR - Faturado</option>
                  <option value="FINAL">FINAL - Finalizado</option>
                </>
              ) : (
                <>
                  <option value="CLIEN">CLIEN - Cliente</option>
                  <option value="FATUR">FATUR - Faturado</option>
                  <option value="VENDE">VENDE</option>
                </>
              )}
            </select>
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_priori"
              value={props.formData?.ped_priori || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Cliente</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Cliente</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_codpes"
              value={props.formData?.ped_codpes || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_nomcli"
              value={props.formData?.ped_nomcli || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Observação</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_observ"
              value={props.formData?.ped_observ || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Val Id</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Cód. Ope</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Descper</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_valid"
              value={props.formData?.ped_valid || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_codope"
              value={props.formData?.ped_codope || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_descper"
              value={props.formData?.ped_descper || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Prazo</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Dat Rec</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_prazo"
              value={props.formData?.ped_prazo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="ped_datrec"
              value={props.formData?.ped_datrec?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Valor</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Val Desc</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Val Tot Trc</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <NumericFormat
              value={props.formData.ped_valor || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  ped_valor: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-4">
            <NumericFormat
              value={props.formData.ped_valdesc || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  ped_valdesc: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-4">
            <NumericFormat
              value={props.formData.ped_valtottrc || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  ped_valtottrc: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
