import { useState } from 'react';
import InputMask from 'react-input-mask';

import { format, addDays } from 'date-fns';

export const Search = (props) => {
  const [anoDamov, setAnoDamov] = useState('');

  const months = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' }
  ];

  const handlePeriodClick = (period) => {
    const today = new Date();
    let start, end;
    switch (period) {
      case 'esta-semana':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, -1);
        }
        end = addDays(start, 6);
        break;
      case 'semana-passada':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, -1);
        }
        start = addDays(start, -7);
        end = addDays(start, 6);
        break;
      case 'proxima-semana':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, 1);
        }
        end = addDays(start, 6);
        break;
      case 'este-mes':
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case 'mes-passado':
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case 'proximo-mes':
        start = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        end = new Date(today.getFullYear(), today.getMonth() + 2, 0);
        break;
      case 'este-ano':
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date(today.getFullYear(), 11, 31);
        break;
      case 'ano-passado':
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        break;
      case 'proximo-ano':
        start = new Date(today.getFullYear() + 1, 0, 1);
        end = new Date(today.getFullYear() + 1, 11, 31);
        break;
      default:
        start = null;
        end = null;
    }

    props.setDataFiltro({
      ...props.dataFiltro,
      datmovInicial: start ? format(start, 'dd/MM/yyyy') : '',
      datmovFinal: end ? format(end, 'dd/MM/yyyy') : ''
    });
  };

  const calculateDates = (monthValue, yearValue) => {
    const month = parseInt(monthValue, 10);
    const year = parseInt(yearValue, 10);

    let lastDayOfMonth;
    if (month === 2) {
      lastDayOfMonth = new Date(year, 2, 0).getDate();
    } else {
      const monthsWith30Days = [4, 6, 9, 11];
      lastDayOfMonth = monthsWith30Days.includes(month) ? 30 : 31;
    }

    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month - 1, lastDayOfMonth);

    props.setDataFiltro({
      ...props.dataFiltro,
      datmovInicial: format(startDate, 'dd/MM/yyyy'),
      datmovFinal: format(endDate, 'dd/MM/yyyy')
    });
  };

  return (
    <>
      {/* emp */}
      <div>
        <span className="col-form-label">Empresa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.empresa || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                empresa: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.optionsEmpresa?.map((option, index) => (
              <option key={index} value={option.emp_codemp}>
                {option.emp_codemp} - {option.emp_fantasia}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* loja */}
      <div>
        <span className="col-form-label">Loja</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.loja || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                loja: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.optionsLoja?.map((option, index) => (
              <option key={index} value={option.loj_codloj}>
                {option.loj_codloj} - {option.loj_denloj}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* undadm */}
      <div>
        <span className="col-form-label">Unidade Administrativa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.undadm || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                undadm: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.optionsUndadm
              ?.slice()
              .sort((a, b) =>
                a.nome_unidade_adm.localeCompare(b.nome_unidade_adm)
              )
              .map((option, index) => (
                <option key={index} value={option.codigo_unidade_adm}>
                  {option.codigo_unidade_adm} - {option.nome_unidade_adm}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* sitped */}
      <div>
        <span className="col-form-label">Situação do Pedido</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.sitped || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                sitped: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="N">N - Normal</option>
            <option value="C">C - Cancelado</option>
          </select>
        </div>
      </div>

      {/* tipped */}
      <div>
        <span className="col-form-label">Tipo do Pedido</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.tipped || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                tipped: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="V">V - Venda</option>
            <option value="D">D - Devolução</option>
            <option value="T">T - Transferência</option>
          </select>
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* Número do Pedido */}
      <div>
        <span className="col-form-label">Número do Pedido</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.numped || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                numped: e.target.value
              })
            }
          />
        </div>
      </div>

      {/* Código do Cliente */}
      <div>
        <span className="col-form-label">Código do Cliente</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.codpes || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                codpes: e.target.value
              })
            }
          />
        </div>
      </div>

      {/* Nome do Cliente */}
      <div>
        <span className="col-form-label">Nome do Cliente</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.nomcli || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                nomcli: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* locped */}
      <div>
        <span className="col-form-label">Local do Pedido</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.locped || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                locped: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.dataEmpresa.area === 'PCP' ? (
              <>
                <option value="ABERT">
                  ABERT - Em aberto, PEDIDO DE ATACADO AINDA NÃO FOI PAGO, SÓ É
                  PAGO DEPOIS DE PRODUZIDO
                </option>
                <option value="ANDAM">ANDAM - Em andamento</option>
                <option value="APROV">APROV - Aprovado</option>
                <option value="CANCE">CANCE - Cancelado</option>
                <option value="FATET">
                  FATET - Faturado e entregue, Entregueo
                </option>
                <option value="FATEV">FATEV - Faturado e enviado</option>
                <option value="FATPE">
                  FATPE - Faturado / Preparando envio / Pronto para envio
                </option>
                <option value="FATUR">FATUR - Faturado</option>
                <option value="FINAL">FINAL - Finalizado</option>
              </>
            ) : (
              <>
                <option value="CLIEN">CLIEN - Cliente</option>
                <option value="FATUR">FATUR - Faturado</option>
                <option value="VENDE">VENDE</option>
              </>
            )}
          </select>
        </div>
      </div>

      {/* fatrdo */}
      <div>
        <span className="col-form-label">Faturado</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.fatrdo || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                fatrdo: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="N">N</option>
            <option value="S">S</option>
          </select>
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* datmov */}
      <div className="row d-flex">
        <div className="dropdown col-6">
          <button
            className="btn btn-info dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Períodos
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('esta-semana', 'damov')}
              >
                Essa semana
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('semana-passada', 'damov')}
              >
                Semana passada
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proxima-semana', 'damov')}
              >
                Semana que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-mes', 'damov')}
              >
                Esse mês
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('mes-passado', 'damov')}
              >
                Mês passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-mes', 'damov')}
              >
                Mês que vem
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('este-ano', 'damov')}
              >
                Esse ano
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('ano-passado', 'damov')}
              >
                Ano passado
              </p>
            </li>
            <li>
              <p
                className="dropdown-item"
                onClick={() => handlePeriodClick('proximo-ano', 'damov')}
              >
                Ano que vem
              </p>
            </li>
          </ul>
        </div>

        <div className="dropdown col-6">
          <button
            className="btn btn-info dropdown-toggle"
            type="button"
            id="dropdownMesesDamov"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Meses/Ano
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMesesDamov">
            <li className="dropdown-item">
              <InputMask
                mask="9999"
                maskChar=""
                type="text"
                className="form-control form-control-sm border border-dark"
                id="datmovIni1"
                placeholder="Ano"
                value={anoDamov}
                onChange={(e) => setAnoDamov(e.target.value)}
              />
            </li>
            {months.map((month) => (
              <li key={month.value}>
                <p
                  className="dropdown-item"
                  onClick={() => calculateDates(month.value, anoDamov)}
                >
                  {month.label}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div>
        <span className="col-form-label">Data de Movimento Inicial</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datmovInicial || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datmovInicial: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Data de Movimento Final</span>
        <div>
          <InputMask
            mask="99/99/9999"
            maskChar=""
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.datmovFinal || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                datmovFinal: e.target.value
              })
            }
          />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={props.handleSearch}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
};
